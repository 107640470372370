/*
| Developed by Dirupt
| Filename: MapSelect.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import './MapSelect.css'
import React from 'react'
import { useTranslation } from 'react-i18next'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MapSelectProps {
	map: 'metro' | 'amt' | 'rem'
	setMap: React.Dispatch<React.SetStateAction<MapSelectProps['map']>>
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MapSelect: React.FC<MapSelectProps> = (props) => {
	const { map, setMap } = props
	const { t } = useTranslation()

	// Methods
	//--------------------------------------------------------------------------
	const handleChangeMap = (newMap: MapSelectProps['map']) => {
		setMap(newMap)
	}

	const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setMap(event.target.value as MapSelectProps['map'])
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<div className="map_select">
			<select onChange={handleSelectChange} value={map}>
				<option value={'metro'}>{t('mapSelect.metro')}</option>
				<option value={'amt'} disabled>
					{t('mapSelect.amt')} ({t('common.comingSoon')})
				</option>
				<option value={'rem'} disabled>
					{t('mapSelect.rem')} ({t('common.comingSoon')})
				</option>
			</select>
			<button className={map === 'metro' ? 'selected_map' : ''} onClick={() => handleChangeMap('metro')}>
				{t('mapSelect.metro')}
			</button>
			<button disabled className={map === 'amt' ? 'selected_map' : ''} onClick={() => handleChangeMap('amt')}>
				{t('mapSelect.amt')} <span>({t('common.comingSoon')})</span>
			</button>
			<button disabled className={map === 'rem' ? 'selected_map' : ''} onClick={() => handleChangeMap('rem')}>
				{t('mapSelect.rem')} <span>({t('common.comingSoon')})</span>
			</button>
		</div>
	)
}
