import * as React from 'react'
import { motion } from 'framer-motion'
import { SVGProps } from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from '../Loader'
import { MetroFiltered, MetroContracts } from '../../contracts/api/Metro.contracts'
import { MetroSvg, MetroSvgProps } from './MetroSvg'
import './MetroSvg.css'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MetroProps {
	data: MetroFiltered | null | undefined
	svgProps?: SVGProps<SVGSVGElement>
	dataKey: keyof MetroContracts
	loadingFilters: boolean
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const Metro = React.forwardRef<SVGSVGElement, MetroProps>((props, ref) => {
	const { t } = useTranslation()
	const { dataKey, data, svgProps, loadingFilters } = props
	const [visibleLines, setVisibleLines] = React.useState<MetroSvgProps['visibleLines']>({
		blue: true,
		green: true,
		orange: true,
		yellow: true,
	})

	// Methods
	//--------------------------------------------------------------------------
	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setVisibleLines((state) => state)
		const name = event.currentTarget.name
		const value = event.currentTarget.checked
		setVisibleLines((state) => ({
			...state,
			[name]: value,
		}))
	}

	// Render
	//--------------------------------------------------------------------------
	if (!data) return <Loader />
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: loadingFilters ? 0.4 : 1 }}
			style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
		>
			<div className="metro_checkboxes">
				<p>{t('maps.lines.label')}:</p>
				{['Blue', 'Green', 'Orange', 'Yellow'].map((item, index) => (
					<label key={index}>
						<input
							name={item.toLowerCase()}
							type="checkbox"
							checked={visibleLines[item.toLowerCase() as keyof typeof visibleLines]}
							onChange={handleCheckboxChange}
						/>
						{/* @ts-ignore */}
						{t(`maps.lines.metro.${item.toLowerCase()}`)}
					</label>
				))}
			</div>
			<MetroSvg data={data} dataKey={dataKey} svgProps={svgProps} ref={ref} visibleLines={visibleLines} />
		</motion.div>
	)
})
