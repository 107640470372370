/*
| Developed by Dirupt
| Filename: DownloadButton.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import './DownloadButton.css'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { axiosInstance } from '../../config/axiosInstance'
import { MapSelectProps } from '../MapSelect'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DownloadButtonProps {
	svgRef: React.RefObject<SVGSVGElement>
	data: {
		key: string
		year: string
		period: string
	}
	map: MapSelectProps['map']
}

interface FormikInitialValues {
	name: string
	email: string
	type: 'seller' | 'buyer'
	consent: boolean
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DownloadButton: React.FC<DownloadButtonProps> = (props) => {
	const { t } = useTranslation()
	const {
		svgRef,
		data: { key: dataKey, period, year },
		map,
	} = props
	const initialValues: FormikInitialValues = { email: '', name: '', type: 'buyer', consent: false }

	const validationSchema = Yup.object().shape({
		email: Yup.string().required().email(),
		name: Yup.string().required(),
		type: Yup.string().required(),
		consent: Yup.boolean(),
	})

	// Methods
	//--------------------------------------------------------------------------
	const downloadJPGFromSVG = () => {
		if (svgRef.current) {
			const svg = svgRef.current
			const svgData = new XMLSerializer().serializeToString(svg)

			const canvas = document.createElement('canvas')
			const ctx = canvas.getContext('2d')
			const img = new Image()

			const svgSize = svg.getBoundingClientRect()
			canvas.width = svgSize.width
			canvas.height = svgSize.height

			img.onload = () => {
				ctx?.drawImage(img, 0, 0)
				const jpgData = canvas.toDataURL('image/jpeg', 1.0)

				const link = document.createElement('a')
				link.href = jpgData
				link.download = `immo_charlyse_${map}_${dataKey}_${period}_${year}.jpg`
				link.click()
			}

			img.onerror = (error) => {
				console.error('Error loading image:', error)
			}

			img.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgData)}`
		} else {
			console.log('SVG ref is null')
		}
	}

	const handleSubmit = async (values: FormikInitialValues, formikHelpers: FormikHelpers<FormikInitialValues>) => {
		try {
			await axiosInstance.post('/downloads', {
				data: values,
			})

			downloadJPGFromSVG()
		} catch (error) {
			console.log(error)
		} finally {
			formikHelpers.setSubmitting(false)
		}
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<div className="download_form">
			<canvas id="canvas" style={{ display: 'none' }} />
			<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
				{(formikProps) => (
					<Form>
						<div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
							{formikProps.errors
								? Object.values(formikProps.errors).map((error, index) => (
										<p key={index} style={{ margin: 0, color: 'red' }}>
											{error}
										</p>
									))
								: null}
						</div>
						<div className="download_form_inputs">
							<Field name="name" placeholder={t('downloadForm.name')} />
							<Field name="email" placeholder={t('downloadForm.email')} />
							<Field name="type" placeholder={t('downloadForm.type.label')} component="select">
								<option value="seller">{t('downloadForm.type.options.seller')}</option>
								<option value="buyer">{t('downloadForm.type.options.buyer')}</option>
							</Field>
						</div>
						<div className="download_consent_button">
							{/* Checkbox container with label */}
							<div className="checkbox-container">
								<Field type="checkbox" name="consent" id={'consent'} />
								<label htmlFor="consent">{t('downloadForm.areYouAgree')}</label>
							</div>
							<button>{t('downloadForm.download')}</button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	)
}
