/*
| Developed by Lawkey
| Filename: Loader.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import './Loader.css'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface LoaderProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const Loader: React.FC<LoaderProps> = () => {
	// Render
	//--------------------------------------------------------------------------
	return <div className="map_loader" />
}
