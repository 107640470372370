import { axiosInstance } from '../../config/axiosInstance'
import { MetroFiltered } from '../../contracts/api/Metro.contracts'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface FetchMetroParams {
	period: string
	year: string
}

/*
|--------------------------------------------------------------------------
| Service
|--------------------------------------------------------------------------
*/
export const fetchMetro = async (params: FetchMetroParams) =>
	axiosInstance.get<MetroFiltered>(
		`/metros?
				populate[common][populate]=*
				&populate[orange][populate]=*
				&populate[blue][populate]=*
				&populate[green][populate]=*
				&populate[yellow][populate]=*
				&filters[period][$eq]=${params.period}
				&filters[startYear][$eq]=${params.year}`,
	)
