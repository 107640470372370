// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map_loader {
    margin: 0 auto;
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid var(--primary-main);
    border-radius: 50%;
    width: 48px;
    height: 48px;
    animation: spin 0.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,yBAAyB,EAAE,eAAe;IAC1C,yCAAyC;IACzC,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,oCAAoC;AACxC;;AAEA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC","sourcesContent":[".map_loader {\n    margin: 0 auto;\n    border: 4px solid #f3f3f3; /* Light grey */\n    border-top: 4px solid var(--primary-main);\n    border-radius: 50%;\n    width: 48px;\n    height: 48px;\n    animation: spin 0.5s linear infinite;\n}\n\n@keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
