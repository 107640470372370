// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;utf8,<svg id=\"Layer_1\" enable-background=\"new 0 0 128 128\" height=\"512\" viewBox=\"0 0 128 128\" width=\"512\" xmlns=\"http://www.w3.org/2000/svg\"><path fill=\"black\" id=\"Down_Arrow_3_\" d=\"m64 88c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l37.172 37.172 37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z\"/></svg>", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map_select select {
    display: block;
    width: 100%;
    padding: 15px 30px;
    border-radius: 5px;
    border: 2px solid var(--primary-main);
    font-weight: 500;
    font-family: 'Jost', sans-serif;
    font-size: 2em;
    cursor: pointer;
    appearance: none;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: 1em auto, 100%;
}

.map_select button {
    display: none;
}

@media (min-width: 900px) {
    .map_select select {
        display: none;
    }
    .map_select button {
        display: block;
    }
    .map_select {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 16px;
    }
}

.selected_map {
    background-color: black;
}
`, "",{"version":3,"sources":["webpack://./src/components/MapSelect/MapSelect.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,qCAAqC;IACrC,gBAAgB;IAChB,+BAA+B;IAC/B,cAAc;IACd,eAAe;IAIf,gBAAgB;IAChB,yDAAuc;IACvc,oCAAoC;IACpC,4CAA4C;IAC5C,+BAA+B;AACnC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;IACjB;IACA;QACI,cAAc;IAClB;IACA;QACI,aAAa;QACb,mBAAmB;QACnB,uBAAuB;QACvB,SAAS;IACb;AACJ;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".map_select select {\n    display: block;\n    width: 100%;\n    padding: 15px 30px;\n    border-radius: 5px;\n    border: 2px solid var(--primary-main);\n    font-weight: 500;\n    font-family: 'Jost', sans-serif;\n    font-size: 2em;\n    cursor: pointer;\n\n    -moz-appearance: none;\n    -webkit-appearance: none;\n    appearance: none;\n    background-image: url('data:image/svg+xml;utf8,<svg id=\"Layer_1\" enable-background=\"new 0 0 128 128\" height=\"512\" viewBox=\"0 0 128 128\" width=\"512\" xmlns=\"http://www.w3.org/2000/svg\"><path fill=\"black\" id=\"Down_Arrow_3_\" d=\"m64 88c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l37.172 37.172 37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z\"/></svg>');\n    background-repeat: no-repeat, repeat;\n    background-position: right .7em top 50%, 0 0;\n    background-size: 1em auto, 100%;\n}\n\n.map_select button {\n    display: none;\n}\n\n@media (min-width: 900px) {\n    .map_select select {\n        display: none;\n    }\n    .map_select button {\n        display: block;\n    }\n    .map_select {\n        display: flex;\n        flex-direction: row;\n        justify-content: center;\n        gap: 16px;\n    }\n}\n\n.selected_map {\n    background-color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
