import axios from 'axios'

// Axios Instance
//--------------------------------------------------------------------------
export const axiosInstance = axios.create({
	baseURL: String(process.env.REACT_APP_API_HOST) + '/api',
	headers: {
		Authorization: `Bearer ${String(process.env.REACT_APP_API_KEY)}`,
	},
})
