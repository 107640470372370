import * as React from 'react'
import { SVGProps } from 'react'
import { useTranslation } from 'react-i18next'
import { MetroFiltered, MetroContracts } from '../../contracts/api/Metro.contracts'
import { Watermark } from '../Watermark'
import { Loader } from '../Loader'
import { Utils } from '../../utils'
import { MetroBlueLine } from './MetroBlueLine/MetroBlueLine'
import { MetroGreenLine } from './MetroGreenLine'
import { MetroOrangeLine } from './MetroOrangeLine/MetroOrangeLine'
import { MetroYellowLine } from './MetroYellowLine/MetroYellowLine'

// Contracts
//--------------------------------------------------------------------------
export interface MetroSvgProps {
	data: MetroFiltered
	svgProps?: SVGProps<SVGSVGElement>
	dataKey: keyof MetroContracts
	visibleLines: {
		blue: boolean
		green: boolean
		orange: boolean
		yellow: boolean
	}
}

// Component
//--------------------------------------------------------------------------
export const MetroSvg = React.forwardRef<SVGSVGElement, MetroSvgProps>((props, ref) => {
	const { t } = useTranslation()
	const { data, svgProps, dataKey, visibleLines } = props
	const attributes = React.useMemo(() => {
		return data?.data?.[0]?.attributes
	}, [data])

	// Render
	//--------------------------------------------------------------------------
	if (!attributes) return <Loader />

	// Render
	//--------------------------------------------------------------------------
	return (
		<div className={'map_container'} style={{ position: 'relative' }}>
			<Watermark />
			<svg
				ref={ref}
				id="Montr\xE9al_Map"
				xmlns="http://www.w3.org/2000/svg"
				x={0}
				y={0}
				viewBox="0 0 2613 2613.67"
				xmlSpace="preserve"
				{...svgProps}
			>
				<rect x={0} y={0} width={2613} height={2613.67} fill={'#fff'} />

				{visibleLines.green ? (
					<MetroGreenLine data={{ ...attributes.green, ...attributes.common }} dataKey={dataKey} />
				) : null}

				{visibleLines.yellow ? (
					<MetroYellowLine data={{ ...attributes.yellow, ...attributes.common }} dataKey={dataKey} />
				) : null}

				{visibleLines.orange ? (
					<MetroOrangeLine data={{ ...attributes.orange, ...attributes.common }} dataKey={dataKey} />
				) : null}

				{visibleLines.blue ? (
					<MetroBlueLine data={{ ...attributes.blue, ...attributes.common }} dataKey={dataKey} />
				) : null}

				<radialGradient
					id="SVGID_00000145752956394950196110000014952934307066711966_"
					cx={460.122}
					cy={1438.246}
					r={61.505}
					gradientUnits="userSpaceOnUse"
				>
					<stop
						offset={0.574}
						style={{
							stopColor: '#130c0e',
						}}
					/>
					<stop
						offset={0.798}
						style={{
							stopColor: '#130c0e',
						}}
					/>
					<stop
						offset={1}
						style={{
							stopColor: '#130c0e',
						}}
					/>
				</radialGradient>
				<text textAnchor={'end'} x={2560} y={100} fontFamily="'Arial-BoldMT'" fontSize="64px">
					{Utils.getMapTitle({
						dataKey: t(`mapFilters.dataKey.${dataKey as keyof Omit<MetroContracts, 'id'>}`),
						category: attributes.category,
						year: attributes.startYear,
						quarter: attributes.period,
					})}
				</text>
				<text textAnchor={'end'} x={2560} y={2500} fontFamily="'Arial-BoldMT'" fontSize="20px">
					Données/Data: Centris
				</text>
				<text textAnchor={'end'} x={2560} y={2530} fontFamily="'Arial-BoldMT'" fontSize="20px">
					N/A = données insuffisantes pour faire les calculs/Not enough data to make the calculations
				</text>
				<text textAnchor={'end'} x={2560} y={2560} fontFamily="'Arial-BoldMT'" fontSize="20px">
					Instagram: @immocharlyse
				</text>
			</svg>
		</div>
	)
})
