import React from 'react'
import { MetroLineCommonStations, MetroContracts } from '../../../contracts/api/Metro.contracts'
import { Utils } from '../../../utils'
import { MetroYellowLineStations } from './MetroYellowLineStations'

// Contracts
//--------------------------------------------------------------------------
export interface MetroYellowLineProps {
	data: MetroYellowLineStations & MetroLineCommonStations
	dataKey: keyof MetroContracts
}

// Component
//--------------------------------------------------------------------------
export const MetroYellowLine: React.FC<MetroYellowLineProps> = (props) => {
	const { data, dataKey } = props

	// Render
	//--------------------------------------------------------------------------
	return (
		<g id="Yellow_line">
			<path
				id="Line_00000021090936332486983790000004853584130082311045_"
				fill="none"
				stroke="#F7D300"
				strokeWidth="50"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				d="
    M1652,1392.3l28.4-20c14.5-12,73.1,27.3,84.8,42.1l49,61.8c6.4,8.1,16.1,12.8,26.4,12.8h123.8c5.9,0,11.8-1.6,16.9-4.6l119.4-69.5"
			/>
			<g id="Stations">
				<g id="Berri-Uqam">
					<g id="Name_00000117675616228499377960000006973670380244755091_">
						<rect x="1249.3" y="1314.5" fill="#F7D300" width="318.5" height="70.7" />
						<g>
							<path
								fill="#353535"
								d="M1267.1,1331c2-0.4,6-0.7,9.8-0.7c4.6,0,7.4,0.5,9.9,1.9c2.3,1.2,4,3.5,4,6.6c0,2.9-1.7,5.7-5.5,7.1
    v0.1c3.8,1,6.7,3.9,6.7,8.2c0,3.1-1.4,5.5-3.5,7.1c-2.5,2-6.5,3-13.2,3c-3.7,0-6.5-0.2-8.2-0.5V1331z M1274.6,1343.8h2.5
    c4,0,6.1-1.7,6.1-4.2c0-2.5-1.9-3.9-5.3-3.9c-1.7,0-2.6,0.1-3.2,0.2V1343.8z M1274.6,1358.6c0.7,0.1,1.6,0.1,2.9,0.1
    c3.4,0,6.5-1.3,6.5-4.9c0-3.3-3-4.7-6.8-4.7h-2.6V1358.6z"
							/>
							<path
								fill="#353535"
								d="M1317,1349.8h-12.3v8h13.8v6.2h-21.3v-33.5h20.6v6.2h-13v7h12.3V1349.8z"
							/>
							<path
								fill="#353535"
								d="M1323.6,1331c2.4-0.4,6-0.7,10.1-0.7c5,0,8.4,0.8,10.8,2.7c2,1.6,3.1,3.9,3.1,7c0,4.2-3,7.2-5.9,8.2
    v0.2c2.3,0.9,3.6,3.2,4.5,6.2c1,3.8,2,8.1,2.7,9.4h-7.7c-0.5-1-1.3-3.7-2.3-7.8c-0.9-4.2-2.3-5.3-5.5-5.3h-2.2v13.1h-7.5V1331z
    M1331.1,1345.4h3c3.8,0,6-1.9,6-4.8c0-3.1-2.1-4.6-5.6-4.6c-1.8,0-2.9,0.1-3.4,0.3V1345.4z"
							/>
							<path
								fill="#353535"
								d="M1353.1,1331c2.4-0.4,6-0.7,10.1-0.7c5,0,8.4,0.8,10.8,2.7c2,1.6,3.1,3.9,3.1,7c0,4.2-3,7.2-5.9,8.2
    v0.2c2.3,0.9,3.6,3.2,4.5,6.2c1,3.8,2,8.1,2.7,9.4h-7.7c-0.5-1-1.3-3.7-2.3-7.8c-0.9-4.2-2.3-5.3-5.5-5.3h-2.2v13.1h-7.5V1331z
    M1360.6,1345.4h3c3.8,0,6-1.9,6-4.8c0-3.1-2.1-4.6-5.6-4.6c-1.8,0-2.9,0.1-3.4,0.3V1345.4z"
							/>
							<path fill="#353535" d="M1390.1,1330.5v33.5h-7.6v-33.5H1390.1z" />
							<path fill="#353535" d="M1407.8,1348.4v5h-13v-5H1407.8z" />
							<path
								fill="#353535"
								d="M1420.2,1330.5v19.2c0,5.8,2.2,8.7,6.1,8.7c4,0,6.2-2.8,6.2-8.7v-19.2h7.6v18.8
    c0,10.3-5.2,15.2-14,15.2c-8.4,0-13.4-4.7-13.4-15.3v-18.7H1420.2z"
							/>
							<path
								fill="#353535"
								d="M1475.1,1369.7c-4.8-1.4-8.7-2.8-13.2-4.7c-0.7-0.3-1.5-0.5-2.3-0.5c-7.6-0.5-14.6-6.1-14.6-17
    c0-10,6.3-17.6,16.4-17.6c10.2,0,15.8,7.7,15.8,16.9c0,7.6-3.5,13-7.9,14.9v0.2c2.6,0.8,5.4,1.4,8.1,1.9L1475.1,1369.7z
    M1469.1,1347.2c0-6.1-2.9-11.2-8-11.2c-5.2,0-8.2,5-8.1,11.3c0,6.4,3,11.2,8.1,11.2
    C1466.2,1358.5,1469.1,1353.7,1469.1,1347.2z"
							/>
							<path
								fill="#353535"
								d="M1489.1,1355.4l-2.4,8.6h-7.8l10.2-33.5h9.9l10.4,33.5h-8.2l-2.6-8.6H1489.1z M1497.6,1349.8l-2.1-7.1
    c-0.6-2-1.2-4.5-1.7-6.4h-0.1c-0.5,2-1,4.5-1.5,6.5l-2,7.1H1497.6z"
							/>
							<path
								fill="#353535"
								d="M1542.1,1351.2c-0.2-4.1-0.3-8.9-0.3-13.8h-0.1c-1.1,4.3-2.5,9-3.7,13l-4.1,13h-5.9l-3.6-12.9
    c-1.1-3.9-2.2-8.7-3-13.1h-0.1c-0.2,4.6-0.4,9.7-0.6,13.9l-0.6,12.7h-7l2.1-33.5h10.1l3.3,11.2c1.1,3.8,2.1,8,2.8,12h0.2
    c0.9-3.9,2-8.3,3.2-12l3.6-11.1h9.9l1.8,33.5h-7.4L1542.1,1351.2z"
							/>
						</g>
					</g>

					<ellipse
						id="Point_00000116220686551686217790000007359112360816410035_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -500.6449 1576.2786)"
						fill="#FFFFFF"
						stroke="#231F20"
						strokeWidth="5"
						strokeMiterlimit="10"
						cx="1652.4"
						cy="1392.5"
						rx="34.1"
						ry="34.1"
					/>
					<text
						transform="matrix(1 0 0 1 1520 1305)"
						fontFamily="'Arial-BoldMT'"
						fill={'#353535'}
						fontSize="26px"
						textAnchor="end"
					>
						{Utils.getFormattedValueFromStation(data.berriUqam, dataKey)}
					</text>
				</g>
				<g id="Jean-Drapeau">
					<g id="Name">
						<path
							fill="#353535"
							d="M1914.9,1535.4h5v14.1c0,6.3-3,8.5-7.9,8.5c-1.2,0-2.7-0.2-3.7-0.5l0.6-4.1c0.7,0.2,1.6,0.4,2.6,0.4
    c2.1,0,3.4-0.9,3.4-4.4V1535.4z"
						/>
						<path
							fill="#353535"
							d="M1937.3,1548.2h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1548.2z"
						/>
						<path
							fill="#353535"
							d="M1946.9,1551.9l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1946.9z M1952.6,1548.1l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1952.6z"
						/>
						<path
							fill="#353535"
							d="M1963.4,1557.6v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6
    v22.3h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1963.4z"
						/>
						<path fill="#353535" d="M1993.7,1547.3v3.3h-8.6v-3.3H1993.7z" />
						<path
							fill="#353535"
							d="M1996.9,1535.7c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V1535.7z M2001.9,1553.8c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V1553.8z"
						/>
						<path
							fill="#353535"
							d="M2019.8,1535.7c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1535.7z M2024.8,1545.2h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1545.2z"
						/>
						<path
							fill="#353535"
							d="M2044.9,1551.9l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H2044.9z M2050.5,1548.1l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H2050.5z"
						/>
						<path
							fill="#353535"
							d="M2061.4,1535.6c1.5-0.3,3.7-0.5,6.8-0.5c3.1,0,5.3,0.6,6.8,1.8c1.4,1.1,2.4,3,2.4,5.1c0,2.2-0.7,4-2,5.3
    c-1.7,1.6-4.3,2.4-7.2,2.4c-0.7,0-1.3,0-1.7-0.1v8h-5V1535.6z M2066.4,1545.7c0.4,0.1,0.9,0.1,1.7,0.1c2.7,0,4.3-1.4,4.3-3.6
    c0-2-1.4-3.3-3.9-3.3c-1,0-1.7,0.1-2.1,0.2V1545.7z"
						/>
						<path
							fill="#353535"
							d="M2093.8,1548.2h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1548.2z"
						/>
						<path
							fill="#353535"
							d="M2103.4,1551.9l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H2103.4z M2109.1,1548.1l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H2109.1z"
						/>
						<path
							fill="#353535"
							d="M2123.8,1535.4v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H2123.8z"
						/>
					</g>

					<ellipse
						id="Point"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -493.4377 1794.1099)"
						fill="#FFFFFF"
						stroke="#232121"
						strokeWidth="2"
						strokeMiterlimit="10"
						cx="1919"
						cy="1492.7"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Value_00000098216958878967810720000015522903562308238270_"></g>
					<text
						transform="matrix(1 0 0 1 2149.376 1557.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#353535'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.jeanDrapeau, dataKey)}
					</text>
				</g>
				<g id="Longueil_-_Université-de-Sherbrooke">
					<g id="Name_00000113313783166093497530000014327125538756027303_">
						<rect x="2031.2" y="1289.8" fill="#F7D300" width="447.4" height="77.3" />
						<g>
							<g>
								<path fill="#353535" d="M2042.2,1300.1h4.9v17.5h8.6v4.1h-13.5V1300.1z" />
								<path
									fill="#353535"
									d="M2077.1,1310.6c0,7.1-4.3,11.4-10.6,11.4c-6.4,0-10.2-4.8-10.2-11c0-6.4,4.1-11.3,10.5-11.3
    C2073.5,1299.7,2077.1,1304.7,2077.1,1310.6z M2061.5,1310.9c0,4.2,2,7.2,5.2,7.2c3.3,0,5.2-3.1,5.2-7.3
    c0-3.9-1.8-7.2-5.2-7.2C2063.5,1303.6,2061.5,1306.7,2061.5,1310.9z"
								/>
								<path
									fill="#353535"
									d="M2080.3,1321.6v-21.6h5.7l4.5,7.9c1.3,2.3,2.6,5,3.5,7.4l0.1,0c-0.3-2.9-0.4-5.8-0.4-9v-6.3h4.5v21.6
    h-5.1l-4.6-8.3c-1.3-2.3-2.7-5.1-3.7-7.6l-0.1,0c0.2,2.9,0.2,5.9,0.2,9.4v6.5H2080.3z"
								/>
								<path
									fill="#353535"
									d="M2120.4,1320.6c-1.5,0.5-4.4,1.2-7.2,1.2c-3.9,0-6.8-1-8.8-2.9c-2-1.9-3.1-4.7-3.1-7.9
    c0-7.2,5.3-11.3,12.3-11.3c2.8,0,4.9,0.5,6,1l-1,3.9c-1.2-0.5-2.6-0.9-5-0.9c-4.1,0-7.2,2.3-7.2,7c0,4.5,2.8,7.1,6.8,7.1
    c1.1,0,2-0.1,2.4-0.3v-4.5h-3.3v-3.8h8V1320.6z"
								/>
								<path
									fill="#353535"
									d="M2129.1,1300.1v12.4c0,3.7,1.4,5.6,3.9,5.6c2.6,0,4-1.8,4-5.6v-12.4h4.9v12.1c0,6.7-3.4,9.8-9,9.8
    c-5.4,0-8.6-3-8.6-9.9v-12H2129.1z"
								/>
								<path
									fill="#353535"
									d="M2158.9,1312.5h-7.9v5.1h8.9v4h-13.8v-21.6h13.3v4h-8.4v4.5h7.9V1312.5z"
								/>
								<path
									fill="#353535"
									d="M2168,1300.1v12.4c0,3.7,1.4,5.6,3.9,5.6c2.6,0,4-1.8,4-5.6v-12.4h4.9v12.1c0,6.7-3.4,9.8-9,9.8
    c-5.4,0-8.6-3-8.6-9.9v-12H2168z"
								/>
								<path fill="#353535" d="M2189.9,1300.1v21.6h-4.9v-21.6H2189.9z" />
								<path fill="#353535" d="M2194.1,1300.1h4.9v17.5h8.6v4.1h-13.5V1300.1z" />
								<path fill="#353535" d="M2224.2,1311.6v3.2h-8.4v-3.2H2224.2z" />
							</g>
							<g>
								<path
									fill="#353535"
									d="M2047.1,1337.5v12.4c0,3.7,1.4,5.6,3.9,5.6c2.6,0,4-1.8,4-5.6v-12.4h4.9v12.1c0,6.7-3.4,9.8-9,9.8
    c-5.4,0-8.6-3-8.6-9.9v-12H2047.1z"
								/>
								<path
									fill="#353535"
									d="M2064.1,1359.1v-21.6h5.7l4.5,7.9c1.3,2.3,2.6,5,3.5,7.4l0.1,0c-0.3-2.9-0.4-5.8-0.4-9v-6.3h4.5v21.6
    h-5.1l-4.6-8.3c-1.3-2.3-2.7-5.1-3.7-7.6l-0.1,0c0.2,2.9,0.2,5.9,0.2,9.4v6.5H2064.1z"
								/>
								<path fill="#353535" d="M2091,1337.5v21.6h-4.9v-21.6H2091z" />
								<path
									fill="#353535"
									d="M2100.4,1359.1l-6.9-21.6h5.3l2.6,9.1c0.8,2.6,1.4,5,1.9,7.7h0.1c0.5-2.6,1.2-5.2,2-7.6l2.7-9.2h5.2
    l-7.3,21.6H2100.4z"
								/>
								<path
									fill="#353535"
									d="M2128.4,1350h-7.9v5.1h8.9v4h-13.8v-21.6h13.3v4h-8.4v4.5h7.9V1350z"
								/>
								<path
									fill="#353535"
									d="M2132.7,1337.8c1.6-0.3,3.9-0.5,6.5-0.5c3.2,0,5.4,0.5,7,1.7c1.3,1,2,2.5,2,4.5c0,2.7-2,4.6-3.8,5.3
    v0.1c1.5,0.6,2.3,2,2.9,4c0.7,2.4,1.3,5.3,1.7,6.1h-5c-0.3-0.6-0.9-2.4-1.5-5c-0.6-2.7-1.5-3.4-3.5-3.4h-1.5v8.5h-4.8V1337.8z
    M2137.6,1347.1h1.9c2.4,0,3.9-1.2,3.9-3.1c0-2-1.4-3-3.6-3c-1.2,0-1.9,0.1-2.2,0.2V1347.1z"
								/>
								<path
									fill="#353535"
									d="M2151.9,1354.1c1.3,0.7,3.3,1.4,5.4,1.4c2.2,0,3.4-0.9,3.4-2.3c0-1.3-1-2.1-3.6-3
    c-3.6-1.3-5.9-3.2-5.9-6.4c0-3.7,3.1-6.5,8.2-6.5c2.5,0,4.2,0.5,5.5,1.1l-1.1,3.9c-0.9-0.4-2.4-1-4.5-1c-2.1,0-3.1,1-3.1,2.1
    c0,1.4,1.2,2,4,3c3.8,1.4,5.6,3.4,5.6,6.5c0,3.6-2.8,6.7-8.7,6.7c-2.4,0-4.9-0.7-6.1-1.3L2151.9,1354.1z"
								/>
								<path fill="#353535" d="M2173.9,1337.5v21.6h-4.9v-21.6H2173.9z" />
								<path fill="#353535" d="M2182.3,1341.6h-5.8v-4.1h16.6v4.1h-5.9v17.5h-4.9V1341.6z" />
								<path
									fill="#353535"
									d="M2208.5,1350h-7.9v5.1h8.9v4h-13.8v-21.6h13.3v4h-8.4v4.5h7.9V1350z"
								/>
								<path fill="#353535" d="M2220,1349.1v3.2h-8.4v-3.2H2220z" />
								<path
									fill="#353535"
									d="M2223.1,1337.8c1.8-0.3,4.1-0.5,6.6-0.5c4.1,0,6.8,0.8,8.8,2.3c2.2,1.7,3.6,4.3,3.6,8.1
    c0,4.1-1.5,7-3.6,8.7c-2.3,1.9-5.7,2.8-9.9,2.8c-2.5,0-4.3-0.2-5.5-0.3V1337.8z M2227.9,1355.4c0.4,0.1,1.1,0.1,1.7,0.1
    c4.4,0,7.3-2.4,7.3-7.6c0-4.5-2.6-6.8-6.8-6.8c-1.1,0-1.8,0.1-2.2,0.2V1355.4z"
								/>
								<path
									fill="#353535"
									d="M2258.2,1350h-7.9v5.1h8.9v4h-13.8v-21.6h13.3v4h-8.4v4.5h7.9V1350z"
								/>
								<path fill="#353535" d="M2269.6,1349.1v3.2h-8.4v-3.2H2269.6z" />
								<path
									fill="#353535"
									d="M2272.9,1354.1c1.3,0.7,3.3,1.4,5.4,1.4c2.2,0,3.4-0.9,3.4-2.3c0-1.3-1-2.1-3.6-3
    c-3.6-1.3-5.9-3.2-5.9-6.4c0-3.7,3.1-6.5,8.2-6.5c2.5,0,4.2,0.5,5.5,1.1l-1.1,3.9c-0.9-0.4-2.4-1-4.5-1c-2.1,0-3.1,1-3.1,2.1
    c0,1.4,1.2,2,4,3c3.8,1.4,5.6,3.4,5.6,6.5c0,3.6-2.8,6.7-8.7,6.7c-2.4,0-4.9-0.7-6.1-1.3L2272.9,1354.1z"
								/>
								<path
									fill="#353535"
									d="M2294.9,1337.5v8.3h8v-8.3h4.9v21.6h-4.9v-9h-8v9h-4.9v-21.6H2294.9z"
								/>
								<path
									fill="#353535"
									d="M2324.9,1350h-7.9v5.1h8.9v4h-13.8v-21.6h13.3v4h-8.4v4.5h7.9V1350z"
								/>
								<path
									fill="#353535"
									d="M2329.2,1337.8c1.6-0.3,3.9-0.5,6.5-0.5c3.2,0,5.4,0.5,7,1.7c1.3,1,2,2.5,2,4.5c0,2.7-2,4.6-3.8,5.3
    v0.1c1.5,0.6,2.3,2,2.9,4c0.7,2.4,1.3,5.3,1.7,6.1h-5c-0.3-0.6-0.9-2.4-1.5-5c-0.6-2.7-1.5-3.4-3.5-3.4h-1.5v8.5h-4.8V1337.8z
    M2334,1347.1h1.9c2.4,0,3.9-1.2,3.9-3.1c0-2-1.4-3-3.6-3c-1.2,0-1.9,0.1-2.2,0.2V1347.1z"
								/>
								<path
									fill="#353535"
									d="M2348.1,1337.8c1.3-0.2,3.9-0.5,6.3-0.5c3,0,4.8,0.3,6.4,1.2c1.5,0.8,2.6,2.3,2.6,4.3
    c0,1.9-1.1,3.7-3.5,4.6v0.1c2.5,0.6,4.3,2.5,4.3,5.3c0,2-0.9,3.5-2.2,4.6c-1.6,1.3-4.2,2-8.5,2c-2.4,0-4.2-0.2-5.3-0.3V1337.8
    z M2353,1346.1h1.6c2.6,0,3.9-1.1,3.9-2.7c0-1.6-1.2-2.5-3.4-2.5c-1.1,0-1.7,0.1-2.1,0.1V1346.1z M2353,1355.6
    c0.5,0.1,1,0.1,1.9,0.1c2.2,0,4.2-0.9,4.2-3.1c0-2.2-1.9-3-4.4-3h-1.7V1355.6z"
								/>
								<path
									fill="#353535"
									d="M2367.5,1337.8c1.6-0.3,3.9-0.5,6.5-0.5c3.2,0,5.4,0.5,7,1.7c1.3,1,2,2.5,2,4.5c0,2.7-2,4.6-3.8,5.3
    v0.1c1.5,0.6,2.3,2,2.9,4c0.7,2.4,1.3,5.3,1.7,6.1h-5c-0.3-0.6-0.9-2.4-1.5-5c-0.6-2.7-1.5-3.4-3.5-3.4h-1.5v8.5h-4.8V1337.8z
    M2372.3,1347.1h1.9c2.4,0,3.9-1.2,3.9-3.1c0-2-1.4-3-3.6-3c-1.2,0-1.9,0.1-2.2,0.2V1347.1z"
								/>
								<path
									fill="#353535"
									d="M2406.1,1348.1c0,7.1-4.3,11.4-10.6,11.4c-6.4,0-10.2-4.8-10.2-11c0-6.4,4.1-11.3,10.5-11.3
    C2402.4,1337.2,2406.1,1342.1,2406.1,1348.1z M2390.5,1348.4c0,4.2,2,7.2,5.2,7.2c3.3,0,5.2-3.1,5.2-7.3
    c0-3.9-1.8-7.2-5.2-7.2C2392.4,1341.1,2390.5,1344.2,2390.5,1348.4z"
								/>
								<path
									fill="#353535"
									d="M2429,1348.1c0,7.1-4.3,11.4-10.6,11.4c-6.4,0-10.2-4.8-10.2-11c0-6.4,4.1-11.3,10.5-11.3
    C2425.4,1337.2,2429,1342.1,2429,1348.1z M2413.4,1348.4c0,4.2,2,7.2,5.2,7.2c3.3,0,5.2-3.1,5.2-7.3c0-3.9-1.8-7.2-5.2-7.2
    C2415.4,1341.1,2413.4,1344.2,2413.4,1348.4z"
								/>
								<path
									fill="#353535"
									d="M2432.2,1337.5h4.8v9.5h0.1c0.5-0.8,1-1.6,1.5-2.4l4.9-7.2h6l-7.1,9.2l7.5,12.4h-5.7l-5.3-9.3
    l-1.8,2.3v7h-4.8V1337.5z"
								/>
								<path
									fill="#353535"
									d="M2464.7,1350h-7.9v5.1h8.9v4h-13.8v-21.6h13.3v4h-8.4v4.5h7.9V1350z"
								/>
							</g>
							<g>
								<path fill="#353535" d="M2208.5,1329.4l-3.9,4.6h-2l2.8-4.6H2208.5z" />
							</g>
						</g>
					</g>

					<ellipse
						id="Point_00000052805334213513275320000005289557155173982887_"
						transform="matrix(0.2298 -0.9732 0.9732 0.2298 244.3404 3136.9265)"
						fill="#FFFFFF"
						stroke="#231F20"
						strokeWidth="5"
						strokeMiterlimit="10"
						cx="2104"
						cy="1414.1"
						rx="34.1"
						ry="34.1"
					/>
					<g id="Value_00000078018218230867363610000013366478640619639485_"></g>
					<text
						transform="matrix(1 0 0 1 2144.376 1400)"
						fontFamily="'Arial-BoldMT'"
						fill={'#353535'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.longueuilUniversiteDeSherbrooke, dataKey)}
					</text>
				</g>
			</g>
		</g>
	)
}
