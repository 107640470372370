// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.metro_checkboxes {
	display: flex;
	gap: 16px;
	font-size: 8px;
	flex-wrap: wrap;
	width: 100%;
}

.metro_checkboxes p {
	font-size: 16px;
	margin: 0;
}

.metro_checkboxes input {
	font-size: 8px;
	width: 16px;
	height: 16px;
	accent-color: var(--primary-main);
}

.metro_checkboxes label {
	display: flex;
	align-items: center;
	font-size: 16px;
	gap: 4px;
	/* Make label unselectable */
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	user-select: none;
}

@media (min-width: 900px) {
	.metro_checkboxes {
		font-size: 16px;
		justify-content: center;
	}

	.metro_checkboxes p {
		font-size: 24px;
	}

	.metro_checkboxes input {
		font-size: 24px;
		width: 24px;
		height: 24px;
	}

	.metro_checkboxes label {
		font-size: 24px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/Metro/MetroSvg.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,SAAS;CACT,cAAc;CACd,eAAe;CACf,WAAW;AACZ;;AAEA;CACC,eAAe;CACf,SAAS;AACV;;AAEA;CACC,cAAc;CACd,WAAW;CACX,YAAY;CACZ,iCAAiC;AAClC;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,eAAe;CACf,QAAQ;CACR,4BAA4B;CAC5B,2BAA2B;CAC3B,yBAAyB;CAIzB,iBAAiB;AAClB;;AAEA;CACC;EACC,eAAe;EACf,uBAAuB;CACxB;;CAEA;EACC,eAAe;CAChB;;CAEA;EACC,eAAe;EACf,WAAW;EACX,YAAY;CACb;;CAEA;EACC,eAAe;CAChB;AACD","sourcesContent":[".metro_checkboxes {\n\tdisplay: flex;\n\tgap: 16px;\n\tfont-size: 8px;\n\tflex-wrap: wrap;\n\twidth: 100%;\n}\n\n.metro_checkboxes p {\n\tfont-size: 16px;\n\tmargin: 0;\n}\n\n.metro_checkboxes input {\n\tfont-size: 8px;\n\twidth: 16px;\n\theight: 16px;\n\taccent-color: var(--primary-main);\n}\n\n.metro_checkboxes label {\n\tdisplay: flex;\n\talign-items: center;\n\tfont-size: 16px;\n\tgap: 4px;\n\t/* Make label unselectable */\n\t-webkit-touch-callout: none;\n\t-webkit-user-select: none;\n\t-khtml-user-select: none;\n\t-moz-user-select: none;\n\t-ms-user-select: none;\n\tuser-select: none;\n}\n\n@media (min-width: 900px) {\n\t.metro_checkboxes {\n\t\tfont-size: 16px;\n\t\tjustify-content: center;\n\t}\n\n\t.metro_checkboxes p {\n\t\tfont-size: 24px;\n\t}\n\n\t.metro_checkboxes input {\n\t\tfont-size: 24px;\n\t\twidth: 24px;\n\t\theight: 24px;\n\t}\n\n\t.metro_checkboxes label {\n\t\tfont-size: 24px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
