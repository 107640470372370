import { axiosInstance } from '../../config/axiosInstance'
import { AmtFiltered } from '../../contracts/api/Amt.contracts'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface FetchAmtParams {
	period: string
	year: string
}

/*
|--------------------------------------------------------------------------
| Service
|--------------------------------------------------------------------------
*/
export const fetchAmt = async (params: FetchAmtParams) =>
	axiosInstance.get<AmtFiltered>(`/amts?
		populate[common][populate]=*
		&populate[cyan][populate]=*
		&populate[gray][populate]=*
		&populate[green][populate]=*
		&populate[pink][populate]=*
		&populate[purple][populate]=*
		&populate[red][populate]=*
		&filters[period][$eq]=${params.period}
		&filters[startYear][$eq]=${params.year}
		`)
