import React from 'react'
import { MetroLineCommonStations, MetroContracts } from '../../../contracts/api/Metro.contracts'
import { Utils } from '../../../utils'
import { MetroOrangeLineStations } from './MetroOrangeLineStations'

// Contract
//--------------------------------------------------------------------------
export interface MetroOrangeLineProps {
	data: MetroOrangeLineStations & MetroLineCommonStations
	dataKey: keyof MetroContracts
}

// Component
//--------------------------------------------------------------------------
export const MetroOrangeLine: React.FC<MetroOrangeLineProps> = (props) => {
	const { data, dataKey } = props

	// Render
	//--------------------------------------------------------------------------
	return (
		<g id="Orange_line">
			<path
				id="Line_00000011713945812082546450000007143861934664327578_"
				fill="none"
				stroke="#EC7300"
				strokeWidth="50"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				d="
    M305.1,253.9h435.8c24.7,0,48,11.5,62.9,31.2l877.4,1154.7c26,34.2,40.6,89.8,5.9,116.5l-284.4,215.4l-309.5,232.9
    c-34.6,27.4-93.4,28.3-120.3-6.7L383.3,1212"
			/>
			<g id="Stations_00000153684527614627676950000016249903340691662525_">
				<g id="Côte-Vertu">
					<circle
						id="Point_00000134211317435262925980000011798332793311639735_"
						fill="#FFFFFF"
						stroke="#231F20"
						strokeWidth="5"
						strokeMiterlimit="10"
						cx="379.5"
						cy="1209.9"
						r="32.9"
					/>
					<g id="Name_00000111172419370218148650000001699651307121929112_">
						<rect x="224" y="1084.3" fill="#EC7300" width="311.9" height="70.7" />
						<g>
							<path
								fill="#FFFFFF"
								d="M268.2,1141.7c-1.4,0.7-4.5,1.4-8.6,1.4c-11.6,0-17.6-7.2-17.6-16.8c0-11.4,8.2-17.7,18.3-17.7
    c3.9,0,6.9,0.8,8.2,1.5l-1.5,6c-1.5-0.7-3.7-1.3-6.4-1.3c-6,0-10.6,3.6-10.6,11.1c0,6.7,4,10.9,10.7,10.9
    c2.3,0,4.8-0.5,6.3-1.1L268.2,1141.7z"
							/>
							<path
								fill="#FFFFFF"
								d="M302.3,1125.6c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C296.7,1108.6,302.3,1116.3,302.3,1125.6z M278.2,1126c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C281.2,1114.6,278.2,1119.5,278.2,1126z"
							/>
							<path fill="#FFFFFF" d="M312.4,1115.5h-9v-6.4h25.8v6.4H320v27.1h-7.6V1115.5z" />
							<path
								fill="#FFFFFF"
								d="M353,1128.5h-12.3v8h13.8v6.2h-21.3v-33.5h20.6v6.2h-13v7H353V1128.5z"
							/>
							<path fill="#FFFFFF" d="M370.8,1127.1v5h-13v-5H370.8z" />
							<path
								fill="#FFFFFF"
								d="M382.4,1142.6l-10.7-33.5h8.3l4,14.2c1.2,4,2.2,7.8,3,11.9h0.1c0.8-4,1.9-8,3.1-11.8l4.2-14.3h8
    l-11.3,33.5H382.4z"
							/>
							<path
								fill="#FFFFFF"
								d="M425.9,1128.5h-12.3v8h13.8v6.2H406v-33.5h20.6v6.2h-13v7h12.3V1128.5z"
							/>
							<path
								fill="#FFFFFF"
								d="M432.5,1109.6c2.4-0.4,6-0.7,10.1-0.7c5,0,8.4,0.8,10.8,2.7c2,1.6,3.1,3.9,3.1,7c0,4.2-3,7.2-5.9,8.2
    v0.2c2.3,0.9,3.6,3.2,4.5,6.2c1,3.8,2,8.1,2.7,9.4H450c-0.5-1-1.3-3.7-2.3-7.8c-0.9-4.2-2.3-5.3-5.5-5.3H440v13.1h-7.5V1109.6z
    M440,1124h3c3.8,0,6-1.9,6-4.8c0-3.1-2.1-4.6-5.6-4.6c-1.8,0-2.9,0.1-3.4,0.3V1124z"
							/>
							<path fill="#FFFFFF" d="M468.5,1115.5h-9v-6.4h25.8v6.4h-9.2v27.1h-7.6V1115.5z" />
							<path
								fill="#FFFFFF"
								d="M496.8,1109.2v19.2c0,5.8,2.2,8.7,6.1,8.7c4,0,6.2-2.8,6.2-8.7v-19.2h7.6v18.8c0,10.3-5.2,15.2-14,15.2
    c-8.4,0-13.4-4.7-13.4-15.3v-18.7H496.8z"
							/>
						</g>
						<g>
							<path
								fill="#FFFFFF"
								d="M287.6,1094.1l4.8,7.1H289l-2.9-4.7H286l-2.9,4.7h-3.2l4.7-7.1H287.6z"
							/>
						</g>
					</g>
					<g id="Value_00000054959357978094738980000009380642949040765619_"></g>
					<text
						transform="matrix(1 0 0 1 224.376 1075)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
					>
						{Utils.getFormattedValueFromStation(data.coteVertu, dataKey)}
					</text>
				</g>
				<g id="Du_Collège">
					<circle
						id="Point_00000124862250123095401610000006415891012694309281_"
						fill="#FFFFFF"
						cx="436.9"
						cy="1287.3"
						r="18.3"
					/>
					<g id="Name_00000123414385551897912800000003938433998317620667_">
						<g>
							<path
								fill="#353535"
								d="M213.1,1303.6c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V1303.6z M218.1,1321.8c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V1321.8z"
							/>
							<path
								fill="#353535"
								d="M241.1,1303.3v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H241.1z"
							/>
							<path
								fill="#353535"
								d="M281.6,1325c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L281.6,1325z"
							/>
							<path
								fill="#353535"
								d="M304.3,1314.2c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C300.6,1303,304.3,1308.1,304.3,1314.2z M288.2,1314.5c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C290.2,1307,288.2,1310.2,288.2,1314.5z"
							/>
							<path fill="#353535" d="M307.6,1303.3h5v18h8.9v4.2h-13.9V1303.3z" />
							<path fill="#353535" d="M324.5,1303.3h5v18h8.9v4.2h-13.9V1303.3z" />
							<path
								fill="#353535"
								d="M354.6,1316.2h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1316.2z"
							/>
							<path
								fill="#353535"
								d="M377.6,1324.6c-1.6,0.5-4.5,1.3-7.4,1.3c-4.1,0-7-1-9.1-3c-2-1.9-3.2-4.8-3.1-8.1
    c0-7.4,5.4-11.6,12.7-11.6c2.9,0,5.1,0.5,6.2,1.1l-1.1,4c-1.2-0.5-2.7-0.9-5.2-0.9c-4.2,0-7.4,2.4-7.4,7.2c0,4.6,2.9,7.3,7,7.3
    c1.1,0,2.1-0.1,2.5-0.3v-4.7h-3.4v-3.9h8.3V1324.6z"
							/>
							<path
								fill="#353535"
								d="M394.8,1316.2h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1316.2z"
							/>
						</g>
						<g>
							<path fill="#353535" d="M346.4,1293.5l2.8,4.7h-2l-3.8-4.7H346.4z" />
						</g>
					</g>
					<g id="Value_00000088113236177271768120000015028184125063873157_"></g>
					<text
						transform="matrix(1 0 0 1 199 1323)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.duCollege, dataKey)}
					</text>
				</g>
				<g id="De_la_Savane">
					<ellipse
						id="Point_00000170244234471279826480000015431050248706541235_"
						transform="matrix(0.5211 -0.8535 0.8535 0.5211 -927.6316 1074.22)"
						fill="#FFFFFF"
						cx="493.5"
						cy="1363.8"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000059303960835465234540000008373861650741107084_">
						<path
							fill="#353535"
							d="M238.9,1376c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4c0,4.3-1.5,7.2-3.7,9
    c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V1376z M243.9,1394.1c0.4,0.1,1.1,0.1,1.8,0.1c4.6,0,7.6-2.5,7.6-7.8
    c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V1394.1z"
						/>
						<path
							fill="#353535"
							d="M275.1,1388.5h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1388.5z"
						/>
						<path fill="#353535" d="M286.2,1375.7h5v18h8.9v4.2h-13.9V1375.7z" />
						<path
							fill="#353535"
							d="M308.4,1392.2l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H308.4z M314.1,1388.5l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H314.1z"
						/>
						<path
							fill="#353535"
							d="M331.8,1392.7c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L331.8,1392.7z"
						/>
						<path
							fill="#353535"
							d="M354.6,1392.2l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H354.6z M360.3,1388.5l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H360.3z"
						/>
						<path
							fill="#353535"
							d="M374.4,1397.9l-7.1-22.3h5.5l2.7,9.4c0.8,2.6,1.5,5.2,2,7.9h0.1c0.6-2.7,1.3-5.3,2-7.9l2.8-9.5h5.4
    l-7.5,22.3H374.4z"
						/>
						<path
							fill="#353535"
							d="M393.6,1392.2l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H393.6z M399.2,1388.5l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H399.2z"
						/>
						<path
							fill="#353535"
							d="M410.1,1397.9v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H410.1z"
						/>
						<path
							fill="#353535"
							d="M446.1,1388.5h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1388.5z"
						/>
					</g>
					<g id="Value_00000122720376710754451640000011598287559255431851_"></g>
					<text
						transform="matrix(1 0 0 1 225 1395)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.deLaSavane, dataKey)}
					</text>
				</g>
				<g id="Namur">
					<ellipse
						id="Point_00000152983258837608739760000005393341672853116323_"
						transform="matrix(0.2474 -0.9689 0.9689 0.2474 -975.1924 1606.8439)"
						fill="#FFFFFF"
						cx="546.7"
						cy="1431.1"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000059309416203721677930000002179266203975071894_">
						<path
							fill="#353535"
							d="M392.7,1469.5v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H392.7z"
						/>
						<path
							fill="#353535"
							d="M420.7,1463.7l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H420.7z M426.3,1460l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H426.3z"
						/>
						<path
							fill="#353535"
							d="M456,1460.9c-0.1-2.7-0.2-5.9-0.2-9.2h-0.1c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7h-3.9l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4h-4.7l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L456,1460.9z"
						/>
						<path
							fill="#353535"
							d="M470.1,1447.2v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H470.1z"
						/>
						<path
							fill="#353535"
							d="M487.6,1447.5c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1447.5z M492.6,1457.1h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1457.1z"
						/>
					</g>
					<g id="Value_00000016048453007855102220000016228372268162343814_"></g>
					<text
						transform="matrix(1 0 0 1 380 1465)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.namur, dataKey)}
					</text>
				</g>
				<g id="Plamondon">
					<ellipse
						id="Point_00000075149909818546224990000010142086233153457029_"
						transform="matrix(0.3043 -0.9526 0.9526 0.3043 -1013.4537 1617.6063)"
						fill="#FFFFFF"
						cx="600.8"
						cy="1502.7"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000159444035209001400710000010100942496455298689_">
						<path
							fill="#353535"
							d="M357.8,1519c1.5-0.3,3.7-0.5,6.8-0.5c3.1,0,5.3,0.6,6.8,1.8c1.4,1.1,2.4,3,2.4,5.1c0,2.2-0.7,4-2,5.3
    c-1.7,1.6-4.3,2.4-7.2,2.4c-0.7,0-1.3,0-1.7-0.1v8h-5V1519z M362.8,1529.1c0.4,0.1,0.9,0.1,1.7,0.1c2.7,0,4.3-1.4,4.3-3.6
    c0-2-1.4-3.3-3.9-3.3c-1,0-1.7,0.1-2.1,0.2V1529.1z"
						/>
						<path fill="#353535" d="M377,1518.7h5v18h8.9v4.2H377V1518.7z" />
						<path
							fill="#353535"
							d="M399.2,1535.3l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H399.2z M404.9,1531.5l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H404.9z"
						/>
						<path
							fill="#353535"
							d="M434.5,1532.5c-0.1-2.7-0.2-5.9-0.2-9.2h-0.1c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7h-3.9l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4h-4.7l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L434.5,1532.5z"
						/>
						<path
							fill="#353535"
							d="M464,1529.6c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C460.3,1518.4,464,1523.5,464,1529.6z M447.9,1529.9c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C449.9,1522.4,447.9,1525.6,447.9,1529.9z"
						/>
						<path
							fill="#353535"
							d="M467.4,1541v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H467.4z"
						/>
						<path
							fill="#353535"
							d="M490.1,1519c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4c0,4.3-1.5,7.2-3.7,9
    c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V1519z M495.2,1537.2c0.4,0.1,1.1,0.1,1.8,0.1c4.6,0,7.6-2.5,7.6-7.8
    c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V1537.2z"
						/>
						<path
							fill="#353535"
							d="M533.5,1529.6c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C529.8,1518.4,533.5,1523.5,533.5,1529.6z M517.4,1529.9c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C519.4,1522.4,517.4,1525.6,517.4,1529.9z"
						/>
						<path
							fill="#353535"
							d="M536.8,1541v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    H550l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H536.8z"
						/>
					</g>
					<g id="Value_00000136408305334892571670000013122420844678850711_"></g>
					<text
						transform="matrix(1 0 0 1 342 1537)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.plamondon, dataKey)}
					</text>
				</g>
				<g id="Côte-Sainte-Catherine">
					<circle
						id="Point_00000162330975666650644880000012385222784266680208_"
						fill="#FFFFFF"
						cx="654.8"
						cy="1574.2"
						r="18.3"
					/>
					<g id="Name_00000040542397663193946630000011225863485565108098_">
						<g>
							<path
								fill="#353535"
								d="M256.9,1612.7c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L256.9,1612.7z"
							/>
							<path
								fill="#353535"
								d="M279.6,1602c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C275.8,1590.7,279.6,1595.8,279.6,1602z M263.5,1602.3c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C265.5,1594.7,263.5,1597.9,263.5,1602.3z"
							/>
							<path fill="#353535" d="M286.3,1595.3h-6v-4.2h17.1v4.2h-6.1v18h-5V1595.3z" />
							<path
								fill="#353535"
								d="M313.3,1603.9h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1603.9z"
							/>
							<path fill="#353535" d="M325.2,1603v3.3h-8.6v-3.3H325.2z" />
							<path
								fill="#353535"
								d="M328.5,1608.1c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L328.5,1608.1z"
							/>
							<path
								fill="#353535"
								d="M351.4,1607.6l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H351.4z M357,1603.9l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H357z"
							/>
							<path fill="#353535" d="M372.9,1591.1v22.3h-5v-22.3H372.9z" />
							<path
								fill="#353535"
								d="M377.3,1613.3v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6
    v22.3h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H377.3z"
							/>
							<path fill="#353535" d="M404.4,1595.3h-6v-4.2h17.1v4.2h-6.1v18h-5V1595.3z" />
							<path
								fill="#353535"
								d="M431.4,1603.9h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1603.9z"
							/>
							<path fill="#353535" d="M443.3,1603v3.3h-8.6v-3.3H443.3z" />
							<path
								fill="#353535"
								d="M463.2,1612.7c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L463.2,1612.7z"
							/>
							<path
								fill="#353535"
								d="M472,1607.6l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H472z M477.7,1603.9l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H477.7z"
							/>
							<path fill="#353535" d="M490.1,1595.3h-6v-4.2h17.1v4.2h-6.1v18h-5V1595.3z" />
							<path
								fill="#353535"
								d="M508.9,1591.1v8.6h8.3v-8.6h5v22.3h-5v-9.3h-8.3v9.3h-5v-22.3H508.9z"
							/>
							<path
								fill="#353535"
								d="M539.9,1603.9h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1603.9z"
							/>
							<path
								fill="#353535"
								d="M544.3,1591.4c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1591.4z M549.3,1601h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1601z"
							/>
							<path fill="#353535" d="M568.9,1591.1v22.3h-5v-22.3H568.9z" />
							<path
								fill="#353535"
								d="M573.3,1613.3v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6
    v22.3h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H573.3z"
							/>
							<path
								fill="#353535"
								d="M609.3,1603.9h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1603.9z"
							/>
						</g>
						<g>
							<path
								fill="#353535"
								d="M270.1,1582.1l3.2,4.7H271l-1.9-3.1H269l-1.9,3.1H265l3.1-4.7H270.1z"
							/>
						</g>
					</g>
					<g id="Value_00000075857999451979017210000015994753273616940199_"></g>
					<text
						transform="matrix(1 0 0 1 225 1610)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.coteSainteCatherine, dataKey)}
					</text>
				</g>
				<g id="Snowdon_00000140010069952729221240000006848275540117277367_">
					<ellipse
						id="Point_00000108310027271020441800000000961069617788928913_"
						transform="matrix(0.9271 -0.3748 0.3748 0.9271 -563.3375 386.1653)"
						fill="#FFFFFF"
						stroke="#231F20"
						strokeWidth="5"
						strokeMiterlimit="10"
						cx="711"
						cy="1641.1"
						rx="34.1"
						ry="34.1"
					/>
					<g id="Name_00000101805477842876025760000007449807945318545341_">
						<rect x="379" y="1644.2" fill="#EC7300" width="273.6" height="70.7" />
						<g>
							<path
								fill="#FFFFFF"
								d="M397.5,1688.5c2,1.1,5.2,2.1,8.4,2.1c3.5,0,5.3-1.4,5.3-3.6c0-2.1-1.6-3.3-5.6-4.7
    c-5.6-2-9.2-5-9.2-9.9c0-5.7,4.8-10,12.7-10c3.8,0,6.6,0.8,8.5,1.7l-1.7,6.1c-1.3-0.6-3.7-1.6-7-1.6c-3.3,0-4.9,1.5-4.9,3.2
    c0,2.1,1.9,3.1,6.2,4.7c5.9,2.2,8.7,5.3,8.7,10c0,5.6-4.3,10.4-13.5,10.4c-3.8,0-7.6-1-9.5-2.1L397.5,1688.5z"
							/>
							<path
								fill="#FFFFFF"
								d="M424.1,1696.3v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9v33.5
    h-7.9l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6v10.1H424.1z"
							/>
							<path
								fill="#FFFFFF"
								d="M489,1679.3c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C483.3,1662.3,489,1670,489,1679.3z M464.8,1679.7c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C467.8,1668.3,464.8,1673.2,464.8,1679.7z"
							/>
							<path
								fill="#FFFFFF"
								d="M499,1696.3l-7.9-33.5h8.1l2.5,13.8c0.7,4,1.4,8.3,1.9,11.7h0.1c0.5-3.6,1.3-7.6,2.2-11.8l2.9-13.7h8
    l2.7,14.1c0.7,3.9,1.3,7.5,1.8,11.2h0.1c0.5-3.7,1.2-7.6,2-11.6l2.7-13.7h7.7l-8.6,33.5h-8.2l-2.8-14.4
    c-0.7-3.3-1.2-6.5-1.6-10.3h-0.1c-0.6,3.8-1.1,6.9-1.9,10.3l-3.2,14.4H499z"
							/>
							<path
								fill="#FFFFFF"
								d="M537.7,1663.3c2.8-0.5,6.4-0.7,10.2-0.7c6.3,0,10.5,1.2,13.7,3.6c3.5,2.6,5.7,6.7,5.7,12.6
    c0,6.4-2.3,10.8-5.5,13.6c-3.5,2.9-8.9,4.3-15.4,4.3c-3.9,0-6.7-0.2-8.6-0.5V1663.3z M545.3,1690.6c0.7,0.1,1.7,0.1,2.6,0.1
    c6.9,0.1,11.4-3.7,11.4-11.7c0-7-4.1-10.6-10.6-10.6c-1.7,0-2.8,0.1-3.4,0.3V1690.6z"
							/>
							<path
								fill="#FFFFFF"
								d="M602.9,1679.3c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C597.2,1662.3,602.9,1670,602.9,1679.3z M578.7,1679.7c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C581.7,1668.3,578.7,1673.2,578.7,1679.7z"
							/>
							<path
								fill="#FFFFFF"
								d="M607.9,1696.3v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9v33.5
    h-7.9l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6v10.1H607.9z"
							/>
						</g>
					</g>
					<text
						transform="matrix(1 0 0 1 370 1690)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.snowdon, dataKey)}
					</text>
				</g>
				<g id="Villa-Maria">
					<ellipse
						id="Point_00000124867608632477354050000013965654893657012914_"
						transform="matrix(0.9557 -0.2942 0.2942 0.9557 -481.5317 309.9872)"
						fill="#FFFFFF"
						cx="789.6"
						cy="1755.5"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000062156798576890924540000012733435017687795893_">
						<path
							fill="#353535"
							d="M554.3,1793.8l-7.1-22.3h5.5l2.7,9.4c0.8,2.6,1.5,5.2,2,7.9h0.1c0.6-2.7,1.3-5.3,2-7.9l2.8-9.5h5.4
    l-7.5,22.3H554.3z"
						/>
						<path fill="#353535" d="M575.1,1771.5v22.3h-5v-22.3H575.1z" />
						<path fill="#353535" d="M579.5,1771.5h5v18h8.9v4.2h-13.9V1771.5z" />
						<path fill="#353535" d="M596.4,1771.5h5v18h8.9v4.2h-13.9V1771.5z" />
						<path
							fill="#353535"
							d="M618.6,1788.1l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H618.6z M624.3,1784.3l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H624.3z"
						/>
						<path fill="#353535" d="M642.3,1783.5v3.3h-8.6v-3.3H642.3z" />
						<path
							fill="#353535"
							d="M664.3,1785.3c-0.1-2.7-0.2-5.9-0.2-9.2h-0.1c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7h-3.9l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4H645l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L664.3,1785.3z"
						/>
						<path
							fill="#353535"
							d="M678.5,1788.1l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H678.5z M684.2,1784.3l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H684.2z"
						/>
						<path
							fill="#353535"
							d="M695,1771.8c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7c0,2.8-2,4.8-3.9,5.5
    v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2c-0.6-2.8-1.6-3.5-3.6-3.6H700v8.7h-5V1771.8
    z M700,1781.4h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2V1781.4z"
						/>
						<path fill="#353535" d="M719.7,1771.5v22.3h-5v-22.3H719.7z" />
						<path
							fill="#353535"
							d="M729.2,1788.1l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H729.2z M734.9,1784.3l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H734.9z"
						/>
					</g>
					<g id="Value_00000013162369474757892990000014128247812065333376_"></g>
					<text
						transform="matrix(1 0 0 1 531 1792)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.villaMaria, dataKey)}
					</text>
				</g>
				<g id="Vendôme">
					<circle
						id="Point_00000022527552843952277450000002477852866726957486_"
						fill="#FFFFFF"
						cx="863.1"
						cy="1853.6"
						r="18.3"
					/>
					<g id="Name_00000080193300392593495560000006956723188890573982_">
						<g>
							<path
								fill="#353535"
								d="M673.6,1891.1l-7.1-22.3h5.5l2.7,9.4c0.8,2.6,1.5,5.2,2,7.9h0.1c0.6-2.7,1.3-5.3,2-7.9l2.8-9.5h5.4
    l-7.5,22.3H673.6z"
							/>
							<path
								fill="#353535"
								d="M702.6,1881.7h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1881.7z"
							/>
							<path
								fill="#353535"
								d="M707,1891.1v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H707z"
							/>
							<path
								fill="#353535"
								d="M729.8,1869.1c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V1869.1z M734.8,1887.3c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V1887.3z"
							/>
							<path
								fill="#353535"
								d="M773.1,1879.7c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C769.4,1868.5,773.1,1873.6,773.1,1879.7z M757.1,1880c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C759,1872.5,757.1,1875.7,757.1,1880z"
							/>
							<path
								fill="#353535"
								d="M795.3,1882.6c-0.1-2.7-0.2-5.9-0.2-9.2H795c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7h-3.9l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4H776l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L795.3,1882.6z"
							/>
							<path
								fill="#353535"
								d="M817.6,1881.7h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1881.7z"
							/>
						</g>
						<g>
							<path
								fill="#353535"
								d="M763.6,1860.9l3.2,4.7h-2.2l-1.9-3.1h-0.1l-1.9,3.1h-2.1l3.1-4.7H763.6z"
							/>
						</g>
					</g>
					<g id="Value_00000037659724111749779490000000731864537008245901_"></g>
					<text
						transform="matrix(1 0 0 1 655 1888)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.vendome, dataKey)}
					</text>
				</g>
				<g id="Place-Saint-Henri">
					<circle
						id="Point_00000126311770559984581340000006040740868248217009_"
						fill="#FFFFFF"
						cx="946.7"
						cy="1963.4"
						r="18.3"
					/>
					<g id="Name_00000117661163810568990490000014129762224044944808_">
						<path
							fill="#353535"
							d="M605.6,1979.8c1.5-0.3,3.7-0.5,6.8-0.5c3.1,0,5.3,0.6,6.8,1.8c1.4,1.1,2.4,3,2.4,5.1c0,2.2-0.7,4-2,5.3
    c-1.7,1.6-4.3,2.4-7.2,2.4c-0.7,0-1.3,0-1.7-0.1v8h-5V1979.8z M610.6,1989.9c0.4,0.1,0.9,0.1,1.7,0.1c2.7,0,4.3-1.4,4.3-3.6
    c0-2-1.4-3.3-3.9-3.3c-1,0-1.7,0.1-2.1,0.2V1989.9z"
						/>
						<path fill="#353535" d="M624.8,1979.5h5v18h8.9v4.2h-13.9V1979.5z" />
						<path
							fill="#353535"
							d="M647.1,1996l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H647.1z M652.7,1992.3l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H652.7z"
						/>
						<path
							fill="#353535"
							d="M679.1,2001.1c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L679.1,2001.1z"
						/>
						<path
							fill="#353535"
							d="M695.6,1992.3h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1992.3z"
						/>
						<path fill="#353535" d="M707.5,1991.4v3.3h-8.6v-3.3H707.5z" />
						<path
							fill="#353535"
							d="M710.9,1996.5c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L710.9,1996.5z"
						/>
						<path
							fill="#353535"
							d="M733.7,1996l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H733.7z M739.4,1992.3l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H739.4z"
						/>
						<path fill="#353535" d="M755.2,1979.5v22.3h-5v-22.3H755.2z" />
						<path
							fill="#353535"
							d="M759.6,2001.7v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H759.6z"
						/>
						<path fill="#353535" d="M786.7,1983.7h-6v-4.2h17.1v4.2h-6.1v18h-5V1983.7z" />
						<path fill="#353535" d="M806,1991.4v3.3h-8.6v-3.3H806z" />
						<path fill="#353535" d="M814.2,1979.5v8.6h8.3v-8.6h5v22.3h-5v-9.3h-8.3v9.3h-5v-22.3H814.2z" />
						<path
							fill="#353535"
							d="M845.2,1992.3H837v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1H837v4.6h8.2V1992.3z"
						/>
						<path
							fill="#353535"
							d="M849.6,2001.7v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H849.6z"
						/>
						<path
							fill="#353535"
							d="M872.4,1979.8c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3H884c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1979.8z M877.4,1989.3h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1989.3z"
						/>
						<path fill="#353535" d="M897,1979.5v22.3h-5v-22.3H897z" />
					</g>
					<g id="Value_00000106134215246900998900000016355869377845777076_"></g>
					<text
						transform="matrix(1 0 0 1 590 2000)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.placeSaintHenri, dataKey)}
					</text>
				</g>
				<g id="Lionel-groulx_00000150085179711765945630000009279006738705384585_">
					<g id="Name_00000052801610686864121800000001722881182973283262_">
						<path fill="#353535" d="M1162.7,2020.7h7.6v27.1h13.3v6.3h-20.9V2020.7z" />
						<path fill="#353535" d="M1195.6,2020.7v33.5h-7.6v-33.5H1195.6z" />
						<path
							fill="#353535"
							d="M1232.8,2037.1c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C1227.2,2020.1,1232.8,2027.8,1232.8,2037.1z M1208.6,2037.5c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C1211.6,2026.1,1208.6,2031,1208.6,2037.5z"
						/>
						<path
							fill="#353535"
							d="M1237.8,2054.1v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9v33.5
    h-7.9l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6v10.1H1237.8z"
						/>
						<path fill="#353535" d="M1291.9,2040h-12.3v8h13.8v6.2H1272v-33.5h20.6v6.2h-13v7h12.3V2040z" />
						<path fill="#353535" d="M1298.6,2020.7h7.6v27.1h13.3v6.3h-20.9V2020.7z" />
						<path fill="#353535" d="M1334.2,2038.6v5h-13v-5H1334.2z" />
						<path
							fill="#353535"
							d="M1367.6,2052.6c-2.3,0.8-6.8,1.9-11.2,1.9c-6.1,0-10.5-1.5-13.6-4.5c-3.1-2.9-4.8-7.3-4.7-12.2
    c0-11.1,8.1-17.5,19.1-17.5c4.3,0,7.6,0.8,9.3,1.6l-1.6,6.1c-1.9-0.8-4.1-1.4-7.8-1.4c-6.3,0-11.1,3.5-11.1,10.8
    c0,6.9,4.4,11,10.6,11c1.7,0,3.1-0.2,3.7-0.5v-7h-5.2v-5.9h12.5V2052.6z"
						/>
						<path
							fill="#353535"
							d="M1373.5,2021.1c2.4-0.4,6-0.7,10.1-0.7c5,0,8.4,0.8,10.8,2.7c2,1.6,3.1,3.9,3.1,7c0,4.2-3,7.2-5.9,8.2
    v0.2c2.3,0.9,3.6,3.2,4.5,6.2c1,3.8,2,8.1,2.7,9.4h-7.7c-0.5-1-1.3-3.7-2.3-7.8c-0.9-4.2-2.3-5.3-5.5-5.3h-2.2v13.1h-7.5V2021.1
    z M1381,2035.5h3c3.8,0,6-1.9,6-4.8c0-3.1-2.1-4.6-5.6-4.6c-1.8,0-2.9,0.1-3.4,0.3V2035.5z"
						/>
						<path
							fill="#353535"
							d="M1433.3,2037.1c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C1427.7,2020.1,1433.3,2027.8,1433.3,2037.1z M1409.2,2037.5c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C1412.2,2026.1,1409.2,2031,1409.2,2037.5z"
						/>
						<path
							fill="#353535"
							d="M1445.8,2020.7v19.2c0,5.8,2.2,8.7,6.1,8.7c4,0,6.2-2.8,6.2-8.7v-19.2h7.6v18.8c0,10.3-5.2,15.2-14,15.2
    c-8.4,0-13.4-4.7-13.4-15.3v-18.7H1445.8z"
						/>
						<path fill="#353535" d="M1472.2,2020.7h7.6v27.1h13.3v6.3h-20.9V2020.7z" />
						<path
							fill="#353535"
							d="M1515.1,2054.1l-3-6.1c-1.2-2.3-2-4.1-3-6h-0.1c-0.7,1.9-1.5,3.7-2.6,6l-2.8,6.1h-8.6l9.7-16.9
    l-9.3-16.5h8.7l2.9,6.1c1,2,1.7,3.7,2.5,5.6h0.1c0.8-2.1,1.4-3.6,2.3-5.5l2.8-6.1h8.6l-9.4,16.3l9.9,17.1H1515.1z"
						/>
					</g>

					<ellipse
						id="Point_00000158015834585448880370000009827251323467447438_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -1108.9479 1353.542)"
						fill="#FFFFFF"
						stroke="#231F20"
						strokeWidth="5"
						strokeMiterlimit="10"
						cx="1079.4"
						cy="2015.4"
						rx="34.1"
						ry="34.1"
					/>
					<text
						transform="matrix(1 0 0 1 1540 2047)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.lionelGroulx, dataKey)}
					</text>
				</g>
				<g id="Gorges-Vanier">
					<ellipse
						id="Point_00000000180845968030130650000002179464814972333486_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -1038.4457 1391.5142)"
						fill="#FFFFFF"
						cx="1160.5"
						cy="1949.3"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000065056765863531364020000011133004268548654013_">
						<path
							fill="#353535"
							d="M1226.4,1989.9c-1.6,0.5-4.5,1.3-7.4,1.3c-4.1,0-7-1-9.1-3c-2-1.9-3.2-4.8-3.1-8.1
    c0-7.4,5.4-11.6,12.7-11.6c2.9,0,5.1,0.5,6.2,1.1l-1.1,4c-1.2-0.5-2.7-0.9-5.2-0.9c-4.2,0-7.4,2.4-7.4,7.2c0,4.6,2.9,7.3,7,7.3
    c1.1,0,2.1-0.1,2.5-0.3v-4.7h-3.4v-3.9h8.3V1989.9z"
						/>
						<path
							fill="#353535"
							d="M1243.5,1981.5h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1981.5z"
						/>
						<path
							fill="#353535"
							d="M1268.3,1979.6c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1264.6,1968.3,1268.3,1973.4,1268.3,1979.6z M1252.2,1979.8c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1254.2,1972.3,1252.2,1975.5,1252.2,1979.8z"
						/>
						<path
							fill="#353535"
							d="M1271.6,1969c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7c0,2.8-2,4.8-3.9,5.5
    v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1969z
    M1276.6,1978.5h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2V1978.5z"
						/>
						<path
							fill="#353535"
							d="M1309.7,1989.9c-1.6,0.5-4.5,1.3-7.4,1.3c-4.1,0-7-1-9.1-3c-2-1.9-3.2-4.8-3.1-8.1
    c0-7.4,5.4-11.6,12.7-11.6c2.9,0,5.1,0.5,6.2,1.1l-1.1,4c-1.2-0.5-2.7-0.9-5.2-0.9c-4.2,0-7.4,2.4-7.4,7.2c0,4.6,2.9,7.3,7,7.3
    c1.1,0,2.1-0.1,2.5-0.3v-4.7h-3.4v-3.9h8.3V1989.9z"
						/>
						<path
							fill="#353535"
							d="M1326.8,1981.5h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1981.5z"
						/>
						<path
							fill="#353535"
							d="M1331.4,1985.7c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L1331.4,1985.7z"
						/>
						<path fill="#353535" d="M1356.9,1980.6v3.3h-8.6v-3.3H1356.9z" />
						<path
							fill="#353535"
							d="M1364.6,1990.9l-7.1-22.3h5.5l2.7,9.4c0.8,2.6,1.5,5.2,2,7.9h0.1c0.6-2.7,1.3-5.3,2-7.9l2.8-9.5h5.4
    l-7.5,22.3H1364.6z"
						/>
						<path
							fill="#353535"
							d="M1383.7,1985.2l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1383.7z M1389.4,1981.4l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1389.4z"
						/>
						<path
							fill="#353535"
							d="M1400.2,1990.9v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6
    v22.3h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1400.2z"
						/>
						<path fill="#353535" d="M1428.1,1968.6v22.3h-5v-22.3H1428.1z" />
						<path
							fill="#353535"
							d="M1445.7,1981.5h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1981.5z"
						/>
						<path
							fill="#353535"
							d="M1450.1,1969c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7c0,2.8-2,4.8-3.9,5.5
    v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1969z
    M1455.1,1978.5h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2V1978.5z"
						/>
					</g>
					<g id="Value_00000106146493707224277240000011919789089876496559_"></g>
					<text
						transform="matrix(1 0 0 1 1480.376 1989)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.georgesVanier, dataKey)}
					</text>
				</g>
				<g id="Lucien-L_x27_allier">
					<ellipse
						id="Point_00000087406820938305491960000010169277152437646497_"
						transform="matrix(0.9239 -0.3827 0.3827 0.9239 -617.6275 630.5114)"
						fill="#FFFFFF"
						cx="1276.1"
						cy="1867.8"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000089570807347450441930000014776615740938629032_">
						<path fill="#353535" d="M1317.5,1893h5v18h8.9v4.2h-13.9V1893z" />
						<path
							fill="#353535"
							d="M1338.4,1893v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8V1893h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2V1893H1338.4z"
						/>
						<path
							fill="#353535"
							d="M1372.3,1914.6c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L1372.3,1914.6z"
						/>
						<path fill="#353535" d="M1380.6,1893v22.3h-5V1893H1380.6z" />
						<path
							fill="#353535"
							d="M1398.2,1905.8h-8.2v5.3h9.2v4.1H1385V1893h13.7v4.1h-8.7v4.6h8.2V1905.8z"
						/>
						<path
							fill="#353535"
							d="M1402.6,1915.2V1893h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1402.6z"
						/>
						<path fill="#353535" d="M1432.9,1904.9v3.3h-8.6v-3.3H1432.9z" />
						<path fill="#353535" d="M1436.1,1893h5v18h8.9v4.2h-13.9V1893z" />
						<path
							fill="#353535"
							d="M1455.4,1892.6c-1,3.2-2.5,6.5-4,8.9l-3,0.3c1-2.8,1.9-6,2.3-8.9L1455.4,1892.6z"
						/>
						<path
							fill="#353535"
							d="M1460.7,1909.5l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1460.7z M1466.3,1905.8l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1466.3z"
						/>
						<path fill="#353535" d="M1477.2,1893h5v18h8.9v4.2h-13.9V1893z" />
						<path fill="#353535" d="M1494.1,1893h5v18h8.9v4.2h-13.9V1893z" />
						<path fill="#353535" d="M1516,1893v22.3h-5V1893H1516z" />
						<path
							fill="#353535"
							d="M1533.6,1905.8h-8.2v5.3h9.2v4.1h-14.2V1893h13.7v4.1h-8.7v4.6h8.2V1905.8z"
						/>
						<path
							fill="#353535"
							d="M1538,1893.3c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7c0,2.8-2,4.8-3.9,5.5
    v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5
    V1893.3z M1543,1902.8h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2V1902.8z"
						/>
					</g>
					<g id="Value_00000002366908925915652930000014407011367171133363_"></g>
					<text
						transform="matrix(1 0 0 1 1566.376 1913)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.lucienLAllier, dataKey)}
					</text>
				</g>
				<g id="Bonaventure">
					<ellipse
						id="Point_00000063602967620176862100000012364687763561584776_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -859.2612 1496.4271)"
						fill="#FFFFFF"
						cx="1376.7"
						cy="1785.4"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000014624103867020110110000007994302988157727144_">
						<path
							fill="#353535"
							d="M1418.2,1815.1c1.3-0.3,4-0.5,6.5-0.5c3.1,0,5,0.3,6.6,1.2c1.6,0.8,2.7,2.3,2.7,4.4c0,2-1.1,3.8-3.7,4.7
    v0.1c2.5,0.7,4.4,2.6,4.4,5.5c0,2-0.9,3.6-2.3,4.7c-1.6,1.3-4.3,2-8.8,2c-2.5,0-4.3-0.2-5.4-0.3V1815.1z M1423.2,1823.6h1.6
    c2.7,0,4.1-1.1,4.1-2.8c0-1.7-1.3-2.6-3.6-2.6c-1.1,0-1.7,0.1-2.1,0.2V1823.6z M1423.2,1833.5c0.5,0.1,1.1,0.1,1.9,0.1
    c2.3,0,4.3-0.9,4.3-3.2c0-2.2-2-3.1-4.5-3.1h-1.7V1833.5z"
						/>
						<path
							fill="#353535"
							d="M1458.5,1825.7c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1454.8,1814.4,1458.5,1819.5,1458.5,1825.7z M1442.4,1826c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1444.4,1818.4,1442.4,1821.6,1442.4,1826z"
						/>
						<path
							fill="#353535"
							d="M1461.8,1837.1v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6
    v22.3h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1461.8z"
						/>
						<path
							fill="#353535"
							d="M1489.8,1831.3l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1489.8z M1495.4,1827.6l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1495.4z"
						/>
						<path
							fill="#353535"
							d="M1509.6,1837.1l-7.1-22.3h5.5l2.7,9.4c0.8,2.6,1.5,5.2,2,7.9h0.1c0.6-2.7,1.3-5.3,2-7.9l2.8-9.5h5.4
    l-7.5,22.3H1509.6z"
						/>
						<path
							fill="#353535"
							d="M1538.6,1827.6h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1827.6z"
						/>
						<path
							fill="#353535"
							d="M1543,1837.1v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1543z"
						/>
						<path fill="#353535" d="M1570.1,1819h-6v-4.2h17.1v4.2h-6.1v18h-5V1819z" />
						<path
							fill="#353535"
							d="M1588.9,1814.8v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H1588.9z"
						/>
						<path
							fill="#353535"
							d="M1606.4,1815.1c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1815.1z M1611.4,1824.7h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1824.7z"
						/>
						<path
							fill="#353535"
							d="M1639.2,1827.6h-8.2v5.3h9.2v4.1H1626v-22.3h13.7v4.1h-8.7v4.6h8.2V1827.6z"
						/>
					</g>
					<g id="Value_00000078017539890960193970000014824495244993266355_"></g>
					<text
						transform="matrix(1 0 0 1 1654.376 1836)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.bonaventure, dataKey)}
					</text>
				</g>
				<g id="Square-Victoria">
					<circle
						id="Point_00000095325349016324353780000005447066397716560808_"
						fill="#FFFFFF"
						cx="1472.4"
						cy="1716.4"
						r="18.3"
					/>
					<g id="Name_00000059272299342103232020000009700206016767913633_">
						<path
							fill="#353535"
							d="M1520.6,1754.5c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L1520.6,1754.5z"
						/>
						<path
							fill="#353535"
							d="M1557.3,1763.5c-3.2-0.9-5.8-1.9-8.8-3.1c-0.5-0.2-1-0.3-1.5-0.3c-5-0.3-9.7-4-9.7-11.3
    c0-6.7,4.2-11.7,10.9-11.7c6.8,0,10.5,5.2,10.5,11.2c0,5.1-2.3,8.6-5.3,9.9v0.1c1.7,0.5,3.6,0.9,5.4,1.3L1557.3,1763.5z
    M1553.4,1748.5c0-4.1-1.9-7.4-5.3-7.4c-3.4,0-5.4,3.4-5.4,7.5c0,4.2,2,7.5,5.4,7.5C1551.4,1756.1,1553.4,1752.9,1553.4,1748.5z
    "
						/>
						<path
							fill="#353535"
							d="M1567,1737.4v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H1567z"
						/>
						<path
							fill="#353535"
							d="M1588.7,1754l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1588.7z M1594.4,1750.2l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1594.4z"
						/>
						<path
							fill="#353535"
							d="M1605.2,1737.8c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1737.8z M1610.2,1747.3h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1747.3z"
						/>
						<path
							fill="#353535"
							d="M1638,1750.3h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1750.3z"
						/>
						<path fill="#353535" d="M1649.9,1749.4v3.3h-8.6v-3.3H1649.9z" />
						<path
							fill="#353535"
							d="M1657.5,1759.7l-7.1-22.3h5.5l2.7,9.4c0.8,2.6,1.5,5.2,2,7.9h0.1c0.6-2.7,1.3-5.3,2-7.9l2.8-9.5h5.4
    l-7.5,22.3H1657.5z"
						/>
						<path fill="#353535" d="M1678.3,1737.4v22.3h-5v-22.3H1678.3z" />
						<path
							fill="#353535"
							d="M1699.1,1759.1c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L1699.1,1759.1z"
						/>
						<path fill="#353535" d="M1707.1,1741.7h-6v-4.2h17.1v4.2h-6.1v18h-5V1741.7z" />
						<path
							fill="#353535"
							d="M1740.3,1748.4c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1736.6,1737.1,1740.3,1742.2,1740.3,1748.4z M1724.3,1748.6c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1726.3,1741.1,1724.3,1744.3,1724.3,1748.6z"
						/>
						<path
							fill="#353535"
							d="M1743.7,1737.8c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1737.8z M1748.7,1747.3h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1747.3z"
						/>
						<path fill="#353535" d="M1768.3,1737.4v22.3h-5v-22.3H1768.3z" />
						<path
							fill="#353535"
							d="M1777.8,1754l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1777.8z M1783.5,1750.2l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1783.5z"
						/>
					</g>
					<g id="Value_00000054264941509023600610000016319751935630704533_"></g>
					<text
						transform="matrix(1 0 0 1 1803.376 1758)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.squareVictoria, dataKey)}
					</text>
				</g>
				<g id="Place-d_x27_Armes">
					<ellipse
						id="Point_00000016784656050523849020000017383215156223748530_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -698.4744 1595.181)"
						fill="#FFFFFF"
						cx="1576.3"
						cy="1640.7"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000017490898148101563910000013334348572312922030_">
						<path
							fill="#353535"
							d="M1619.4,1660.4c1.5-0.3,3.7-0.5,6.8-0.5c3.1,0,5.3,0.6,6.8,1.8c1.4,1.1,2.4,3,2.4,5.1c0,2.2-0.7,4-2,5.3
    c-1.7,1.6-4.3,2.4-7.2,2.4c-0.7,0-1.3,0-1.7-0.1v8h-5V1660.4z M1624.4,1670.5c0.4,0.1,0.9,0.1,1.7,0.1c2.7,0,4.3-1.4,4.3-3.6
    c0-2-1.4-3.3-3.9-3.3c-1,0-1.7,0.1-2.1,0.2V1670.5z"
						/>
						<path fill="#353535" d="M1638.6,1660.1h5v18h8.9v4.2h-13.9V1660.1z" />
						<path
							fill="#353535"
							d="M1660.9,1676.6l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1660.9z M1666.5,1672.9l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1666.5z"
						/>
						<path
							fill="#353535"
							d="M1692.9,1681.8c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L1692.9,1681.8z"
						/>
						<path
							fill="#353535"
							d="M1709.4,1672.9h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1672.9z"
						/>
						<path fill="#353535" d="M1721.3,1672v3.3h-8.6v-3.3H1721.3z" />
						<path
							fill="#353535"
							d="M1724.5,1660.4c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V1660.4z M1729.5,1678.6c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V1678.6z"
						/>
						<path
							fill="#353535"
							d="M1753.4,1659.7c-1,3.2-2.5,6.5-4,8.9l-3,0.3c1-2.8,1.9-6,2.3-8.9L1753.4,1659.7z"
						/>
						<path
							fill="#353535"
							d="M1758.6,1676.6l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1758.6z M1764.3,1672.9l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1764.3z"
						/>
						<path
							fill="#353535"
							d="M1775.1,1660.4c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1660.4z M1780.1,1670h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1670z"
						/>
						<path
							fill="#353535"
							d="M1813.5,1673.8c-0.1-2.7-0.2-5.9-0.2-9.2h-0.1c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7h-3.9l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4h-4.7l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L1813.5,1673.8z"
						/>
						<path
							fill="#353535"
							d="M1835.9,1672.9h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1672.9z"
						/>
						<path
							fill="#353535"
							d="M1840.5,1677.2c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L1840.5,1677.2z"
						/>
					</g>
					<g id="Value_00000069399480688692288990000009441181180984466110_"></g>
					<text
						transform="matrix(1 0 0 1 1863 1680)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.placeDArmes, dataKey)}
					</text>
				</g>
				<g id="Champ-de-Mars">
					<ellipse
						id="Point_00000062901994003196896090000009392221471854375304_"
						transform="matrix(0.9239 -0.3827 0.3827 0.9239 -472.09 758.3885)"
						fill="#FFFFFF"
						cx="1670.3"
						cy="1565.9"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000085250926866459818960000012333185502753672115_">
						<path
							fill="#353535"
							d="M1749.7,1595.3c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L1749.7,1595.3z"
						/>
						<path fill="#353535" d="M1758.1,1573.6v8.6h8.3v-8.6h5v22.3h-5v-9.3h-8.3v9.3h-5v-22.3H1758.1z" />
						<path
							fill="#353535"
							d="M1781,1590.2l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1781z M1786.6,1586.4l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1786.6z"
						/>
						<path
							fill="#353535"
							d="M1816.3,1587.4c-0.1-2.7-0.2-5.9-0.2-9.2h-0.1c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7h-3.9l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4h-4.7l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L1816.3,1587.4z"
						/>
						<path
							fill="#353535"
							d="M1825.4,1573.9c1.5-0.3,3.7-0.5,6.8-0.5c3.1,0,5.3,0.6,6.8,1.8c1.4,1.1,2.4,3,2.4,5.1c0,2.2-0.7,4-2,5.3
    c-1.7,1.6-4.3,2.4-7.2,2.4c-0.7,0-1.3,0-1.7-0.1v8h-5V1573.9z M1830.4,1584c0.4,0.1,0.9,0.1,1.7,0.1c2.7,0,4.3-1.4,4.3-3.6
    c0-2-1.4-3.3-3.9-3.3c-1,0-1.7,0.1-2.1,0.2V1584z"
						/>
						<path fill="#353535" d="M1851.6,1585.5v3.3h-8.6v-3.3H1851.6z" />
						<path
							fill="#353535"
							d="M1854.8,1573.9c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V1573.9z M1859.8,1592.1c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V1592.1z"
						/>
						<path
							fill="#353535"
							d="M1891,1586.5h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1586.5z"
						/>
						<path fill="#353535" d="M1902.9,1585.5v3.3h-8.6v-3.3H1902.9z" />
						<path
							fill="#353535"
							d="M1924.9,1587.4c-0.1-2.7-0.2-5.9-0.2-9.2h-0.1c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7h-3.9l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4h-4.7l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L1924.9,1587.4z"
						/>
						<path
							fill="#353535"
							d="M1939.1,1590.2l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1939.1z M1944.7,1586.4l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1944.7z"
						/>
						<path
							fill="#353535"
							d="M1955.6,1573.9c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1573.9z M1960.6,1583.5h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1583.5z"
						/>
						<path
							fill="#353535"
							d="M1975.3,1590.7c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L1975.3,1590.7z"
						/>
					</g>
					<g id="Value_00000044884922816132509010000005169908696834145432_"></g>
					<text
						transform="matrix(1 0 0 1 2000 1595)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.champDeMars, dataKey)}
					</text>
				</g>
				<g id="Berri-Uqam_00000078023016942259831630000003504796372414869639_">
					<ellipse
						id="Point_00000176014807706504755310000000989118664053580972_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -500.6449 1576.2786)"
						fill="#FFFFFF"
						stroke="#231F20"
						strokeWidth="5"
						strokeMiterlimit="10"
						cx="1652.4"
						cy="1392.5"
						rx="34.1"
						ry="34.1"
					/>
					<g id="Name_00000021809614195943595260000003314530011891740086_">
						<rect x="1249.3" y="1314.5" fill="#EC7300" width="318.5" height="70.7" />
						<g>
							<path
								fill="#FFFFFF"
								d="M1267.1,1331c2-0.4,6-0.7,9.8-0.7c4.6,0,7.4,0.5,9.9,1.9c2.3,1.2,4,3.5,4,6.6c0,2.9-1.7,5.7-5.5,7.1
    v0.1c3.8,1,6.7,3.9,6.7,8.2c0,3.1-1.4,5.5-3.5,7.1c-2.5,2-6.5,3-13.2,3c-3.7,0-6.5-0.2-8.2-0.5V1331z M1274.6,1343.8h2.5
    c4,0,6.1-1.7,6.1-4.2c0-2.5-1.9-3.9-5.3-3.9c-1.7,0-2.6,0.1-3.2,0.2V1343.8z M1274.6,1358.6c0.7,0.1,1.6,0.1,2.9,0.1
    c3.4,0,6.5-1.3,6.5-4.9c0-3.3-3-4.7-6.8-4.7h-2.6V1358.6z"
							/>
							<path
								fill="#FFFFFF"
								d="M1317,1349.8h-12.3v8h13.8v6.2h-21.3v-33.5h20.6v6.2h-13v7h12.3V1349.8z"
							/>
							<path
								fill="#FFFFFF"
								d="M1323.6,1331c2.4-0.4,6-0.7,10.1-0.7c5,0,8.4,0.8,10.8,2.7c2,1.6,3.1,3.9,3.1,7c0,4.2-3,7.2-5.9,8.2
    v0.2c2.3,0.9,3.6,3.2,4.5,6.2c1,3.8,2,8.1,2.7,9.4h-7.7c-0.5-1-1.3-3.7-2.3-7.8c-0.9-4.2-2.3-5.3-5.5-5.3h-2.2v13.1h-7.5V1331z
    M1331.1,1345.4h3c3.8,0,6-1.9,6-4.8c0-3.1-2.1-4.6-5.6-4.6c-1.8,0-2.9,0.1-3.4,0.3V1345.4z"
							/>
							<path
								fill="#FFFFFF"
								d="M1353.1,1331c2.4-0.4,6-0.7,10.1-0.7c5,0,8.4,0.8,10.8,2.7c2,1.6,3.1,3.9,3.1,7c0,4.2-3,7.2-5.9,8.2
    v0.2c2.3,0.9,3.6,3.2,4.5,6.2c1,3.8,2,8.1,2.7,9.4h-7.7c-0.5-1-1.3-3.7-2.3-7.8c-0.9-4.2-2.3-5.3-5.5-5.3h-2.2v13.1h-7.5V1331z
    M1360.6,1345.4h3c3.8,0,6-1.9,6-4.8c0-3.1-2.1-4.6-5.6-4.6c-1.8,0-2.9,0.1-3.4,0.3V1345.4z"
							/>
							<path fill="#FFFFFF" d="M1390.1,1330.5v33.5h-7.6v-33.5H1390.1z" />
							<path fill="#FFFFFF" d="M1407.8,1348.4v5h-13v-5H1407.8z" />
							<path
								fill="#FFFFFF"
								d="M1420.2,1330.5v19.2c0,5.8,2.2,8.7,6.1,8.7c4,0,6.2-2.8,6.2-8.7v-19.2h7.6v18.8
    c0,10.3-5.2,15.2-14,15.2c-8.4,0-13.4-4.7-13.4-15.3v-18.7H1420.2z"
							/>
							<path
								fill="#FFFFFF"
								d="M1475.1,1369.7c-4.8-1.4-8.7-2.8-13.2-4.7c-0.7-0.3-1.5-0.5-2.3-0.5c-7.6-0.5-14.6-6.1-14.6-17
    c0-10,6.3-17.6,16.4-17.6c10.2,0,15.8,7.7,15.8,16.9c0,7.6-3.5,13-7.9,14.9v0.2c2.6,0.8,5.4,1.4,8.1,1.9L1475.1,1369.7z
    M1469.1,1347.2c0-6.1-2.9-11.2-8-11.2c-5.2,0-8.2,5-8.1,11.3c0,6.4,3,11.2,8.1,11.2
    C1466.2,1358.5,1469.1,1353.7,1469.1,1347.2z"
							/>
							<path
								fill="#FFFFFF"
								d="M1489.1,1355.4l-2.4,8.6h-7.8l10.2-33.5h9.9l10.4,33.5h-8.2l-2.6-8.6H1489.1z M1497.6,1349.8l-2.1-7.1
    c-0.6-2-1.2-4.5-1.7-6.4h-0.1c-0.5,2-1,4.5-1.5,6.5l-2,7.1H1497.6z"
							/>
							<path
								fill="#FFFFFF"
								d="M1542.1,1351.2c-0.2-4.1-0.3-8.9-0.3-13.8h-0.1c-1.1,4.3-2.5,9-3.7,13l-4.1,13h-5.9l-3.6-12.9
    c-1.1-3.9-2.2-8.7-3-13.1h-0.1c-0.2,4.6-0.4,9.7-0.6,13.9l-0.6,12.7h-7l2.1-33.5h10.1l3.3,11.2c1.1,3.8,2.1,8,2.8,12h0.2
    c0.9-3.9,2-8.3,3.2-12l3.6-11.1h9.9l1.8,33.5h-7.4L1542.1,1351.2z"
							/>
						</g>
					</g>
					<text
						transform="matrix(1 0 0 1 1520 1305)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor="end"
					>
						{Utils.getFormattedValueFromStation(data.berriUqam, dataKey)}
					</text>
				</g>
				<g id="Sherbrooke">
					<ellipse
						id="Point_00000080911919292808565370000013129415982735684789_"
						transform="matrix(0.1602 -0.9871 0.9871 0.1602 53.2347 2588.8638)"
						fill="#FFFFFF"
						cx="1548"
						cy="1263.1"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000168110107102454821940000010028546448129932204_">
						<path
							fill="#353535"
							d="M1588,1239.7c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L1588,1239.7z"
						/>
						<path fill="#353535" d="M1610.7,1222.6v8.6h8.3v-8.6h5v22.3h-5v-9.3h-8.3v9.3h-5v-22.3H1610.7z" />
						<path
							fill="#353535"
							d="M1641.7,1235.5h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1235.5z"
						/>
						<path
							fill="#353535"
							d="M1646.1,1222.9c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1222.9z M1651.1,1232.5h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1232.5z"
						/>
						<path
							fill="#353535"
							d="M1665.7,1222.9c1.3-0.3,4-0.5,6.5-0.5c3.1,0,5,0.3,6.6,1.2c1.6,0.8,2.7,2.3,2.7,4.4c0,2-1.1,3.8-3.7,4.7
    v0.1c2.5,0.7,4.4,2.6,4.4,5.5c0,2-0.9,3.6-2.3,4.7c-1.6,1.3-4.3,2-8.8,2c-2.5,0-4.3-0.2-5.4-0.3V1222.9z M1670.7,1231.5h1.6
    c2.7,0,4.1-1.1,4.1-2.8c0-1.7-1.3-2.6-3.6-2.6c-1.1,0-1.7,0.1-2.1,0.2V1231.5z M1670.7,1241.3c0.5,0.1,1.1,0.1,1.9,0.1
    c2.3,0,4.3-0.9,4.3-3.2c0-2.2-2-3.1-4.5-3.1h-1.7V1241.3z"
						/>
						<path
							fill="#353535"
							d="M1685.6,1222.9c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1222.9z M1690.6,1232.5h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1232.5z"
						/>
						<path
							fill="#353535"
							d="M1725.4,1233.6c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1721.7,1222.3,1725.4,1227.4,1725.4,1233.6z M1709.4,1233.8c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1711.4,1226.3,1709.4,1229.5,1709.4,1233.8z"
						/>
						<path
							fill="#353535"
							d="M1749.1,1233.6c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1745.4,1222.3,1749.1,1227.4,1749.1,1233.6z M1733.1,1233.8c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1735,1226.3,1733.1,1229.5,1733.1,1233.8z"
						/>
						<path
							fill="#353535"
							d="M1752.5,1222.6h5v9.8h0.1c0.5-0.8,1-1.6,1.5-2.4l5-7.4h6.2l-7.4,9.5l7.8,12.8h-5.9l-5.5-9.6l-1.9,2.3
    v7.3h-5V1222.6z"
						/>
						<path
							fill="#353535"
							d="M1786,1235.5h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1235.5z"
						/>
					</g>
					<g id="Value_00000091694989693537065420000007586829490361052831_"></g>
					<text
						transform="matrix(1 0 0 1 1588.376 1273)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.sherbrooke, dataKey)}
					</text>
				</g>
				<g id="Mont-Royal">
					<ellipse
						id="Point_00000033334553140216955140000007020595810498917793_"
						transform="matrix(0.1602 -0.9871 0.9871 0.1602 83.1681 2462.3464)"
						fill="#FFFFFF"
						cx="1488.7"
						cy="1182.3"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000090979211654718090410000002753282104393129645_">
						<path
							fill="#353535"
							d="M1550.9,1152.4c-0.1-2.7-0.2-5.9-0.2-9.2h-0.1c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7h-3.9l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4h-4.7l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L1550.9,1152.4z"
						/>
						<path
							fill="#353535"
							d="M1580.4,1149.6c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1576.7,1138.3,1580.4,1143.4,1580.4,1149.6z M1564.3,1149.8c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1566.3,1142.3,1564.3,1145.5,1564.3,1149.8z"
						/>
						<path
							fill="#353535"
							d="M1583.8,1160.9v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6
    v22.3h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1583.8z"
						/>
						<path fill="#353535" d="M1610.8,1142.9h-6v-4.2h17.1v4.2h-6.1v18h-5V1142.9z" />
						<path fill="#353535" d="M1630.1,1150.6v3.3h-8.6v-3.3H1630.1z" />
						<path
							fill="#353535"
							d="M1633.3,1139c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7c0,2.8-2,4.8-3.9,5.5
    v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1139z
    M1638.3,1148.5h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2V1148.5z"
						/>
						<path
							fill="#353535"
							d="M1673.1,1149.6c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1669.4,1138.3,1673.1,1143.4,1673.1,1149.6z M1657,1149.8c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1659,1142.3,1657,1145.5,1657,1149.8z"
						/>
						<path
							fill="#353535"
							d="M1680.4,1160.9v-9.1l-7-13.1h5.8l2.3,5.4c0.7,1.6,1.2,2.8,1.7,4.3h0.1c0.5-1.4,1-2.7,1.7-4.3l2.2-5.4
    h5.7l-7.3,13v9.3H1680.4z"
						/>
						<path
							fill="#353535"
							d="M1697.3,1155.2l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1697.3z M1702.9,1151.4l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1702.9z"
						/>
						<path fill="#353535" d="M1713.8,1138.6h5v18h8.9v4.2h-13.9V1138.6z" />
					</g>
					<text
						transform="matrix(1 0 0 1 1530 1190)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.montRoyal, dataKey)}
					</text>
				</g>
				<g id="Laurier">
					<ellipse
						id="Point_00000174569493344777849130000007839492173167803554_"
						transform="matrix(0.2298 -0.9732 0.9732 0.2298 22.4703 2240.3164)"
						fill="#FFFFFF"
						cx="1426.6"
						cy="1106"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000060725857760052012950000011186833124726543513_">
						<path fill="#353535" d="M1473.1,1057.1h5v18h8.9v4.2h-13.9V1057.1z" />
						<path
							fill="#353535"
							d="M1495.3,1073.7l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1495.3z M1501,1069.9l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1501z"
						/>
						<path
							fill="#353535"
							d="M1515.7,1057.1v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H1515.7z"
						/>
						<path
							fill="#353535"
							d="M1533.2,1057.4c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1057.4z M1538.2,1067h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1067z"
						/>
						<path fill="#353535" d="M1557.8,1057.1v22.3h-5v-22.3H1557.8z" />
						<path
							fill="#353535"
							d="M1575.4,1070h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1070z"
						/>
						<path
							fill="#353535"
							d="M1579.8,1057.4c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1057.4z M1584.8,1067h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1067z"
						/>
					</g>
					<g id="Value_00000105429073522135625690000017091666548501015426_"></g>
					<text
						transform="matrix(1 0 0 1 1606.376 1079.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.laurier, dataKey)}
					</text>
				</g>
				<g id="Rosemont">
					<circle
						id="Point_00000125585809845109933300000012309525228135647374_"
						fill="#FFFFFF"
						cx="1363.4"
						cy="1022.8"
						r="18.3"
					/>
					<g id="Name_00000111910719889486807950000001199727100418822531_">
						<path
							fill="#353535"
							d="M1408.2,975.1c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V975.1z M1413.2,984.7h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V984.7z"
						/>
						<path
							fill="#353535"
							d="M1448,985.7c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1444.3,974.4,1448,979.6,1448,985.7z M1431.9,986c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1433.9,978.4,1431.9,981.7,1431.9,986z"
						/>
						<path
							fill="#353535"
							d="M1451.5,991.9c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L1451.5,991.9z"
						/>
						<path
							fill="#353535"
							d="M1482.4,987.7h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V987.7z"
						/>
						<path
							fill="#353535"
							d="M1505.6,988.6c-0.1-2.7-0.2-5.9-0.2-9.2h-0.1c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7h-3.9l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4h-4.7l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L1505.6,988.6z"
						/>
						<path
							fill="#353535"
							d="M1535.1,985.7c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1531.4,974.4,1535.1,979.6,1535.1,985.7z M1519.1,986c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1521,978.4,1519.1,981.7,1519.1,986z"
						/>
						<path
							fill="#353535"
							d="M1538.5,997.1v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1538.5z"
						/>
						<path fill="#353535" d="M1565.6,979h-6v-4.2h17.1v4.2h-6.1v18h-5V979z" />
					</g>
					<g id="Value_00000166641810961336899530000011344700857096906893_"></g>
					<text
						transform="matrix(1 0 0 1 1586 996)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.rosemont, dataKey)}
					</text>
				</g>
				<g id="Beaubien">
					<circle
						id="Point_00000077285798117579069770000000362512222417404565_"
						fill="#FFFFFF"
						cx="1302.7"
						cy="940.5"
						r="18.3"
					/>
					<g id="Name_00000035520394810920446920000006210645911436183987_">
						<path
							fill="#353535"
							d="M1348.3,891.9c1.3-0.3,4-0.5,6.5-0.5c3.1,0,5,0.3,6.6,1.2c1.6,0.8,2.7,2.3,2.7,4.4c0,2-1.1,3.8-3.7,4.7
    v0.1c2.5,0.7,4.4,2.6,4.4,5.5c0,2-0.9,3.6-2.3,4.7c-1.6,1.3-4.3,2-8.8,2c-2.5,0-4.3-0.2-5.4-0.3V891.9z M1353.3,900.5h1.6
    c2.7,0,4.1-1.1,4.1-2.8c0-1.7-1.3-2.6-3.6-2.6c-1.1,0-1.7,0.1-2.1,0.2V900.5z M1353.3,910.3c0.5,0.1,1.1,0.1,1.9,0.1
    c2.3,0,4.3-0.9,4.3-3.2c0-2.2-2-3.1-4.5-3.1h-1.7V910.3z"
						/>
						<path
							fill="#353535"
							d="M1381.5,904.5h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V904.5z"
						/>
						<path
							fill="#353535"
							d="M1391.1,908.2l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1391.1z M1396.7,904.4l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1396.7z"
						/>
						<path
							fill="#353535"
							d="M1411.4,891.6v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H1411.4z"
						/>
						<path
							fill="#353535"
							d="M1429,891.9c1.3-0.3,4-0.5,6.5-0.5c3.1,0,5,0.3,6.6,1.2c1.6,0.8,2.7,2.3,2.7,4.4c0,2-1.1,3.8-3.7,4.7
    v0.1c2.5,0.7,4.4,2.6,4.4,5.5c0,2-0.9,3.6-2.3,4.7c-1.6,1.3-4.3,2-8.8,2c-2.5,0-4.3-0.2-5.4-0.3V891.9z M1434,900.5h1.6
    c2.7,0,4.1-1.1,4.1-2.8c0-1.7-1.3-2.6-3.6-2.6c-1.1,0-1.7,0.1-2.1,0.2V900.5z M1434,910.3c0.5,0.1,1.1,0.1,1.9,0.1
    c2.3,0,4.3-0.9,4.3-3.2c0-2.2-2-3.1-4.5-3.1h-1.7V910.3z"
						/>
						<path fill="#353535" d="M1453.9,891.6v22.3h-5v-22.3H1453.9z" />
						<path
							fill="#353535"
							d="M1471.6,904.5h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V904.5z"
						/>
						<path
							fill="#353535"
							d="M1476,913.9v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1476z"
						/>
					</g>
					<g id="Value_00000122679202480932024980000017251074875319505585_"></g>
					<text
						transform="matrix(1 0 0 1 1508.376 912)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.beaubien, dataKey)}
					</text>
				</g>
				<g id="Jean-Talon_00000015324348052505415020000000415717983616287634_">
					<ellipse
						id="Point_00000017506670571412250540000005776715016308217005_"
						transform="matrix(0.9239 -0.3827 0.3827 0.9239 -228.8824 537.3048)"
						fill="#FFFFFF"
						stroke="#231F20"
						strokeWidth="5"
						strokeMiterlimit="10"
						cx="1236.2"
						cy="844"
						rx="32.9"
						ry="32.9"
					/>
					<g id="Name_00000077283121159519358860000012187139041696171189_">
						<path
							fill="#353535"
							d="M893.9,815.6h7.6v21.2c0,9.5-4.6,12.8-11.9,12.8c-1.8,0-4-0.3-5.5-0.8l0.8-6.1c1,0.3,2.4,0.6,3.9,0.6
    c3.2,0,5.2-1.4,5.2-6.6V815.6z"
						/>
						<path fill="#353535" d="M927.7,834.9h-12.3v8h13.8v6.2h-21.3v-33.5h20.6v6.2h-13v7h12.3V834.9z" />
						<path
							fill="#353535"
							d="M942.1,840.4l-2.4,8.6h-7.8l10.2-33.5h9.9l10.4,33.5h-8.2l-2.6-8.6H942.1z M950.6,834.8l-2.1-7.1
    c-0.6-2-1.2-4.5-1.7-6.4h-0.1c-0.5,2-1,4.5-1.5,6.5l-2,7.1H950.6z"
						/>
						<path
							fill="#353535"
							d="M966.9,849v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9V849h-7.9
    l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6V849H966.9z"
						/>
						<path fill="#353535" d="M1012.3,833.5v5h-13v-5H1012.3z" />
						<path fill="#353535" d="M1020.9,821.9h-9v-6.4h25.8v6.4h-9.2V849h-7.6V821.9z" />
						<path
							fill="#353535"
							d="M1045.8,840.4l-2.4,8.6h-7.8l10.2-33.5h9.9l10.4,33.5h-8.2l-2.6-8.6H1045.8z M1054.3,834.8l-2.1-7.1
    c-0.6-2-1.2-4.5-1.7-6.4h-0.1c-0.5,2-1,4.5-1.5,6.5l-2,7.1H1054.3z"
						/>
						<path fill="#353535" d="M1070.6,815.6h7.6v27.1h13.3v6.3h-20.9V815.6z" />
						<path
							fill="#353535"
							d="M1124.7,832c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C1119.1,815,1124.7,822.7,1124.7,832z M1100.5,832.4c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C1103.5,821,1100.5,825.9,1100.5,832.4z"
						/>
						<path
							fill="#353535"
							d="M1129.7,849v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9V849h-7.9
    l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6V849H1129.7z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 875 840)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.jeanTalon, dataKey)}
					</text>
				</g>
				<g id="Jarry">
					<ellipse
						id="Point_00000106126646312402695370000018308789567824830884_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -195.1431 1042.2068)"
						fill="#FFFFFF"
						cx="1160.5"
						cy="756.7"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000004547290338817124520000016110872567546913922_">
						<path
							fill="#353535"
							d="M1203,692.9h5V707c0,6.3-3,8.5-7.9,8.5c-1.2,0-2.7-0.2-3.7-0.5l0.6-4.1c0.7,0.2,1.6,0.4,2.6,0.4
    c2.1,0,3.4-0.9,3.4-4.4V692.9z"
						/>
						<path
							fill="#353535"
							d="M1217.4,709.4l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1217.4z M1223,705.7l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1223z"
						/>
						<path
							fill="#353535"
							d="M1233.9,693.2c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V693.2z M1238.9,702.8h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V702.8z"
						/>
						<path
							fill="#353535"
							d="M1253.5,693.2c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V693.2z M1258.4,702.8h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V702.8z"
						/>
						<path
							fill="#353535"
							d="M1277.6,715.1V706l-7-13.1h5.8l2.3,5.4c0.7,1.6,1.2,2.8,1.7,4.3h0.1c0.5-1.4,1-2.7,1.7-4.3l2.2-5.4h5.7
    l-7.3,13v9.3H1277.6z"
						/>
					</g>
					<g id="Value_00000052088032258125140370000011701488573475361202_"></g>
					<text
						transform="matrix(1 0 0 1 1196.376 745)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.jarry, dataKey)}
					</text>
				</g>
				<g id="Crémazie">
					<ellipse
						id="Point_00000147180375041701011550000016715032496679081108_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -155.1927 973.9857)"
						fill="#FFFFFF"
						cx="1098.1"
						cy="674.3"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000147212047476394984330000016579182674255058871_">
						<path
							fill="#353535"
							d="M1152.6,633c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L1152.6,633z"
						/>
						<path
							fill="#353535"
							d="M1155.9,611.7c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V611.7z M1160.9,621.2h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V621.2z"
						/>
						<path
							fill="#353535"
							d="M1188.7,624.2h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V624.2z"
						/>
						<path
							fill="#353535"
							d="M1211.9,625.1c-0.1-2.7-0.2-5.9-0.2-9.2h-0.1c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7h-3.9l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4h-4.7l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L1211.9,625.1z"
						/>
						<path
							fill="#353535"
							d="M1226.1,627.9l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1226.1z M1231.8,624.2l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1231.8z"
						/>
						<path
							fill="#353535"
							d="M1241.4,630.9l10.6-15.3v-0.1h-9.6v-4.2h16.2v2.9l-10.4,15.1v0.1h10.5v4.2h-17.3V630.9z"
						/>
						<path fill="#353535" d="M1267,611.4v22.3h-5v-22.3H1267z" />
						<path
							fill="#353535"
							d="M1284.6,624.2h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V624.2z"
						/>
						<g>
							<path fill="#353535" d="M1182.6,602.4l-4,4.7h-2.1l2.9-4.7H1182.6z" />
						</g>
					</g>
					<g id="Value_00000158744530816623074900000001189463498254771639_"></g>
					<text
						transform="matrix(1 0 0 1 1298.376 633.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.cremazie, dataKey)}
					</text>
				</g>
				<g id="Sauvé">
					<ellipse
						id="Point_00000013169258650389109270000013743772183743821731_"
						transform="matrix(0.1602 -0.9871 0.9871 0.1602 286.1776 1520.3456)"
						fill="#FFFFFF"
						cx="1036.6"
						cy="592"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000131354109497212254890000002715343889626309523_">
						<g>
							<path
								fill="#353535"
								d="M1075.7,546.1c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L1075.7,546.1z"
							/>
							<path
								fill="#353535"
								d="M1098.5,545.6l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1098.5z M1104.2,541.8l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1104.2z"
							/>
							<path
								fill="#353535"
								d="M1118.9,529v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8V529h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2V529H1118.9z"
							/>
							<path
								fill="#353535"
								d="M1141.7,551.3l-7.1-22.3h5.5l2.7,9.4c0.8,2.6,1.5,5.2,2,7.9h0.1c0.6-2.7,1.3-5.3,2-7.9l2.8-9.5h5.4
    l-7.5,22.3H1141.7z"
							/>
							<path
								fill="#353535"
								d="M1170.7,541.9h-8.2v5.3h9.2v4.1h-14.2V529h13.7v4.1h-8.7v4.6h8.2V541.9z"
							/>
						</g>
						<g>
							<path fill="#353535" d="M1166,520.1l-4,4.7h-2.1l2.9-4.7H1166z" />
						</g>
					</g>
					<g id="Value_00000093135860773844253220000000067292481606037643_"></g>
					<text
						transform="matrix(1 0 0 1 1185.376 550)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.sauve, dataKey)}
					</text>
				</g>
				<g id="Henri-Bourassa">
					<ellipse
						id="Point_00000155135221974583660100000008103545494910810813_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -73.3258 833.9731)"
						fill="#FFFFFF"
						cx="970"
						cy="505.5"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000024711684932912960660000012658364402651652257_">
						<path fill="#353535" d="M1011.5,440.9v8.6h8.3v-8.6h5v22.3h-5v-9.3h-8.3v9.3h-5v-22.3H1011.5z" />
						<path
							fill="#353535"
							d="M1042.5,453.7h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V453.7z"
						/>
						<path
							fill="#353535"
							d="M1046.9,463.1v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1046.9z"
						/>
						<path
							fill="#353535"
							d="M1069.7,441.2c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V441.2z M1074.7,450.8h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V450.8z"
						/>
						<path fill="#353535" d="M1094.3,440.9v22.3h-5v-22.3H1094.3z" />
						<path fill="#353535" d="M1106.2,452.8v3.3h-8.6v-3.3H1106.2z" />
						<path
							fill="#353535"
							d="M1109.3,441.2c1.3-0.3,4-0.5,6.5-0.5c3.1,0,5,0.3,6.6,1.2c1.6,0.8,2.7,2.3,2.7,4.4c0,2-1.1,3.8-3.7,4.7
    v0.1c2.5,0.7,4.4,2.6,4.4,5.5c0,2-0.9,3.6-2.3,4.7c-1.6,1.3-4.3,2-8.8,2c-2.5,0-4.3-0.2-5.4-0.3V441.2z M1114.3,449.7h1.6
    c2.7,0,4.1-1.1,4.1-2.8c0-1.7-1.3-2.6-3.6-2.6c-1.1,0-1.7,0.1-2.1,0.2V449.7z M1114.3,459.6c0.5,0.1,1.1,0.1,1.9,0.1
    c2.3,0,4.3-0.9,4.3-3.2c0-2.2-2-3.1-4.5-3.1h-1.7V459.6z"
						/>
						<path
							fill="#353535"
							d="M1149.7,451.8c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1145.9,440.5,1149.7,445.6,1149.7,451.8z M1133.6,452.1c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1135.6,444.5,1133.6,447.7,1133.6,452.1z"
						/>
						<path
							fill="#353535"
							d="M1158,440.9v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H1158z"
						/>
						<path
							fill="#353535"
							d="M1175.5,441.2c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V441.2z M1180.5,450.8h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V450.8z"
						/>
						<path
							fill="#353535"
							d="M1200.6,457.4l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1200.6z M1206.2,453.7l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1206.2z"
						/>
						<path
							fill="#353535"
							d="M1217.2,457.9c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L1217.2,457.9z"
						/>
						<path
							fill="#353535"
							d="M1235.1,457.9c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L1235.1,457.9z"
						/>
						<path
							fill="#353535"
							d="M1257.9,457.4l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1257.9z M1263.6,453.7l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1263.6z"
						/>
					</g>
					<g id="Value_00000117642292985847056270000001567595959404051088_"></g>
					<text
						transform="matrix(1 0 0 1 1285.376 463.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.henriBourassa, dataKey)}
					</text>
				</g>
				<g id="Carter">
					<ellipse
						id="Point_00000096016773443903308350000016545915798278255546_"
						transform="matrix(0.2902 -0.957 0.957 0.2902 286.14 1000.9232)"
						fill="#FFFFFF"
						cx="817.8"
						cy="307.6"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000136407907640099635580000012206115342750189466_">
						<path
							fill="#353535"
							d="M878.1,277.1c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L878.1,277.1z"
						/>
						<path
							fill="#353535"
							d="M886.9,272l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H886.9z M892.6,268.2l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3H890c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H892.6z"
						/>
						<path
							fill="#353535"
							d="M903.5,255.7c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7c0,2.8-2,4.8-3.9,5.5
    v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V255.7
    z M908.4,265.3h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2V265.3z"
						/>
						<path fill="#353535" d="M927.4,259.6h-6v-4.2h17.1v4.2h-6.1v18h-5V259.6z" />
						<path
							fill="#353535"
							d="M954.4,268.3h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V268.3z"
						/>
						<path
							fill="#353535"
							d="M958.8,255.7c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7c0,2.8-2,4.8-3.9,5.5
    v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V255.7
    z M963.8,265.3h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2V265.3z"
						/>
					</g>
					<g id="Value"></g>
					<text
						transform="matrix(1 0 0 1 986.3759 278.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.carter, dataKey)}
					</text>
				</g>
				<g id="De_la_Concorde">
					<ellipse
						id="Point_00000021112439479228837310000008401680300895891855_"
						transform="matrix(0.1443 -0.9895 0.9895 0.1443 231.0056 777.4576)"
						fill="#FFFFFF"
						cx="565"
						cy="255.2"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000180339305729709136620000005366030261981238459_">
						<path
							fill="#353535"
							d="M582.4,184.2c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4c0,4.3-1.5,7.2-3.7,9
    c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V184.2z M587.4,202.4c0.4,0.1,1.1,0.1,1.8,0.1c4.6,0,7.6-2.5,7.6-7.8
    c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V202.4z"
						/>
						<path
							fill="#353535"
							d="M618.6,196.7h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V196.7z"
						/>
						<path fill="#353535" d="M629.7,183.9h5v18h8.9v4.2h-13.9V183.9z" />
						<path
							fill="#353535"
							d="M651.9,200.4l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3H660l-1.7-5.7H651.9z M657.6,196.7l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3H655c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H657.6z"
						/>
						<path
							fill="#353535"
							d="M691.4,205.5c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L691.4,205.5z"
						/>
						<path
							fill="#353535"
							d="M714.1,194.8c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C710.4,183.5,714.1,188.6,714.1,194.8z M698,195.1c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C700,187.5,698,190.8,698,195.1z"
						/>
						<path
							fill="#353535"
							d="M717.4,206.2v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H717.4z"
						/>
						<path
							fill="#353535"
							d="M756.6,205.5c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L756.6,205.5z"
						/>
						<path
							fill="#353535"
							d="M779.3,194.8c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C775.5,183.5,779.3,188.6,779.3,194.8z M763.2,195.1c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C765.2,187.5,763.2,190.8,763.2,195.1z"
						/>
						<path
							fill="#353535"
							d="M782.6,184.2c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7c0,2.8-2,4.8-3.9,5.5
    v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V184.2
    z M787.6,193.8h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2V193.8z"
						/>
						<path
							fill="#353535"
							d="M802.2,184.2c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4c0,4.3-1.5,7.2-3.7,9
    c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V184.2z M807.2,202.4c0.4,0.1,1.1,0.1,1.8,0.1c4.6,0,7.6-2.5,7.6-7.8
    c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V202.4z"
						/>
						<path
							fill="#353535"
							d="M838.4,196.7h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V196.7z"
						/>
					</g>
					<g id="Value_00000127015091451875664890000005729034266883822271_"></g>
					<text
						transform="matrix(1 0 0 1 851.376 204)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.deLaConcorde, dataKey)}
					</text>
				</g>
				<g id="Montmorency">
					<circle
						id="Point_00000124883681042991153570000018192898438559726998_"
						fill="#FFFFFF"
						stroke="#231F20"
						strokeWidth="5"
						strokeMiterlimit="10"
						cx="305.5"
						cy="254.3"
						r="32.9"
					/>
					<g id="Name_00000079466309208822217750000011452091385361634491_">
						<rect x="130.4" y="130.2" fill="#EC7300" width="401.7" height="70.7" />
						<g>
							<path
								fill="#FFFFFF"
								d="M178.5,169.5c-0.2-4.1-0.3-8.9-0.3-13.8H178c-1.1,4.3-2.5,9-3.7,13l-4.1,13h-5.9l-3.6-12.9
    c-1.1-3.9-2.2-8.7-3-13.1h-0.1c-0.2,4.6-0.4,9.7-0.6,13.9l-0.6,12.7h-7l2.1-33.5h10.1l3.3,11.2c1.1,3.8,2.1,8,2.8,12h0.2
    c0.9-3.9,2-8.3,3.2-12l3.6-11.1h9.9l1.8,33.5H179L178.5,169.5z"
							/>
							<path
								fill="#FFFFFF"
								d="M222.8,165.2c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C217.2,148.3,222.8,155.9,222.8,165.2z M198.6,165.6c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C201.6,154.3,198.6,159.1,198.6,165.6z"
							/>
							<path
								fill="#FFFFFF"
								d="M227.8,182.3v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9v33.5
    h-7.9l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6v10.1H227.8z"
							/>
							<path fill="#FFFFFF" d="M268.5,155.2h-9v-6.4h25.8v6.4h-9.2v27.1h-7.6V155.2z" />
							<path
								fill="#FFFFFF"
								d="M317.3,169.5c-0.2-4.1-0.3-8.9-0.3-13.8h-0.1c-1.1,4.3-2.5,9-3.7,13l-4.1,13h-5.9l-3.6-12.9
    c-1.1-3.9-2.2-8.7-3-13.1h-0.1c-0.2,4.6-0.4,9.7-0.6,13.9l-0.6,12.7h-7l2.1-33.5h10.1l3.3,11.2c1.1,3.8,2.1,8,2.8,12h0.2
    c0.9-3.9,2-8.3,3.2-12l3.6-11.1h9.9l1.8,33.5h-7.4L317.3,169.5z"
							/>
							<path
								fill="#FFFFFF"
								d="M361.7,165.2c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C356,148.3,361.7,155.9,361.7,165.2z M337.5,165.6c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C340.5,154.3,337.5,159.1,337.5,165.6z"
							/>
							<path
								fill="#FFFFFF"
								d="M366.7,149.3c2.4-0.4,6-0.7,10.1-0.7c5,0,8.4,0.8,10.8,2.7c2,1.6,3.1,3.9,3.1,7c0,4.2-3,7.2-5.9,8.2
    v0.2c2.3,0.9,3.6,3.2,4.5,6.2c1,3.8,2,8.1,2.7,9.4h-7.7c-0.5-1-1.3-3.7-2.3-7.8c-0.9-4.2-2.3-5.3-5.5-5.3h-2.2v13.1h-7.5V149.3
    z M374.2,163.7h3c3.8,0,6-1.9,6-4.8c0-3.1-2.1-4.6-5.6-4.6c-1.8,0-2.9,0.1-3.4,0.3V163.7z"
							/>
							<path
								fill="#FFFFFF"
								d="M416,168.1h-12.3v8h13.8v6.2h-21.3v-33.5h20.6v6.2h-13v7H416V168.1z"
							/>
							<path
								fill="#FFFFFF"
								d="M422.6,182.3v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9v33.5
    h-7.9l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6v10.1H422.6z"
							/>
							<path
								fill="#FFFFFF"
								d="M481.4,181.3c-1.4,0.7-4.5,1.4-8.6,1.4c-11.6,0-17.6-7.2-17.6-16.8c0-11.4,8.2-17.7,18.3-17.7
    c3.9,0,6.9,0.8,8.2,1.5l-1.5,6c-1.5-0.7-3.7-1.3-6.4-1.3c-6,0-10.6,3.6-10.6,11.1c0,6.7,4,10.9,10.7,10.9
    c2.3,0,4.8-0.5,6.3-1.1L481.4,181.3z"
							/>
							<path
								fill="#FFFFFF"
								d="M494.5,182.3v-13.7l-10.6-19.7h8.7l3.4,8.1c1,2.4,1.8,4.2,2.6,6.4h0.1c0.7-2.1,1.6-4,2.5-6.4l3.4-8h8.5
    l-11,19.5v14H494.5z"
							/>
						</g>
					</g>
					<g id="Value_00000143589893631182680280000013309577576753859259_"></g>
					<text
						transform="matrix(1 0 0 1 130.376 120)"
						fontFamily="'Arial-BoldMT'"
						fill={'#EC7300'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.montmorrency, dataKey)}
					</text>
				</g>
			</g>
		</g>
	)
}
