import * as React from 'react'
import { MetroLineCommonStations, MetroContracts } from '../../../contracts/api/Metro.contracts'
import { Utils } from '../../../utils'
import { MetroLineBlueType } from './MetroBlueLineStations'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MetroBlueLineProps {
	data: MetroLineBlueType & MetroLineCommonStations
	dataKey: keyof MetroContracts
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MetroBlueLine: React.FC<MetroBlueLineProps> = (props) => {
	const { dataKey, data } = props

	// Render
	//--------------------------------------------------------------------------
	return (
		<g id="Blue_line">
			<path
				id="Line_00000000207520760149329990000012514902468698610350_"
				fill="none"
				stroke="#3281D2"
				strokeWidth="50"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				d="
    M711.8,1639.5l423.3-313.5c35.3-26.9,27-63.5-0.8-98.1l-104-129.7c-27.9-34.7-24.7-85.4,10.8-112.3L1521,637.3"
			/>
			<g id="Stations_00000047022498441594900100000004491032318076217012_">
				<g id="Snowdon">
					<ellipse
						id="Point_00000090998604602049202030000016387954363891786653_"
						transform="matrix(0.9271 -0.3748 0.3748 0.9271 -563.3375 386.1653)"
						fill="#FFFFFF"
						stroke="#231F20"
						strokeWidth="5"
						strokeMiterlimit="10"
						cx="711"
						cy="1641.1"
						rx="34.1"
						ry="34.1"
					/>
					<g id="Name_00000066475500660199501380000003620138925716184215_">
						<rect x="379" y="1644.2" fill="#3281D2" width="273.6" height="70.7" />
						<g>
							<path
								fill="#FFFFFF"
								d="M397.5,1688.5c2,1.1,5.2,2.1,8.4,2.1c3.5,0,5.3-1.4,5.3-3.6c0-2.1-1.6-3.3-5.6-4.7
    c-5.6-2-9.2-5-9.2-9.9c0-5.7,4.8-10,12.7-10c3.8,0,6.6,0.8,8.5,1.7l-1.7,6.1c-1.3-0.6-3.7-1.6-7-1.6c-3.3,0-4.9,1.5-4.9,3.2
    c0,2.1,1.9,3.1,6.2,4.7c5.9,2.2,8.7,5.3,8.7,10c0,5.6-4.3,10.4-13.5,10.4c-3.8,0-7.6-1-9.5-2.1L397.5,1688.5z"
							/>
							<path
								fill="#FFFFFF"
								d="M424.1,1696.3v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9v33.5
    h-7.9l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6v10.1H424.1z"
							/>
							<path
								fill="#FFFFFF"
								d="M489,1679.3c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C483.3,1662.3,489,1670,489,1679.3z M464.8,1679.7c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C467.8,1668.3,464.8,1673.2,464.8,1679.7z"
							/>
							<path
								fill="#FFFFFF"
								d="M499,1696.3l-7.9-33.5h8.1l2.5,13.8c0.7,4,1.4,8.3,1.9,11.7h0.1c0.5-3.6,1.3-7.6,2.2-11.8l2.9-13.7h8
    l2.7,14.1c0.7,3.9,1.3,7.5,1.8,11.2h0.1c0.5-3.7,1.2-7.6,2-11.6l2.7-13.7h7.7l-8.6,33.5h-8.2l-2.8-14.4
    c-0.7-3.3-1.2-6.5-1.6-10.3h-0.1c-0.6,3.8-1.1,6.9-1.9,10.3l-3.2,14.4H499z"
							/>
							<path
								fill="#FFFFFF"
								d="M537.7,1663.3c2.8-0.5,6.4-0.7,10.2-0.7c6.3,0,10.5,1.2,13.7,3.6c3.5,2.6,5.7,6.7,5.7,12.6
    c0,6.4-2.3,10.8-5.5,13.6c-3.5,2.9-8.9,4.3-15.4,4.3c-3.9,0-6.7-0.2-8.6-0.5V1663.3z M545.3,1690.6c0.7,0.1,1.7,0.1,2.6,0.1
    c6.9,0.1,11.4-3.7,11.4-11.7c0-7-4.1-10.6-10.6-10.6c-1.7,0-2.8,0.1-3.4,0.3V1690.6z"
							/>
							<path
								fill="#FFFFFF"
								d="M602.9,1679.3c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C597.2,1662.3,602.9,1670,602.9,1679.3z M578.7,1679.7c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C581.7,1668.3,578.7,1673.2,578.7,1679.7z"
							/>
							<path
								fill="#FFFFFF"
								d="M607.9,1696.3v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9v33.5
    h-7.9l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6v10.1H607.9z"
							/>
						</g>
					</g>
					<text
						transform="matrix(1 0 0 1 370 1690)"
						fontFamily="'Arial-BoldMT'"
						fill={'#3281D2'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.snowdon, dataKey)}
					</text>
				</g>
				<g id="Côte-des-neiges">
					<ellipse
						id="Point_00000015349620671261554190000008859146180898954683_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -840.9868 1046.5355)"
						fill="#FFFFFF"
						cx="842.8"
						cy="1538.4"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000031892494046723737470000010054522914046484664_">
						<path
							fill="#353535"
							d="M693.8,1504.6c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L693.8,1504.6z"
						/>
						<path
							fill="#353535"
							d="M716.5,1493.8c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C712.7,1482.6,716.5,1487.7,716.5,1493.8z M700.4,1494.1c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C702.4,1486.6,700.4,1489.8,700.4,1494.1z"
						/>
						<path fill="#353535" d="M723.2,1487.2h-6v-4.2h17.1v4.2h-6.1v18h-5V1487.2z" />
						<path fill="#353535" d="M750.2,1495.8H742v5.3h9.2v4.1H737v-22.3h13.7v4.1H742v4.6h8.2V1495.8z" />
						<path fill="#353535" d="M762.1,1494.8v3.3h-8.6v-3.3H762.1z" />
						<path
							fill="#353535"
							d="M765.2,1483.2c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V1483.2z M770.3,1501.4c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V1501.4z"
						/>
						<path
							fill="#353535"
							d="M801.5,1495.8h-8.2v5.3h9.2v4.1h-14.2v-22.3H802v4.1h-8.7v4.6h8.2V1495.8z"
						/>
						<path
							fill="#353535"
							d="M806,1500c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1c-3.7-1.3-6.1-3.3-6.1-6.6
    c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2c0,1.4,1.2,2,4.1,3.1
    c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L806,1500z"
						/>
						<path fill="#353535" d="M831.6,1494.8v3.3h-8.6v-3.3H831.6z" />
						<path
							fill="#353535"
							d="M677.4,1544.8v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,2.9,0.2,6.1,0.2,9.7v6.7H677.4z"
						/>
						<path
							fill="#353535"
							d="M713.5,1535.4h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1535.4z"
						/>
						<path fill="#353535" d="M722.9,1522.6v22.3h-5v-22.3H722.9z" />
						<path
							fill="#353535"
							d="M745.9,1543.8c-1.6,0.5-4.5,1.3-7.4,1.3c-4.1,0-7-1-9.1-3c-2-1.9-3.2-4.8-3.1-8.1
    c0-7.4,5.4-11.6,12.7-11.6c2.9,0,5.1,0.5,6.2,1.1l-1.1,4c-1.2-0.5-2.7-0.9-5.2-0.9c-4.2,0-7.4,2.4-7.4,7.2c0,4.6,2.9,7.3,7,7.3
    c1.1,0,2.1-0.1,2.5-0.3v-4.7h-3.4v-3.9h8.3V1543.8z"
						/>
						<path
							fill="#353535"
							d="M763,1535.4h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1535.4z"
						/>
						<path
							fill="#353535"
							d="M767.6,1539.6c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L767.6,1539.6z"
						/>
						<g>
							<path
								fill="#353535"
								d="M706.1,1475.7l3.2,4.7h-2.2l-1.9-3.1h-0.1l-1.9,3.1H701l3.1-4.7H706.1z"
							/>
						</g>
					</g>
					<text
						transform="matrix(1 0 0 1 835 1472)"
						fontFamily="'Arial-BoldMT'"
						fill={'#3281D2'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.coteDesNeiges, dataKey)}
					</text>
				</g>
				<g id="Université-de-Montréal">
					<circle
						id="Point_00000085966603017391417730000005080351835768347564_"
						fill="#FFFFFF"
						cx="967.5"
						cy="1449.4"
						r="18.3"
					/>
					<g id="Name_00000016797802703827447640000000875303542784989060_">
						<path
							fill="#353535"
							d="M738.5,1378v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8V1378h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2V1378H738.5z"
						/>
						<path
							fill="#353535"
							d="M756.1,1400.3V1378h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H756.1z"
						/>
						<path fill="#353535" d="M783.9,1378v22.3h-5V1378H783.9z" />
						<path
							fill="#353535"
							d="M793.5,1400.3l-7.1-22.3h5.5l2.7,9.4c0.8,2.6,1.5,5.2,2,7.9h0.1c0.6-2.7,1.3-5.3,2-7.9l2.8-9.5h5.4
    l-7.5,22.3H793.5z"
						/>
						<path
							fill="#353535"
							d="M822.5,1390.9h-8.2v5.3h9.2v4.1h-14.2V1378H823v4.1h-8.7v4.6h8.2V1390.9z"
						/>
						<path
							fill="#353535"
							d="M826.9,1378.3c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1378.3z M831.9,1387.9h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1387.9z"
						/>
						<path
							fill="#353535"
							d="M846.7,1395.1c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L846.7,1395.1z"
						/>
						<path fill="#353535" d="M869.4,1378v22.3h-5V1378H869.4z" />
						<path fill="#353535" d="M878.1,1382.2h-6v-4.2h17.1v4.2h-6.1v18h-5V1382.2z" />
						<path
							fill="#353535"
							d="M905.1,1390.9h-8.2v5.3h9.2v4.1h-14.2V1378h13.7v4.1h-8.7v4.6h8.2V1390.9z"
						/>
						<path fill="#353535" d="M917,1389.9v3.3h-8.6v-3.3H917z" />
						<path
							fill="#353535"
							d="M920.2,1378.3c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V1378.3z M925.2,1396.5c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V1396.5z"
						/>
						<path
							fill="#353535"
							d="M956.4,1390.9h-8.2v5.3h9.2v4.1h-14.2V1378h13.7v4.1h-8.7v4.6h8.2V1390.9z"
						/>
						<path fill="#353535" d="M968.3,1389.9v3.3h-8.6v-3.3H968.3z" />
						<path
							fill="#353535"
							d="M752.4,1431.4c-0.1-2.7-0.2-5.9-0.2-9.2h-0.1c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7H743l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4H733l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L752.4,1431.4z"
						/>
						<path
							fill="#353535"
							d="M781.9,1428.6c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C778.1,1417.3,781.9,1422.4,781.9,1428.6z M765.8,1428.9c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C767.8,1421.3,765.8,1424.5,765.8,1428.9z"
						/>
						<path
							fill="#353535"
							d="M785.2,1439.9v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,2.9,0.2,6.1,0.2,9.7v6.7H785.2z"
						/>
						<path fill="#353535" d="M812.3,1421.9h-6v-4.2h17.1v4.2h-6.1v18h-5V1421.9z" />
						<path
							fill="#353535"
							d="M826.1,1418c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7c0,2.8-2,4.8-3.9,5.5
    v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1418z
    M831.1,1427.5h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2V1427.5z"
						/>
						<path
							fill="#353535"
							d="M858.9,1430.5h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1430.5z"
						/>
						<path
							fill="#353535"
							d="M868.5,1434.2l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H868.5z M874.1,1430.5l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H874.1z"
						/>
						<path fill="#353535" d="M885,1417.7h5v18h8.9v4.2H885V1417.7z" />
						<g>
							<path fill="#353535" d="M858.1,1409.8l-4,4.7H852l2.9-4.7H858.1z" />
						</g>
						<g>
							<path fill="#353535" d="M903,1369.9l-4,4.7h-2.1l2.9-4.7H903z" />
						</g>
					</g>
					<g id="Value_00000116931441141686645540000018259426553772250271_"></g>
					<text
						transform="matrix(1 0 0 1 730 1364.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#3281D2'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.universiteDeMontreal, dataKey)}
					</text>
				</g>
				<g id="Édouard-Montpetit">
					<ellipse
						id="Point_00000182521050992287524730000000981198645361123769_"
						transform="matrix(0.9239 -0.3827 0.3827 0.9239 -436.9036 521.1129)"
						fill="#FFFFFF"
						cx="1091.5"
						cy="1358.8"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000162347120719150384830000015942744186956513464_">
						<path
							fill="#353535"
							d="M912.5,1278.7h-8.2v5.3h9.2v4.1h-14.2v-22.3H913v4.1h-8.7v4.6h8.2V1278.7z"
						/>
						<path
							fill="#353535"
							d="M916.9,1266.2c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V1266.2z M921.9,1284.4c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V1284.4z"
						/>
						<path
							fill="#353535"
							d="M960.2,1276.8c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C956.5,1265.5,960.2,1270.6,960.2,1276.8z M944.2,1277.1c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C946.1,1269.5,944.2,1272.7,944.2,1277.1z"
						/>
						<path
							fill="#353535"
							d="M968.6,1265.9v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H968.6z"
						/>
						<path
							fill="#353535"
							d="M990.3,1282.4l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H990.3z M995.9,1278.7l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H995.9z"
						/>
						<path
							fill="#353535"
							d="M1006.8,1266.2c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1266.2z M1011.8,1275.8h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1275.8z"
						/>
						<path
							fill="#353535"
							d="M1026.4,1266.2c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V1266.2z M1031.4,1284.4c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V1284.4z"
						/>
						<path fill="#353535" d="M1057.2,1277.8v3.3h-8.6v-3.3H1057.2z" />
						<path
							fill="#353535"
							d="M918.1,1319.3c-0.1-2.7-0.2-5.9-0.2-9.2h-0.1c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7h-3.9l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4h-4.7l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L918.1,1319.3z"
						/>
						<path
							fill="#353535"
							d="M947.5,1316.4c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C943.8,1305.2,947.5,1310.3,947.5,1316.4z M931.5,1316.7c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C933.5,1309.2,931.5,1312.4,931.5,1316.7z"
						/>
						<path
							fill="#353535"
							d="M950.9,1327.8v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    H964l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,2.9,0.2,6.1,0.2,9.7v6.7H950.9z"
						/>
						<path fill="#353535" d="M978,1309.8h-6v-4.2h17.1v4.2H983v18h-5V1309.8z" />
						<path
							fill="#353535"
							d="M991.8,1305.8c1.5-0.3,3.7-0.5,6.8-0.5c3.1,0,5.3,0.6,6.8,1.8c1.4,1.1,2.4,3,2.4,5.1c0,2.2-0.7,4-2,5.3
    c-1.7,1.6-4.3,2.4-7.2,2.4c-0.7,0-1.3,0-1.7-0.1v8h-5V1305.8z M996.8,1315.9c0.4,0.1,0.9,0.1,1.7,0.1c2.7,0,4.3-1.4,4.3-3.6
    c0-2-1.4-3.3-3.9-3.3c-1,0-1.7,0.1-2.1,0.2V1315.9z"
						/>
						<path
							fill="#353535"
							d="M1024.2,1318.4h-8.2v5.3h9.2v4.1H1011v-22.3h13.7v4.1h-8.7v4.6h8.2V1318.4z"
						/>
						<path fill="#353535" d="M1033.1,1309.8h-6v-4.2h17.1v4.2h-6.1v18h-5V1309.8z" />
						<path fill="#353535" d="M1051.9,1305.5v22.3h-5v-22.3H1051.9z" />
						<path fill="#353535" d="M1060.6,1309.8h-6v-4.2h17.1v4.2h-6.1v18h-5V1309.8z" />
						<g>
							<path fill="#353535" d="M909.8,1257.8l-4,4.7h-2.1l2.9-4.7H909.8z" />
						</g>
					</g>
					<g id="Value_00000142161885829787283130000002680084525626069938_"></g>
					<text
						transform="matrix(1 0 0 1 880 1305)"
						fontFamily="'Arial-BoldMT'"
						fill={'#3281D2'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.edouardMontpetit, dataKey)}
					</text>
				</g>
				<g id="Outremont">
					<ellipse
						id="Point_00000037671917134458618930000009553979017753161657_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -536.6213 1160.9155)"
						fill="#FFFFFF"
						cx="1133"
						cy="1228.2"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000092426178597919226860000010659436993934907530_">
						<path
							fill="#353535"
							d="M1188.2,1187.8c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1184.5,1176.5,1188.2,1181.7,1188.2,1187.8z M1172.1,1188.1c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1174.1,1180.5,1172.1,1183.8,1172.1,1188.1z"
						/>
						<path
							fill="#353535"
							d="M1196.5,1176.9v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H1196.5z"
						/>
						<path fill="#353535" d="M1218.4,1181.1h-6v-4.2h17.1v4.2h-6.1v18h-5V1181.1z" />
						<path
							fill="#353535"
							d="M1232.2,1177.2c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1177.2z M1237.2,1186.8h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1186.8z"
						/>
						<path
							fill="#353535"
							d="M1265,1189.7h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1189.7z"
						/>
						<path
							fill="#353535"
							d="M1288.2,1190.7c-0.1-2.7-0.2-5.9-0.2-9.2h-0.1c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7h-3.9l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4h-4.7l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L1288.2,1190.7z"
						/>
						<path
							fill="#353535"
							d="M1317.7,1187.8c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1314,1176.5,1317.7,1181.7,1317.7,1187.8z M1301.6,1188.1c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1303.6,1180.5,1301.6,1183.8,1301.6,1188.1z"
						/>
						<path
							fill="#353535"
							d="M1321,1199.2v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1321z"
						/>
						<path fill="#353535" d="M1348.1,1181.1h-6v-4.2h17.1v4.2h-6.1v18h-5V1181.1z" />
					</g>
					<g id="Value_00000120528229943034028770000002744799730106241179_"></g>
					<text
						transform="matrix(1 0 0 1 1166.376 1165)"
						fontFamily="'Arial-BoldMT'"
						fill={'#3281D2'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.outremont, dataKey)}
					</text>
				</g>
				<g id="Acadie">
					<circle
						id="Point_00000109002950756567198570000011397536518972443551_"
						fill="#FFFFFF"
						cx="1024.9"
						cy="1092.7"
						r="18.3"
					/>
					<g id="Name_00000131325747654335733030000005754274556050944431_">
						<path
							fill="#353535"
							d="M881.9,1131.9l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3H890l-1.7-5.7H881.9z M887.6,1128.2l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3H885c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H887.6z"
						/>
						<path
							fill="#353535"
							d="M913.9,1137c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L913.9,1137z"
						/>
						<path
							fill="#353535"
							d="M922.8,1131.9l-1.6,5.7H916l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H922.8z M928.4,1128.2l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H928.4z"
						/>
						<path
							fill="#353535"
							d="M939.3,1115.7c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V1115.7z M944.3,1133.8c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V1133.8z"
						/>
						<path fill="#353535" d="M967.3,1115.4v22.3h-5v-22.3H967.3z" />
						<path
							fill="#353535"
							d="M984.9,1128.2h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1128.2z"
						/>
					</g>
					<g id="Value_00000054226741431058558550000007789206096198735017_"></g>
					<text
						transform="matrix(1 0 0 1 860 1135)"
						fontFamily="'Arial-BoldMT'"
						fill={'#3281D2'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.acadie, dataKey)}
					</text>
				</g>
				<g id="Parc">
					<ellipse
						id="Point_00000122702020408589483360000008702720555483113865_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -385.0443 1027.8547)"
						fill="#FFFFFF"
						cx="1048.2"
						cy="978.7"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000116934348610762805490000003993976600352467078_">
						<path
							fill="#353535"
							d="M913.1,949.9c1.5-0.3,3.7-0.5,6.8-0.5c3.1,0,5.3,0.6,6.8,1.8c1.4,1.1,2.4,3,2.4,5.1c0,2.2-0.7,4-2,5.3
					c-1.7,1.6-4.3,2.4-7.2,2.4c-0.7,0-1.3,0-1.7-0.1v8h-5V949.9z M918.1,960c0.4,0.1,0.9,0.1,1.7,0.1c2.7,0,4.3-1.4,4.3-3.6
					c0-2-1.4-3.3-3.9-3.3c-1,0-1.7,0.1-2.1,0.2V960z"
						/>
						<path
							fill="#353535"
							d="M935.5,966.2l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H935.5z M941.1,962.4l-1.4-4.7
					c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H941.1z"
						/>
						<path
							fill="#353535"
							d="M952,949.9c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7c0,2.8-2,4.8-3.9,5.5
					v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2c-0.6-2.8-1.6-3.5-3.6-3.6H957v8.7h-5V949.9z
					 M957,959.5h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2V959.5z"
						/>
						<path
							fill="#353535"
							d="M987.8,971.3c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
					c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
					L987.8,971.3z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 900 970)"
						fontFamily="'Arial-BoldMT'"
						fill={'#3281D2'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.parc, dataKey)}
					</text>
				</g>
				<g id="De_castelnau">
					<ellipse
						id="Point_00000160156558196271716820000003965002114613015738_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -312.1358 1074.1313)"
						fill="#FFFFFF"
						cx="1140.5"
						cy="913.8"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000161589483483466011270000003192694998170389949_">
						<path
							fill="#353535"
							d="M849.5,883.1c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4c0,4.3-1.5,7.2-3.7,9
    c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V883.1z M854.5,901.3c0.4,0.1,1.1,0.1,1.8,0.1c4.6,0,7.6-2.5,7.6-7.8
    c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V901.3z"
						/>
						<path
							fill="#353535"
							d="M885.7,895.7h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V895.7z"
						/>
						<path
							fill="#353535"
							d="M913.1,904.5c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L913.1,904.5z"
						/>
						<path
							fill="#353535"
							d="M921.9,899.4l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3H930l-1.7-5.7H921.9z M927.6,895.6l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3H925c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H927.6z"
						/>
						<path
							fill="#353535"
							d="M938.6,899.9c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L938.6,899.9z"
						/>
						<path fill="#353535" d="M960.6,887.1h-6v-4.2h17.1v4.2h-6.1v18h-5V887.1z" />
						<path
							fill="#353535"
							d="M987.6,895.7h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V895.7z"
						/>
						<path fill="#353535" d="M992,882.8h5v18h8.9v4.2H992V882.8z" />
						<path
							fill="#353535"
							d="M1008.9,905.1v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1008.9z"
						/>
						<path
							fill="#353535"
							d="M1036.8,899.4l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1036.8z M1042.5,895.6l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1042.5z"
						/>
						<path
							fill="#353535"
							d="M1057.2,882.8v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H1057.2z"
						/>
					</g>
					<g id="Value_00000061447945646322787580000013881114776054749868_"></g>
					<text
						transform="matrix(1 0 0 1 830 902)"
						fontFamily="'Arial-BoldMT'"
						fill={'#3281D2'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.deCastelnau, dataKey)}
					</text>
				</g>
				<g id="Jean-Talon">
					<ellipse
						id="Point_00000124875649776184923600000008428640253599100551_"
						transform="matrix(0.9239 -0.3827 0.3827 0.9239 -228.8824 537.3048)"
						fill="#FFFFFF"
						stroke="#231F20"
						strokeWidth="5"
						strokeMiterlimit="10"
						cx="1236.2"
						cy="844"
						rx="32.9"
						ry="32.9"
					/>
					<g id="Name_00000046326086394406962900000004727176778663646346_">
						<path
							fill="#353535"
							d="M893.9,815.6h7.6v21.2c0,9.5-4.6,12.8-11.9,12.8c-1.8,0-4-0.3-5.5-0.8l0.8-6.1c1,0.3,2.4,0.6,3.9,0.6
    c3.2,0,5.2-1.4,5.2-6.6V815.6z"
						/>
						<path fill="#353535" d="M927.7,834.9h-12.3v8h13.8v6.2h-21.3v-33.5h20.6v6.2h-13v7h12.3V834.9z" />
						<path
							fill="#353535"
							d="M942.1,840.4l-2.4,8.6h-7.8l10.2-33.5h9.9l10.4,33.5h-8.2l-2.6-8.6H942.1z M950.6,834.8l-2.1-7.1
    c-0.6-2-1.2-4.5-1.7-6.4h-0.1c-0.5,2-1,4.5-1.5,6.5l-2,7.1H950.6z"
						/>
						<path
							fill="#353535"
							d="M966.9,849v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9V849h-7.9
    l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6V849H966.9z"
						/>
						<path fill="#353535" d="M1012.3,833.5v5h-13v-5H1012.3z" />
						<path fill="#353535" d="M1020.9,821.9h-9v-6.4h25.8v6.4h-9.2V849h-7.6V821.9z" />
						<path
							fill="#353535"
							d="M1045.8,840.4l-2.4,8.6h-7.8l10.2-33.5h9.9l10.4,33.5h-8.2l-2.6-8.6H1045.8z M1054.3,834.8l-2.1-7.1
    c-0.6-2-1.2-4.5-1.7-6.4h-0.1c-0.5,2-1,4.5-1.5,6.5l-2,7.1H1054.3z"
						/>
						<path fill="#353535" d="M1070.6,815.6h7.6v27.1h13.3v6.3h-20.9V815.6z" />
						<path
							fill="#353535"
							d="M1124.7,832c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C1119.1,815,1124.7,822.7,1124.7,832z M1100.5,832.4c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C1103.5,821,1100.5,825.9,1100.5,832.4z"
						/>
						<path
							fill="#353535"
							d="M1129.7,849v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9V849h-7.9
    l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6V849H1129.7z"
						/>
					</g>
					<g id="Value_00000099640291362941362260000000994173525287147432_">
						<g id="Arrow_00000016039264073301177730000018434092515010535854_"></g>
					</g>
					<text
						transform="matrix(1 0 0 1 875 840)"
						fontFamily="'Arial-BoldMT'"
						fill={'#3281D2'}
						fontSize="26px"
						textAnchor={'end'}
					>
						{Utils.getFormattedValueFromStation(data.jeanTalon, dataKey)}
					</text>
				</g>
				<g id="Fabre">
					<ellipse
						id="Point_00000125580728604394934370000018228727283590797982_"
						transform="matrix(0.1602 -0.9871 0.9871 0.1602 366.9521 1969.5746)"
						fill="#FFFFFF"
						cx="1341"
						cy="769.1"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000093864206487612952640000001159183696505418423_">
						<path fill="#353535" d="M1382.4,792.7h13.6v4.1h-8.6v5.1h8v4.1h-8v9h-5V792.7z" />
						<path
							fill="#353535"
							d="M1402.8,809.2l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1402.8z M1408.4,805.5l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1408.4z"
						/>
						<path
							fill="#353535"
							d="M1419.3,793c1.3-0.3,4-0.5,6.5-0.5c3.1,0,5,0.3,6.6,1.2c1.6,0.8,2.7,2.3,2.7,4.4c0,2-1.1,3.8-3.7,4.7
    v0.1c2.5,0.7,4.4,2.6,4.4,5.5c0,2-0.9,3.6-2.3,4.7c-1.6,1.3-4.3,2-8.8,2c-2.5,0-4.3-0.2-5.4-0.3V793z M1424.3,801.5h1.6
    c2.7,0,4.1-1.1,4.1-2.8c0-1.7-1.3-2.6-3.6-2.6c-1.1,0-1.7,0.1-2.1,0.2V801.5z M1424.3,811.4c0.5,0.1,1.1,0.1,1.9,0.1
    c2.3,0,4.3-0.9,4.3-3.2c0-2.2-2-3.1-4.5-3.1h-1.7V811.4z"
						/>
						<path
							fill="#353535"
							d="M1439.2,793c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7c0,2.8-2,4.8-3.9,5.5
    v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V793z
    M1444.2,802.6h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2V802.6z"
						/>
						<path
							fill="#353535"
							d="M1472.1,805.5h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V805.5z"
						/>
					</g>
					<g id="Value_00000002381754062557977540000018405719549148032186_">
						<g id="Arrow_00000041975199934203931580000006613618862665974936_">
							<path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M1262,796.3" />
							<path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M1497,796.3" />
						</g>
					</g>
					<text
						transform="matrix(1 0 0 1 1483.376 815.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#3281D2'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.fabre, dataKey)}
					</text>
				</g>
				<g id="D_x27_Iberville">
					<circle
						id="Point_00000129891586075816281340000010847358278455760294_"
						fill="#FFFFFF"
						cx="1425.3"
						cy="706.3"
						r="18.3"
					/>
					<g id="Name_00000120560502051815226080000005936037144967601597_">
						<path
							fill="#353535"
							d="M1463.9,728.9c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V728.9z M1468.9,747.1c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V747.1z"
						/>
						<path
							fill="#353535"
							d="M1492.8,728.3c-1,3.2-2.5,6.5-4,8.9l-3,0.3c1-2.8,1.9-6,2.3-8.9L1492.8,728.3z"
						/>
						<path fill="#353535" d="M1500.7,728.6v22.3h-5v-22.3H1500.7z" />
						<path
							fill="#353535"
							d="M1505.1,728.9c1.3-0.3,4-0.5,6.5-0.5c3.1,0,5,0.3,6.6,1.2c1.6,0.8,2.7,2.3,2.7,4.4c0,2-1.1,3.8-3.7,4.7
    v0.1c2.5,0.7,4.4,2.6,4.4,5.5c0,2-0.9,3.6-2.3,4.7c-1.6,1.3-4.3,2-8.8,2c-2.5,0-4.3-0.2-5.4-0.3V728.9z M1510.1,737.5h1.6
    c2.7,0,4.1-1.1,4.1-2.8c0-1.7-1.3-2.6-3.6-2.6c-1.1,0-1.7,0.1-2.1,0.2V737.5z M1510.1,747.3c0.5,0.1,1.1,0.1,1.9,0.1
    c2.3,0,4.3-0.9,4.3-3.2c0-2.2-2-3.1-4.5-3.1h-1.7V747.3z"
						/>
						<path
							fill="#353535"
							d="M1538.3,741.5h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V741.5z"
						/>
						<path
							fill="#353535"
							d="M1542.7,728.9c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V728.9z M1547.7,738.5h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V738.5z"
						/>
						<path
							fill="#353535"
							d="M1567.4,750.9l-7.1-22.3h5.5l2.7,9.4c0.8,2.6,1.5,5.2,2,7.9h0.1c0.6-2.7,1.3-5.3,2-7.9l2.8-9.5h5.4
    l-7.5,22.3H1567.4z"
						/>
						<path fill="#353535" d="M1588.2,728.6v22.3h-5v-22.3H1588.2z" />
						<path fill="#353535" d="M1592.5,728.6h5v18h8.9v4.2h-13.9V728.6z" />
						<path fill="#353535" d="M1609.4,728.6h5v18h8.9v4.2h-13.9V728.6z" />
						<path
							fill="#353535"
							d="M1639.5,741.5h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V741.5z"
						/>
					</g>
					<g id="Value_00000061458830246061166870000005294883594054014393_"></g>
					<text
						transform="matrix(1 0 0 1 1649.376 751.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#3281D2'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.dIberville, dataKey)}
					</text>
				</g>
				<g id="Saint-Michel">
					<ellipse
						id="Point_00000174594525058164297920000015323169214969114022_"
						transform="matrix(0.9239 -0.3827 0.3827 0.9239 -127.9834 630.3881)"
						fill="#FFFFFF"
						stroke="#231F20"
						strokeWidth="5"
						strokeMiterlimit="10"
						cx="1520.6"
						cy="636.9"
						rx="32.9"
						ry="32.9"
					/>
					<g id="Name_00000181788088548697976090000001248668208952403896_">
						<rect x="1563.8" y="577.2" fill="#3281D2" width="353.5" height="70.7" />
						<g>
							<path
								fill="#FFFFFF"
								d="M1583.8,621.5c2,1.1,5.2,2.1,8.4,2.1c3.5,0,5.3-1.4,5.3-3.6c0-2.1-1.6-3.3-5.6-4.7
    c-5.6-2-9.2-5-9.2-9.9c0-5.7,4.8-10,12.7-10c3.8,0,6.6,0.8,8.5,1.7l-1.7,6.1c-1.3-0.6-3.7-1.6-7-1.6c-3.3,0-4.9,1.5-4.9,3.2
    c0,2.1,1.9,3.1,6.2,4.7c5.9,2.2,8.7,5.3,8.7,10c0,5.6-4.3,10.4-13.5,10.4c-3.8,0-7.6-1-9.5-2.1L1583.8,621.5z"
							/>
							<path
								fill="#FFFFFF"
								d="M1618.1,620.7l-2.4,8.6h-7.8l10.2-33.5h9.9l10.4,33.5h-8.2l-2.6-8.6H1618.1z M1626.6,615.1l-2.1-7.1
    c-0.6-2-1.2-4.5-1.7-6.4h-0.1c-0.5,2-1,4.5-1.5,6.5l-2,7.1H1626.6z"
							/>
							<path fill="#FFFFFF" d="M1650.5,595.8v33.5h-7.6v-33.5H1650.5z" />
							<path
								fill="#FFFFFF"
								d="M1657.1,629.3v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9v33.5
    h-7.9l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6v10.1H1657.1z"
							/>
							<path fill="#FFFFFF" d="M1697.8,602.2h-9v-6.4h25.8v6.4h-9.2v27.1h-7.6V602.2z" />
							<path fill="#FFFFFF" d="M1726.7,613.7v5h-13v-5H1726.7z" />
							<path
								fill="#FFFFFF"
								d="M1759.8,616.5c-0.2-4.1-0.3-8.9-0.3-13.8h-0.1c-1.1,4.3-2.5,9-3.7,13l-4.1,13h-5.9l-3.6-12.9
    c-1.1-3.9-2.2-8.7-3-13.1h-0.1c-0.2,4.6-0.4,9.7-0.6,13.9l-0.6,12.7h-7l2.1-33.5h10.1l3.3,11.2c1.1,3.8,2.1,8,2.8,12h0.2
    c0.9-3.9,2-8.3,3.2-12l3.6-11.1h9.9l1.8,33.5h-7.4L1759.8,616.5z"
							/>
							<path fill="#FFFFFF" d="M1781.1,595.8v33.5h-7.6v-33.5H1781.1z" />
							<path
								fill="#FFFFFF"
								d="M1812.2,628.4c-1.4,0.7-4.5,1.4-8.6,1.4c-11.6,0-17.6-7.2-17.6-16.8c0-11.4,8.2-17.7,18.3-17.7
    c3.9,0,6.9,0.8,8.2,1.5l-1.5,6c-1.5-0.7-3.7-1.3-6.4-1.3c-6,0-10.6,3.6-10.6,11.1c0,6.7,4,10.9,10.7,10.9
    c2.3,0,4.8-0.5,6.3-1.1L1812.2,628.4z"
							/>
							<path
								fill="#FFFFFF"
								d="M1824.8,595.8v12.9h12.5v-12.9h7.6v33.5h-7.6v-14h-12.5v14h-7.6v-33.5H1824.8z"
							/>
							<path
								fill="#FFFFFF"
								d="M1871.3,615.1H1859v8h13.8v6.2h-21.3v-33.5h20.6v6.2h-13v7h12.3V615.1z"
							/>
							<path fill="#FFFFFF" d="M1878,595.8h7.6v27.1h13.3v6.3H1878V595.8z" />
						</g>
					</g>
					<g id="Value_00000168813003801629985010000013109398123547248794_"></g>
					<text
						transform="matrix(1 0 0 1 1563.376 565)"
						fontFamily="'Arial-BoldMT'"
						fill={'#3281D2'}
						fontSize="26px"
						textAnchor={'start'}
					>
						{Utils.getFormattedValueFromStation(data.saintMichel, dataKey)}
					</text>
				</g>
			</g>
		</g>
	)
}
