/*
| Developed by Dirupt
| Filename : MapFilters.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import { useTranslation } from 'react-i18next'
import { MetroContracts } from '../../contracts/api/Metro.contracts'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MapFiltersProps {
	period: string
	year: string
	dataKey: keyof MetroContracts
	yearsAndPeriods: Record<string, string[]>
	handleOnYearChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
	handleOnPeriodChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
	handleOneDataKeyChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MapFilters: React.FC<MapFiltersProps> = (props) => {
	const { t } = useTranslation()

	return (
		<div className="filters_selects">
			<select onChange={props.handleOnYearChange} value={props.year}>
				{props.yearsAndPeriods
					? Object.keys(props.yearsAndPeriods).map((item, index) => (
							<option key={index} value={item}>
								{item}
							</option>
						))
					: null}
			</select>
			<select onChange={props.handleOnPeriodChange} value={props.period}>
				{props.yearsAndPeriods && props.year
					? props.yearsAndPeriods[props.year]
							.sort((a, b) => {
								if (a === 'Annual') {
									return 1 // Move "Annual" to the end
								} else if (b === 'Annual') {
									return -1 // Move "Annual" to the end
								} else {
									return a.localeCompare(b) // Sort alphabetically
								}
							})
							.map((item, index) => (
								<option key={index} value={item}>
									{item === 'Annual' ? t('mapFilters.period.annual') : item}
								</option>
							))
					: null}
			</select>
			<select onChange={props.handleOneDataKeyChange} value={props.dataKey}>
				<option value={'maximumPrice'}>{t('mapFilters.dataKey.maximumPrice')}</option>
				<option value={'minimumPrice'}>{t('mapFilters.dataKey.minimumPrice')}</option>
				<option value={'medianPrice'}>{t('mapFilters.dataKey.medianPrice')}</option>
				<option value={'valuePerSqFt'}>{t('mapFilters.dataKey.valuePerSqFt')}</option>
				<option value={'variation'}>{t('mapFilters.dataKey.variation')}</option>
			</select>
		</div>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
MapFilters.displayName = 'MapFilters'
