/*
| Developed by Dirupt
| Filename : config.ts
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import commonEN from './locales/en/translation.json'
import commonFR from './locales/fr/translation.json'

// eslint-disable-next-line import/no-named-as-default-member
void i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		lng: 'en',
		// debug: true,
		defaultNS: 'translation',
		returnNull: false,
		resources: {
			en: {
				translation: commonEN,
			},
			fr: {
				translation: commonFR,
			},
		},
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	})

export default i18n
