/*
| Developed by Dirupt
| Filename : config.ts
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { MetroContracts } from '../contracts/api/Metro.contracts'

/**
 * Utils class
 */
export class Utils {
	/**
	 * Get formatted value from station
	 * @param data
	 * @param dataKey
	 */
	static getFormattedValueFromStation(data: MetroContracts, dataKey: keyof MetroContracts) {
		const currentData = data?.[dataKey]

		if (!currentData) {
			return 'N/A'
		}

		if (currentData === 'N/A' || currentData === 'S.O') {
			return currentData
		}

		if (dataKey === 'variation') {
			return `${currentData}%`
		}

		if (dataKey === 'valuePerSqFt') {
			return `${currentData}$/pi²`
		}

		return currentData
	}

	/**
	 * Get map title
	 * @param dataKey
	 * @param category
	 * @param quarter
	 * @param year
	 */
	static getMapTitle({
		dataKey,
		category,
		quarter,
		year,
	}: {
		dataKey: string
		category?: string
		quarter: string
		year: string
	}) {
		if (!category) {
			return `${dataKey} - ${quarter} - ${year}`
		}

		return `${dataKey} - ${category} - ${quarter} - ${year}`
	}
}
