/*
| Developed by Dirupt
| Filename: Watermark.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface WatermarkProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const Watermark: React.FC<WatermarkProps> = () => {
	// Render
	//--------------------------------------------------------------------------
	return (
		<svg
			version="1.0"
			xmlns="http://www.w3.org/2000/svg"
			width="80%"
			height="80%"
			style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', opacity: 0.1 }}
			// width="4057.000000pt"
			// height="4188.000000pt"
			viewBox="0 0 4057.000000 4188.000000"
			preserveAspectRatio="xMidYMid meet"
		>
			<g transform="translate(0.000000,4188.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
				<path
					d="M22539 41871 c-156 -17 -335 -69 -474 -138 -171 -87 -358 -243 -472
		-398 -107 -144 -202 -365 -234 -545 -17 -96 -17 -313 0 -410 111 -629 665
	-1100 1294 -1100 241 0 460 59 663 178 80 46 78 42 90 185 l7 78 -74 -56
	c-195 -146 -453 -229 -714 -228 -157 1 -274 28 -419 96 -430 204 -696 601
	-696 1042 0 433 256 831 664 1031 257 126 552 149 808 64 220 -74 440 -226
	553 -384 22 -30 43 -55 46 -56 3 0 12 48 18 107 l12 106 -63 65 c-173 177
	-437 314 -682 351 -95 15 -243 20 -327 12z"
				/>
				<path
					d="M24395 40545 c-188 -41 -345 -135 -462 -275 -63 -76 -143 -234 -169
		-335 -26 -101 -24 -284 5 -390 62 -230 217 -419 426 -520 120 -58 187 -75 326
	-82 295 -14 550 113 710 355 82 125 139 311 139 457 0 207 -101 440 -249 578
	-197 182 -484 266 -726 212z m318 -151 c235 -60 419 -241 478 -472 71 -280
	-55 -600 -294 -741 -109 -64 -179 -84 -317 -89 -147 -5 -213 8 -330 65 -100
	48 -220 167 -273 270 -73 141 -97 323 -62 473 44 184 188 367 355 449 142 70
	289 85 443 45z"
				/>
				<path
					d="M17890 40204 c-3237 -378 -6245 -1482 -8950 -3287 -1444 -964 -2782
		-2136 -3924 -3437 -2785 -3173 -4507 -7146 -4921 -11360 -70 -713 -90 -1155
		-90 -1975 1 -796 17 -1174 80 -1855 344 -3690 1715 -7236 3952 -10223 2218
	-2961 5188 -5235 8643 -6617 1929 -772 4041 -1255 6100 -1395 483 -33 595 -36
	1195 -42 778 -7 1252 10 1925 67 3371 290 6626 1435 9470 3333 1599 1068 3027
	2349 4259 3822 3022 3616 4690 8216 4691 12945 0 374 -11 789 -30 1120 -14
	246 -52 734 -57 740 -4 4 -213 -35 -217 -42 -2 -2 3 -68 10 -148 44 -516 64
	-1018 64 -1650 0 -1208 -87 -2236 -290 -3417 -419 -2438 -1311 -4829 -2595
	-6953 -1623 -2686 -3833 -4925 -6495 -6582 -3906 -2431 -8466 -3432 -13020
	-2857 -2892 365 -5657 1349 -8130 2894 -1768 1105 -3358 2489 -4696 4090
	-1594 1907 -2837 4134 -3624 6493 -821 2465 -1150 5084 -965 7687 251 3508
	1416 6885 3382 9798 1439 2134 3276 3961 5423 5394 734 489 1366 855 2190
	1267 686 343 1246 586 1953 850 1472 550 3102 940 4642 1111 127 14 249 28
	273 31 l42 5 0 110 0 109 -42 -1 c-24 0 -135 -12 -248 -25z"
				/>
				<path
					d="M25911 40177 c-4 -18 -77 -247 -160 -509 -154 -484 -181 -580 -181
		-655 -1 -178 118 -352 301 -442 111 -54 194 -75 304 -75 207 0 363 92 447 265
	40 82 362 1084 355 1104 -6 15 -127 49 -137 38 -4 -4 -72 -210 -150 -458 -79
	-247 -157 -484 -175 -525 -83 -198 -181 -280 -334 -280 -178 0 -348 86 -407
	206 -36 73 -43 179 -20 292 9 45 75 267 147 494 72 227 139 439 149 470 l18
	57 -74 25 -73 24 -10 -31z"
				/>
				<path
					d="M28621 39768 c-10 -24 -58 -133 -106 -243 -47 -110 -90 -204 -95
		-208 -4 -5 -39 5 -78 22 -39 17 -73 31 -75 31 -10 0 -58 -114 -53 -125 2 -6
	35 -24 72 -40 38 -15 70 -29 72 -31 2 -1 -121 -287 -274 -635 -152 -349 -277
	-640 -278 -648 -1 -15 125 -74 135 -63 4 4 131 292 283 641 152 349 277 636
	279 638 2 1 54 -20 116 -47 62 -28 119 -50 126 -50 7 0 22 24 35 53 12 28 24
	57 26 62 3 6 -52 35 -120 64 l-125 54 13 31 c8 17 55 126 106 241 50 116 94
	216 97 222 3 8 -20 24 -62 43 -37 16 -69 30 -71 30 -3 0 -13 -19 -23 -42z"
				/>
				<path
					d="M27497 39663 c-7 -14 -546 -1407 -549 -1422 -3 -13 98 -61 128 -61 6
	0 86 195 178 433 185 477 233 584 300 674 65 86 131 127 216 131 83 5 141 -11
	185 -50 l35 -30 38 26 c55 39 82 63 82 73 0 18 -106 84 -159 100 -118 34 -236
	10 -335 -69 -37 -30 -38 -30 -31 -7 4 13 18 51 31 83 13 33 24 63 24 68 0 18
	-134 66 -143 51z"
				/>
				<path
					d="M29350 39534 c-95 -60 -90 -191 10 -242 120 -61 242 61 184 184 -31
	66 -133 96 -194 58z"
				/>
				<path
					d="M28795 38298 c-180 -376 -330 -691 -333 -700 -3 -14 11 -25 62 -49
	37 -17 68 -30 70 -28 8 9 666 1385 666 1391 0 8 -113 68 -128 68 -5 0 -156
	-307 -337 -682z"
				/>
				<path
					d="M29865 38456 c-322 -74 -578 -357 -646 -714 -16 -86 -6 -279 19 -358
	98 -308 401 -527 731 -526 141 0 283 39 388 105 l42 27 -36 60 c-19 33 -38 60
	-42 60 -4 0 -39 -16 -78 -35 -98 -48 -182 -68 -288 -68 -154 0 -292 56 -413
	168 -75 70 -122 142 -160 243 -33 88 -37 254 -9 347 25 81 68 175 80 175 5 0
	269 -145 585 -322 317 -178 578 -324 582 -326 4 -1 23 32 42 75 66 142 88 240
	88 382 0 319 -195 579 -515 687 -98 33 -273 42 -370 20z m294 -151 c202 -48
	373 -216 426 -421 20 -78 19 -250 -3 -329 -10 -33 -19 -60 -22 -60 -3 0 -238
	130 -522 289 l-517 290 32 39 c65 83 198 163 316 192 76 18 212 18 290 0z"
				/>
				<path
					d="M30949 37104 c-228 -350 -417 -642 -421 -649 -11 -19 113 -99 128
		-82 7 6 105 156 219 332 307 473 357 544 433 618 40 38 93 78 124 92 126 59
	274 14 344 -103 1 -2 34 13 73 33 l72 36 -20 28 c-33 46 -91 92 -151 120 -46
	22 -69 26 -145 26 -81 0 -97 -3 -153 -31 l-61 -30 21 35 c12 20 34 55 50 78
	15 23 28 46 28 51 0 8 -110 82 -122 82 -3 0 -191 -286 -419 -636z"
				/>
				<path
					d="M32885 36325 c-429 -541 -780 -990 -780 -996 0 -6 26 -32 58 -57 57
		-45 58 -45 76 -26 38 40 1551 1955 1551 1963 0 6 -115 101 -123 101 -1 0 -353
	-443 -782 -985z"
				/>
				<path
					d="M33137 35504 l-527 -566 51 -47 c29 -25 55 -47 59 -49 5 -2 155 154
	334 346 179 192 356 372 392 401 37 28 101 68 143 89 70 34 81 37 165 37 107
	0 155 -19 235 -91 98 -88 132 -201 96 -322 -32 -106 -88 -173 -714 -840 l-115
	-124 51 -49 c28 -27 56 -49 61 -49 5 0 142 143 305 318 339 365 419 444 502
	494 175 106 342 89 479 -48 74 -74 101 -131 101 -214 0 -53 -6 -77 -32 -130
	-42 -84 -58 -104 -427 -500 -171 -184 -330 -355 -353 -380 l-41 -45 57 -52 57
	-51 350 375 c403 434 440 478 497 592 41 82 42 87 42 185 0 95 -2 106 -34 171
	-66 134 -224 263 -361 294 -64 15 -156 13 -220 -3 -30 -8 -57 -13 -58 -11 -2
	2 1 27 7 55 32 149 -9 286 -122 406 -33 36 -80 77 -104 91 -94 55 -227 78
	-312 53 -19 -5 -37 -8 -39 -5 -2 2 24 33 58 69 l63 66 -53 47 c-29 27 -56 49
	-60 51 -3 1 -244 -252 -533 -564z"
				/>
				<path
					d="M34930 33824 c-316 -267 -583 -491 -594 -499 -18 -13 -16 -17 29 -69
	26 -31 51 -56 56 -56 4 0 151 120 326 267 461 388 522 430 674 469 168 43 318
	-30 405 -197 41 -77 41 -171 0 -253 -45 -91 -129 -171 -547 -522 -214 -180
	-389 -329 -389 -333 1 -3 23 -30 49 -60 l49 -54 343 288 c408 343 448 374 542
	419 183 87 326 67 449 -62 79 -83 112 -150 112 -232 0 -79 -28 -139 -101 -220
	-26 -29 -232 -208 -458 -398 -225 -189 -411 -347 -413 -351 -1 -4 18 -34 44
	-65 47 -56 47 -57 68 -38 12 10 202 171 424 357 465 389 523 450 573 597 42
	126 10 260 -96 398 -113 147 -284 222 -453 199 -31 -4 -58 -7 -59 -5 -2 1 6
	29 17 62 36 103 27 241 -22 339 -86 170 -245 270 -418 263 l-80 -3 47 40 c26
	22 60 51 75 65 l28 25 -53 57 -52 56 -575 -484z"
				/>
				<path
					d="M36750 32164 c-120 -21 -155 -30 -222 -55 -395 -152 -610 -607 -474
		-1005 47 -139 113 -241 224 -346 88 -83 180 -138 302 -180 89 -30 103 -32 235
	-33 116 0 153 4 218 23 152 44 274 119 387 239 89 94 132 164 171 275 72 210
	62 405 -32 606 -109 235 -293 390 -536 452 -73 19 -226 32 -273 24z m235 -166
	c119 -26 251 -101 326 -186 58 -66 114 -164 143 -253 117 -350 -87 -728 -454
	-840 -95 -30 -252 -32 -350 -5 -163 44 -320 165 -407 314 -106 180 -110 416
	-13 610 141 280 448 426 755 360z"
				/>
				<path
					d="M38005 30643 c-666 -360 -1212 -657 -1215 -660 -6 -6 63 -133 72
		-133 4 0 56 27 116 60 60 33 112 60 115 60 3 0 -5 -24 -18 -52 -77 -170 -68
	-393 25 -584 92 -190 239 -322 435 -391 74 -26 91 -27 235 -27 143 0 162 2
	240 28 310 102 518 297 602 566 32 105 32 271 0 384 -40 141 -104 249 -207
	352 -76 77 -104 98 -185 137 -54 26 -125 52 -164 60 -39 7 -66 16 -60 19 5 3
	295 160 644 348 349 189 639 346 643 350 8 7 -45 124 -61 133 -4 3 -552 -290
	-1217 -650z m39 -339 c95 -24 174 -71 251 -149 76 -76 122 -147 158 -241 19
	-50 22 -78 22 -189 -1 -116 -4 -137 -27 -196 -57 -144 -172 -281 -302 -358
	-179 -107 -417 -134 -596 -69 -226 82 -380 305 -380 551 0 151 43 266 143 388
	108 130 306 245 467 272 64 11 207 6 264 -9z"
				/>
				<path
					d="M39525 29219 c-53 -15 -88 -61 -92 -121 -7 -96 54 -155 151 -145 41
	4 55 11 81 39 43 47 52 95 26 153 -16 36 -29 50 -63 65 -47 21 -58 22 -103 9z"
				/>
				<path
					d="M38370 28625 c-377 -172 -691 -318 -699 -323 -12 -7 -10 -19 14 -75
	16 -37 33 -67 36 -67 8 0 1400 637 1406 644 5 5 -58 136 -65 136 -4 0 -315
	-142 -692 -315z"
				/>
				<path
					d="M39215 28185 c-1054 -442 -1269 -536 -1267 -550 8 -43 53 -127 64
		-122 67 26 2534 1060 2537 1063 6 5 -51 144 -58 143 -3 -1 -577 -241 -1276
	-534z"
				/>
				<path
					d="M40103 27771 c-96 -44 -102 -185 -10 -241 91 -56 207 10 207 118 0
	57 -25 98 -75 122 -51 25 -69 25 -122 1z"
				/>
				<path
					d="M38923 27268 l-721 -270 18 -51 c31 -88 31 -89 54 -84 32 6 1420 527
	1427 535 7 8 -40 142 -50 141 -3 0 -331 -122 -728 -271z"
				/>
				<path
					d="M39236 26744 c-509 -110 -790 -542 -644 -994 41 -129 96 -218 193
		-316 78 -78 104 -98 185 -137 97 -47 210 -82 221 -69 4 4 13 37 22 73 l14 66
	-54 13 c-160 35 -334 173 -407 320 -39 81 -66 198 -66 288 1 223 131 423 340
	525 52 26 103 48 114 49 17 3 41 -71 214 -637 107 -352 196 -641 197 -643 5
	-6 143 51 202 84 142 79 270 209 332 336 53 107 74 215 68 337 -6 131 -24 199
	-83 319 -77 158 -219 287 -383 349 -140 53 -323 67 -465 37z m328 -144 c115
	-29 189 -73 281 -165 131 -131 181 -257 173 -439 -7 -161 -60 -277 -179 -396
	-58 -59 -171 -132 -182 -120 -2 3 -79 252 -171 553 -92 301 -169 554 -172 562
	-7 17 15 22 102 24 42 1 103 -7 148 -19z"
				/>
				<path
					d="M7765 25570 c-1558 -88 -2713 -672 -3579 -1809 -533 -699 -862 -1516
		-975 -2421 -86 -684 -54 -1377 90 -1962 244 -991 835 -1756 1674 -2168 581
	-285 1231 -412 2040 -397 390 7 690 40 1102 123 838 167 1459 431 2043 867
	158 118 368 324 453 443 166 235 249 480 251 739 1 100 -3 127 -19 160 -40 81
	-80 56 -237 -153 -290 -387 -635 -738 -983 -999 -669 -502 -1439 -783 -2365
	-864 -187 -17 -681 -17 -830 -1 -384 42 -685 125 -985 271 -358 175 -644 413
	-894 744 -429 568 -669 1230 -732 2020 -15 194 -6 773 15 957 100 879 367
	1599 852 2301 595 861 1356 1424 2260 1673 545 149 1217 194 1667 111 404 -75
	801 -259 1013 -470 124 -124 188 -236 220 -385 31 -145 14 -225 -93 -455 -111
	-237 -153 -364 -153 -466 0 -156 101 -249 269 -249 188 1 341 110 436 312 76
	160 108 395 87 641 -7 78 -19 176 -28 217 -150 714 -720 1106 -1753 1205 -190
	19 -642 27 -846 15z"
				/>
				<path
					d="M14478 25230 c-213 -34 -367 -116 -478 -254 -159 -200 -274 -440
		-436 -904 -112 -323 -118 -346 -103 -440 7 -42 11 -98 11 -124 -3 -70 -60
	-279 -160 -588 -109 -333 -244 -763 -472 -1500 -535 -1732 -914 -2867 -1238
	-3710 -209 -545 -240 -676 -173 -744 48 -47 226 -25 341 42 82 49 188 160 272
	287 91 138 238 425 323 635 204 497 686 1311 1186 2000 401 553 1006 1271
	1151 1364 72 47 62 -42 -30 -274 -38 -96 -158 -380 -265 -630 -278 -650 -497
	-1206 -717 -1820 -213 -596 -326 -1003 -376 -1355 -25 -173 -23 -415 4 -517
	40 -150 101 -230 202 -263 113 -38 307 -2 465 85 151 83 310 221 348 303 30
	63 18 155 -23 177 -18 9 -54 -6 -254 -110 -204 -105 -237 -119 -259 -111 -49
	19 -62 54 -66 179 -15 470 201 1235 613 2174 370 846 763 1609 1211 2353 73
	121 141 243 151 270 27 72 26 184 -2 222 -71 97 -277 116 -491 47 -621 -201
	-1473 -1127 -2537 -2758 -75 -114 -136 -205 -136 -201 0 5 38 143 85 309 545
	1916 1270 3828 2088 5499 l121 249 -19 36 c-30 55 -80 76 -190 78 -49 2 -116
	-1 -147 -6z"
				/>
				<path
					d="M26085 25226 c-309 -49 -487 -203 -666 -581 -95 -202 -301 -766 -324
		-889 -5 -22 1 -72 15 -136 l21 -101 -20 -107 c-25 -125 -25 -128 -301 -1037
	-116 -385 -292 -965 -390 -1290 -427 -1412 -736 -2371 -1018 -3165 -185 -520
	-222 -645 -222 -755 1 -198 184 -276 445 -190 348 115 1145 630 1320 853 76
	96 69 139 -24 162 -42 10 -56 8 -111 -10 -75 -25 -177 -76 -366 -181 -345
	-192 -437 -221 -505 -161 -31 28 -34 35 -37 105 -4 83 12 163 139 672 483
	1933 1470 4697 2323 6507 92 195 97 208 84 234 -38 78 -158 101 -363 70z"
				/>
				<path
					d="M39640 24915 c-404 -95 -735 -174 -736 -174 -1 -1 7 -34 16 -73 15
		-61 20 -72 36 -70 11 2 213 48 449 104 536 125 554 128 678 128 120 0 159 -9
	223 -53 70 -47 108 -121 110 -211 l1 -69 71 -22 c60 -19 72 -20 76 -8 12 31 5
	124 -14 184 -35 116 -104 184 -228 225 l-65 22 39 11 c21 6 65 16 97 22 52 11
	58 14 53 33 -3 12 -10 45 -16 74 -10 44 -15 52 -33 51 -12 0 -353 -79 -757
	-174z"
				/>
				<path
					d="M21230 22115 c-163 -34 -264 -87 -375 -199 -132 -134 -229 -325 -350
		-691 -73 -218 -175 -588 -175 -633 0 -17 12 -79 26 -139 34 -141 31 -184 -41
	-483 -31 -129 -85 -361 -121 -515 -78 -334 -121 -507 -260 -1050 -96 -371
	-108 -412 -132 -430 -148 -112 -243 -390 -274 -806 l-11 -159 23 -25 c84 -89
	266 -41 498 133 115 86 126 104 202 322 323 935 815 1902 1442 2837 444 663
	807 1038 1073 1109 112 30 145 10 145 -89 0 -79 -31 -186 -120 -418 -148 -385
	-153 -439 -38 -439 129 0 427 240 579 468 177 264 259 502 259 749 0 253 -75
	373 -249 403 -127 21 -325 -31 -490 -129 -342 -203 -681 -554 -1163 -1201
	-489 -658 -851 -1210 -1133 -1725 -42 -77 -73 -126 -69 -110 85 387 270 997
	435 1441 81 218 361 917 489 1219 145 342 190 456 190 474 0 31 -45 76 -88 89
	-51 15 -189 14 -272 -3z"
				/>
				<path
					d="M29479 22065 c-210 -57 -590 -265 -714 -389 -52 -52 -86 -106 -99
		-158 -5 -21 0 -33 24 -57 17 -16 40 -31 53 -33 12 -2 69 9 125 24 123 32 147
	28 158 -26 31 -168 -201 -665 -636 -1366 -285 -457 -656 -999 -877 -1277 -345
	-435 -590 -607 -662 -465 -29 58 -32 112 -23 482 9 348 2 732 -18 980 -55 695
	-205 1378 -380 1735 -85 173 -163 259 -258 285 -38 10 -49 9 -87 -10 -59 -28
	-132 -109 -255 -282 -140 -196 -161 -220 -243 -285 -195 -153 -273 -265 -221
	-317 19 -19 21 -19 156 -16 124 2 177 -11 250 -65 135 -99 262 -351 353 -699
	47 -178 109 -494 139 -706 115 -802 89 -1859 -56 -2329 -24 -78 -111 -209
	-528 -791 -536 -749 -904 -1314 -1278 -1965 -483 -840 -648 -1459 -422 -1584
	84 -47 244 -25 377 51 87 49 159 113 183 164 30 60 23 117 -31 266 -37 102
	-42 124 -46 228 -4 99 -1 128 20 210 107 412 493 1096 1142 2020 285 406 578
	800 594 800 9 0 63 -13 120 -30 96 -27 107 -28 128 -14 77 50 118 163 273 769
	34 132 64 244 67 249 3 4 9 3 12 -3 15 -24 598 704 1122 1404 920 1226 1375
	1898 1630 2406 183 364 248 615 196 750 -16 41 -22 47 -55 53 -60 10 -183 5
	-233 -9z"
				/>
				<path
					d="M32250 21985 c-103 -20 -170 -42 -400 -127 -380 -140 -453 -172 -763
		-335 -216 -112 -468 -273 -687 -436 -214 -161 -587 -539 -716 -727 -54 -80
		-89 -178 -103 -289 -29 -226 60 -404 335 -671 193 -188 343 -303 599 -460 429
	-264 716 -492 834 -663 118 -172 109 -310 -27 -447 -139 -138 -377 -256 -802
	-395 -388 -127 -716 -199 -965 -214 -84 -5 -152 -13 -170 -22 -75 -36 -91
	-152 -36 -263 59 -120 124 -148 311 -135 410 28 941 234 1475 571 445 282 716
	593 781 896 18 88 18 233 -1 307 -36 141 -125 270 -254 369 -90 69 -375 247
	-629 393 -293 168 -362 215 -479 327 -222 211 -330 433 -308 634 19 179 109
	341 278 497 213 198 748 503 1023 583 96 29 438 82 521 82 160 -1 235 -92 204
	-251 -17 -82 -47 -174 -135 -401 -106 -277 -138 -382 -140 -456 -1 -56 2 -66
	23 -83 64 -52 225 69 391 294 246 332 442 726 456 915 11 145 -44 321 -128
	405 -102 102 -291 142 -488 102z"
				/>
				<path
					d="M17865 21973 c-120 -13 -200 -45 -275 -110 -117 -101 -151 -119 -225
		-126 -139 -11 -272 -85 -432 -240 -376 -363 -875 -1233 -1215 -2117 -336 -874
		-505 -1594 -468 -1989 33 -349 183 -470 476 -386 490 143 1221 873 2198 2198
	60 81 111 145 113 143 2 -1 -28 -115 -66 -252 -202 -721 -313 -1239 -340
	-1584 -10 -125 -9 -141 10 -210 49 -182 178 -276 347 -255 101 13 370 121 506
	204 121 74 371 258 485 357 63 55 163 141 224 192 171 144 362 332 386 380 39
	76 41 237 4 230 -9 -2 -63 -55 -121 -118 -481 -529 -785 -760 -1001 -760 -200
	0 -224 197 -101 810 66 327 177 793 280 1172 139 512 356 1181 393 1215 12 11
	59 39 103 62 45 23 90 52 102 64 19 21 177 370 220 489 47 126 65 220 66 333
	1 104 -1 113 -29 164 -49 89 -136 131 -275 133 -99 1 -142 -21 -242 -125 l-76
	-80 -54 6 c-29 3 -141 31 -248 62 -265 76 -369 102 -480 119 -107 16 -220 24
	-265 19z m450 -428 c404 -33 386 -30 403 -80 29 -85 -6 -224 -133 -529 -208
	-499 -482 -1035 -698 -1364 -297 -453 -1067 -1364 -1421 -1682 -320 -287 -525
	-367 -593 -232 -28 56 -30 241 -4 413 65 447 272 1006 658 1779 455 914 814
	1471 1045 1624 69 46 162 68 408 95 8 1 159 -10 335 -24z"
				/>
				<path
					d="M35060 21853 c-186 -11 -433 -65 -587 -128 -235 -97 -430 -255 -558
		-455 -23 -36 -180 -338 -349 -671 l-306 -606 -88 -6 c-113 -9 -155 -21 -191
	-57 -83 -83 -29 -226 105 -281 49 -21 53 -25 47 -48 -16 -73 -122 -428 -198
	-666 -94 -298 -173 -601 -207 -800 -19 -109 -22 -167 -22 -360 0 -206 2 -238
	22 -310 31 -112 93 -234 155 -301 65 -72 203 -163 304 -202 145 -55 205 -66
	368 -67 132 0 158 3 220 24 231 78 531 282 890 603 461 412 706 748 722 988 5
	70 4 78 -18 103 -13 14 -36 29 -51 33 -24 5 -36 -2 -94 -57 -36 -35 -139 -154
	-228 -264 -448 -557 -765 -858 -1042 -990 -107 -51 -184 -68 -284 -62 -177 9
	-276 101 -332 305 -28 104 -31 383 -5 552 52 332 178 776 350 1225 l54 140
	384 0 c418 0 475 5 769 65 471 96 653 174 835 355 109 109 147 169 187 295 20
	65 23 94 22 225 -1 158 -10 248 -49 486 -66 397 -174 662 -331 809 -126 117
	-215 140 -494 123z m27 -464 c117 -57 219 -261 267 -539 24 -131 33 -438 16
	-546 -15 -105 -62 -207 -124 -268 -153 -154 -514 -213 -1100 -182 -153 8 -280
	17 -282 19 -12 12 190 426 312 638 308 535 529 804 723 880 63 25 135 24 188
	-2z"
				/>
			</g>
		</svg>
	)
}
