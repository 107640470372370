import React from 'react'
import { MetroContracts, MetroLineCommonStations } from '../../../contracts/api/Metro.contracts'
import { Utils } from '../../../utils'
import { MetroGreenLineType } from './MetroGreenLineContracts'

// Contract
//--------------------------------------------------------------------------
export interface MetroGreenLineProps {
	data: MetroGreenLineType & MetroLineCommonStations
	dataKey: keyof MetroContracts
}

// Component
//--------------------------------------------------------------------------
export const MetroGreenLine: React.FC<MetroGreenLineProps> = (props) => {
	const { data, dataKey } = props

	// Render
	//--------------------------------------------------------------------------
	return (
		<g id="Green_line">
			<path
				id="Line_00000082333363374443468560000006070659824301952953_"
				fill="none"
				stroke="#2EA64A"
				strokeWidth="50"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				d="
    M736.7,2443.7l161.3-118.1c24-16.5,38.4-6.3,59.9,13.3l6.7,5.8c22.5,20.5,50,16.3,74.1-2.3l134-103.7c27.1-21,41.2-42.8,20.6-70.3
    l-167.2-228c-20.7-27.6-15.3-66.6,12.1-87.5l785.5-585.2c27.9-21.2,40.8-51.5,19.1-79l-20.8-22.5c-21.8-27.6-7.3-51.2,20.8-72.4
    l211.2-161.3c28.2-21.3,61.1-44.7,39.1-72.4l-27.4-37.4c-22.2-27.8-10.3-63.7,18.3-84.8l228.7-172.2"
			/>
			<g id="Stations_00000075131365792853669280000010448301322122571158_">
				<g id="Angrignon">
					<circle
						id="Point_00000003783320391777452850000015430287862943525790_"
						fill="#FFFFFF"
						stroke="#231F20"
						strokeWidth="5"
						strokeMiterlimit="10"
						cx="736.7"
						cy="2443.7"
						r="34.1"
					/>
					<g id="Name_00000038384856613421301330000004998815966898844052_">
						<rect x="369.7" y="2412.7" fill="#2EA64A" width="320.2" height="70.7" />
						<g>
							<path
								fill="#FFFFFF"
								d="M401,2456.2l-2.4,8.6h-7.8l10.2-33.5h9.9l10.4,33.5h-8.2l-2.6-8.6H401z M409.5,2450.5l-2.1-7.1
    c-0.6-2-1.2-4.5-1.7-6.4h-0.1c-0.5,2-1,4.5-1.5,6.5l-2,7.1H409.5z"
							/>
							<path
								fill="#FFFFFF"
								d="M425.8,2464.8v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9v33.5
    h-7.9l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6v10.1H425.8z"
							/>
							<path
								fill="#FFFFFF"
								d="M488,2463.2c-2.3,0.8-6.8,1.9-11.2,1.9c-6.1,0-10.5-1.5-13.6-4.5c-3.1-2.9-4.8-7.3-4.7-12.2
    c0-11.1,8.1-17.5,19.1-17.5c4.3,0,7.6,0.8,9.3,1.6l-1.6,6.1c-1.9-0.8-4.1-1.4-7.8-1.4c-6.3,0-11.1,3.5-11.1,10.8
    c0,6.9,4.4,11,10.6,11c1.7,0,3.1-0.2,3.7-0.5v-7h-5.2v-5.9H488V2463.2z"
							/>
							<path
								fill="#FFFFFF"
								d="M493.9,2431.8c2.4-0.4,6-0.7,10.1-0.7c5,0,8.4,0.8,10.8,2.7c2,1.6,3.1,3.9,3.1,7c0,4.2-3,7.2-5.9,8.2
    v0.2c2.3,0.9,3.6,3.2,4.5,6.2c1,3.8,2,8.1,2.7,9.4h-7.7c-0.5-1-1.3-3.7-2.3-7.8c-0.9-4.2-2.3-5.3-5.5-5.3h-2.2v13.1h-7.5
    V2431.8z M501.4,2446.2h3c3.8,0,6-1.9,6-4.8c0-3.1-2.1-4.6-5.6-4.6c-1.8,0-2.9,0.1-3.4,0.3V2446.2z"
							/>
							<path fill="#FFFFFF" d="M530.9,2431.3v33.5h-7.6v-33.5H530.9z" />
							<path
								fill="#FFFFFF"
								d="M565.4,2463.2c-2.3,0.8-6.8,1.9-11.2,1.9c-6.1,0-10.5-1.5-13.6-4.5c-3.1-2.9-4.8-7.3-4.7-12.2
    c0-11.1,8.1-17.5,19.1-17.5c4.3,0,7.6,0.8,9.3,1.6l-1.6,6.1c-1.9-0.8-4.1-1.4-7.8-1.4c-6.3,0-11.1,3.5-11.1,10.8
    c0,6.9,4.4,11,10.6,11c1.7,0,3.1-0.2,3.7-0.5v-7h-5.2v-5.9h12.5V2463.2z"
							/>
							<path
								fill="#FFFFFF"
								d="M571.3,2464.8v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9v33.5
    h-7.9l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6v10.1H571.3z"
							/>
							<path
								fill="#FFFFFF"
								d="M636.2,2447.7c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C630.6,2430.8,636.2,2438.5,636.2,2447.7z M612,2448.1c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C615,2436.8,612,2441.6,612,2448.1z"
							/>
							<path
								fill="#FFFFFF"
								d="M641.2,2464.8v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9v33.5
    H661l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6v10.1H641.2z"
							/>
						</g>
					</g>
					<text
						transform="matrix(1 0 0 1 690 2513.145)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="end"
					>
						{Utils.getFormattedValueFromStation(data.angrignon, dataKey)}
					</text>
				</g>
				<g id="Monk">
					<circle
						id="Point_00000152259699789507053180000009826002904480723841_"
						fill="#FFFFFF"
						cx="819.5"
						cy="2380.1"
						r="18.3"
					/>
					<g id="Name_00000057117950115486111950000013075334650771633027_">
						<path
							fill="#353535"
							d="M694.3,2343.3c-0.1-2.7-0.2-5.9-0.2-9.2H694c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7h-3.9l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4H675l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L694.3,2343.3z"
						/>
						<path
							fill="#353535"
							d="M723.8,2340.5c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C720.1,2329.2,723.8,2334.3,723.8,2340.5z M707.7,2340.8c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C709.7,2333.2,707.7,2336.5,707.7,2340.8z"
						/>
						<path
							fill="#353535"
							d="M727.1,2351.9v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H727.1z"
						/>
						<path
							fill="#353535"
							d="M749.9,2329.6h5v9.8h0.1c0.5-0.8,1-1.6,1.5-2.4l5-7.4h6.2l-7.4,9.5l7.8,12.8h-5.9l-5.5-9.6l-1.9,2.3v7.3
    h-5V2329.6z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 660 2352.145)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="end"
					>
						{Utils.getFormattedValueFromStation(data.monk, dataKey)}
					</text>
				</g>
				<g id="Jolicoeur">
					<ellipse
						id="Point_00000015346722776505355530000006119960948652707461_"
						transform="matrix(0.2298 -0.9732 0.9732 0.2298 -1551.7924 2677.9546)"
						fill="#FFFFFF"
						cx="916"
						cy="2319.4"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000171715477892852182570000014500153565748547970_">
						<path
							fill="#353535"
							d="M710.6,2259.7h5v14.1c0,6.3-3,8.5-7.9,8.5c-1.2,0-2.7-0.2-3.7-0.5l0.6-4.1c0.7,0.2,1.6,0.4,2.6,0.4
    c2.1,0,3.4-0.9,3.4-4.4V2259.7z"
						/>
						<path
							fill="#353535"
							d="M740.2,2270.6c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C736.5,2259.4,740.2,2264.5,740.2,2270.6z M724.1,2270.9c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C726.1,2263.4,724.1,2266.6,724.1,2270.9z"
						/>
						<path fill="#353535" d="M743.5,2259.7h5v18h8.9v4.2h-13.9V2259.7z" />
						<path fill="#353535" d="M765.5,2259.7v22.3h-5v-22.3H765.5z" />
						<path
							fill="#353535"
							d="M786.2,2281.4c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L786.2,2281.4z"
						/>
						<path
							fill="#353535"
							d="M808.9,2270.6c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C805.1,2259.4,808.9,2264.5,808.9,2270.6z M792.8,2270.9c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C794.8,2263.4,792.8,2266.6,792.8,2270.9z"
						/>
						<path
							fill="#353535"
							d="M825.4,2272.6h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V2272.6z"
						/>
						<path
							fill="#353535"
							d="M834.8,2259.7v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H834.8z"
						/>
						<path
							fill="#353535"
							d="M852.4,2260c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7c0,2.8-2,4.8-3.9,5.5
    v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3H864c-0.3-0.6-0.9-2.4-1.5-5.2c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V2260z
    M857.3,2269.6h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2V2269.6z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 690 2281.145)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="end"
					>
						{Utils.getFormattedValueFromStation(data.jolicoeur, dataKey)}
					</text>
				</g>
				<g id="Verdun">
					<ellipse
						id="Point_00000093875753684395052550000000530758853559453345_"
						transform="matrix(0.1602 -0.9871 0.9871 0.1602 -1480.5216 2976.759)"
						fill="#FFFFFF"
						cx="1009.1"
						cy="2358.5"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000031915651011245487540000017095868546318772394_">
						<path
							fill="#353535"
							d="M1050,2415.1l-7.1-22.3h5.5l2.7,9.4c0.8,2.6,1.5,5.2,2,7.9h0.1c0.6-2.7,1.3-5.3,2-7.9l2.8-9.5h5.4
    l-7.5,22.3H1050z"
						/>
						<path
							fill="#353535"
							d="M1079,2405.6h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V2405.6z"
						/>
						<path
							fill="#353535"
							d="M1083.4,2393.1c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V2393.1z M1088.4,2402.7h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V2402.7z"
						/>
						<path
							fill="#353535"
							d="M1103,2393.1c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4c0,4.3-1.5,7.2-3.7,9
    c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V2393.1z M1108,2411.3c0.4,0.1,1.1,0.1,1.8,0.1c4.6,0,7.6-2.5,7.6-7.8
    c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V2411.3z"
						/>
						<path
							fill="#353535"
							d="M1131,2392.8v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H1131z"
						/>
						<path
							fill="#353535"
							d="M1148.5,2415.1v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6
    v22.3h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1148.5z"
						/>
					</g>
					<g id="Value_00000091699082481093931720000013695480185112327603_"></g>
					<text
						transform="matrix(1 0 0 1 1180 2415.145)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.verdun, dataKey)}
					</text>
				</g>
				<g id="De_l_x27_église">
					<ellipse
						id="Point_00000053528036791193243420000013207387811014267023_"
						transform="matrix(0.2298 -0.9732 0.9732 0.2298 -1389.6489 2837.9214)"
						fill="#FFFFFF"
						cx="1098.1"
						cy="2296.9"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000090994365878674433260000005979805054195891635_">
						<path
							fill="#353535"
							d="M1129.6,2326.6c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V2326.6z M1134.6,2344.7c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V2344.7z"
						/>
						<path
							fill="#353535"
							d="M1165.8,2339.1h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V2339.1z"
						/>
						<path fill="#353535" d="M1176.9,2326.3h5v18h8.9v4.2h-13.9V2326.3z" />
						<path
							fill="#353535"
							d="M1196.2,2325.9c-1,3.2-2.5,6.5-4,8.9l-3,0.3c1-2.8,1.9-6,2.3-8.9L1196.2,2325.9z"
						/>
						<path
							fill="#353535"
							d="M1212.4,2339.1h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V2339.1z"
						/>
						<path
							fill="#353535"
							d="M1235.4,2347.5c-1.6,0.5-4.5,1.3-7.4,1.3c-4.1,0-7-1-9.1-3c-2-1.9-3.2-4.8-3.1-8.1
    c0-7.4,5.4-11.6,12.7-11.6c2.9,0,5.1,0.5,6.2,1.1l-1.1,4c-1.2-0.5-2.7-0.9-5.2-0.9c-4.2,0-7.4,2.4-7.4,7.2c0,4.6,2.9,7.3,7,7.3
    c1.1,0,2.1-0.1,2.5-0.3v-4.7h-3.4v-3.9h8.3V2347.5z"
						/>
						<path fill="#353535" d="M1239.3,2326.3h5v18h8.9v4.2h-13.9V2326.3z" />
						<path fill="#353535" d="M1261.3,2326.3v22.3h-5v-22.3H1261.3z" />
						<path
							fill="#353535"
							d="M1265.8,2343.3c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L1265.8,2343.3z"
						/>
						<path
							fill="#353535"
							d="M1296.7,2339.1h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V2339.1z"
						/>
					</g>
					<g id="Value_00000096026252922822242690000000006670880007341479_"></g>
					<text
						transform="matrix(1 0 0 1 1310 2349.145)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.deLEglise, dataKey)}
					</text>
				</g>
				<g id="Lasalle">
					<ellipse
						id="Point_00000145029941891862214320000002549887113816096918_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -1237.6785 1484.3737)"
						fill="#FFFFFF"
						cx="1173"
						cy="2236.2"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000096036119787307641270000004626718438222852269_">
						<path fill="#353535" d="M1221.1,2260.6h5v18h8.9v4.2h-13.9V2260.6z" />
						<path
							fill="#353535"
							d="M1243.3,2277.1l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1243.3z M1249,2273.4l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1249z"
						/>
						<path
							fill="#353535"
							d="M1260,2277.6c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L1260,2277.6z"
						/>
						<path
							fill="#353535"
							d="M1282.8,2277.1l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1282.8z M1288.5,2273.4l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1288.5z"
						/>
						<path fill="#353535" d="M1299.3,2260.6h5v18h8.9v4.2h-13.9V2260.6z" />
						<path fill="#353535" d="M1316.2,2260.6h5v18h8.9v4.2h-13.9V2260.6z" />
						<path
							fill="#353535"
							d="M1346.3,2273.4h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V2273.4z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 1360 2282.145)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.lasalle, dataKey)}
					</text>
				</g>
				<g id="Charlevoix">
					<circle
						id="Point_00000129206678806935702590000000936335890802496927_"
						fill="#FFFFFF"
						cx="1160.5"
						cy="2123.1"
						r="18.3"
					/>
					<g id="Name_00000116950904655055901940000001232761838992595090_">
						<path
							fill="#353535"
							d="M1234.9,2131.7c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L1234.9,2131.7z"
						/>
						<path fill="#353535" d="M1243.3,2110v8.6h8.3v-8.6h5v22.3h-5v-9.3h-8.3v9.3h-5V2110H1243.3z" />
						<path
							fill="#353535"
							d="M1266.2,2126.6l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1266.2z M1271.8,2122.8l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1271.8z"
						/>
						<path
							fill="#353535"
							d="M1282.7,2110.3c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V2110.3z M1287.7,2119.9h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V2119.9z"
						/>
						<path fill="#353535" d="M1302.3,2110h5v18h8.9v4.2h-13.9V2110z" />
						<path
							fill="#353535"
							d="M1332.4,2122.9h-8.2v5.3h9.2v4.1h-14.2V2110h13.7v4.1h-8.7v4.6h8.2V2122.9z"
						/>
						<path
							fill="#353535"
							d="M1342.3,2132.3l-7.1-22.3h5.5l2.7,9.4c0.8,2.6,1.5,5.2,2,7.9h0.1c0.6-2.7,1.3-5.3,2-7.9l2.8-9.5h5.4
    l-7.5,22.3H1342.3z"
						/>
						<path
							fill="#353535"
							d="M1378.1,2120.9c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1374.4,2109.7,1378.1,2114.8,1378.1,2120.9z M1362,2121.2c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1364,2113.7,1362,2116.9,1362,2121.2z"
						/>
						<path fill="#353535" d="M1386.5,2110v22.3h-5V2110H1386.5z" />
						<path
							fill="#353535"
							d="M1402.6,2132.3l-2-4c-0.8-1.6-1.4-2.7-2-4h-0.1c-0.5,1.3-1,2.5-1.7,4l-1.8,4h-5.7l6.4-11.3l-6.2-11h5.8
    l1.9,4.1c0.7,1.4,1.1,2.5,1.7,3.7h0.1c0.5-1.4,0.9-2.4,1.5-3.7l1.9-4.1h5.7l-6.3,10.9l6.6,11.4H1402.6z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 1420 2132.145)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.charlevoix, dataKey)}
					</text>
				</g>
				<g id="Lionel-groulx">
					<ellipse
						id="Point_00000166671009148770312010000013210192817984506497_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -1108.9479 1353.542)"
						fill="#FFFFFF"
						stroke="#231F20"
						strokeWidth="5"
						strokeMiterlimit="10"
						cx="1079.4"
						cy="2015.4"
						rx="34.1"
						ry="34.1"
					/>
					<g id="Name_00000030477881261118952860000004750798672605076919_">
						<path fill="#353535" d="M1162.7,2020.7h7.6v27.1h13.3v6.3h-20.9V2020.7z" />
						<path fill="#353535" d="M1195.6,2020.7v33.5h-7.6v-33.5H1195.6z" />
						<path
							fill="#353535"
							d="M1232.8,2037.1c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C1227.2,2020.1,1232.8,2027.8,1232.8,2037.1z M1208.6,2037.5c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C1211.6,2026.1,1208.6,2031,1208.6,2037.5z"
						/>
						<path
							fill="#353535"
							d="M1237.8,2054.1v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9v33.5
    h-7.9l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6v10.1H1237.8z"
						/>
						<path fill="#353535" d="M1291.9,2040h-12.3v8h13.8v6.2H1272v-33.5h20.6v6.2h-13v7h12.3V2040z" />
						<path fill="#353535" d="M1298.6,2020.7h7.6v27.1h13.3v6.3h-20.9V2020.7z" />
						<path fill="#353535" d="M1334.2,2038.6v5h-13v-5H1334.2z" />
						<path
							fill="#353535"
							d="M1367.6,2052.6c-2.3,0.8-6.8,1.9-11.2,1.9c-6.1,0-10.5-1.5-13.6-4.5c-3.1-2.9-4.8-7.3-4.7-12.2
    c0-11.1,8.1-17.5,19.1-17.5c4.3,0,7.6,0.8,9.3,1.6l-1.6,6.1c-1.9-0.8-4.1-1.4-7.8-1.4c-6.3,0-11.1,3.5-11.1,10.8
    c0,6.9,4.4,11,10.6,11c1.7,0,3.1-0.2,3.7-0.5v-7h-5.2v-5.9h12.5V2052.6z"
						/>
						<path
							fill="#353535"
							d="M1373.5,2021.1c2.4-0.4,6-0.7,10.1-0.7c5,0,8.4,0.8,10.8,2.7c2,1.6,3.1,3.9,3.1,7c0,4.2-3,7.2-5.9,8.2
    v0.2c2.3,0.9,3.6,3.2,4.5,6.2c1,3.8,2,8.1,2.7,9.4h-7.7c-0.5-1-1.3-3.7-2.3-7.8c-0.9-4.2-2.3-5.3-5.5-5.3h-2.2v13.1h-7.5V2021.1
    z M1381,2035.5h3c3.8,0,6-1.9,6-4.8c0-3.1-2.1-4.6-5.6-4.6c-1.8,0-2.9,0.1-3.4,0.3V2035.5z"
						/>
						<path
							fill="#353535"
							d="M1433.3,2037.1c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C1427.7,2020.1,1433.3,2027.8,1433.3,2037.1z M1409.2,2037.5c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C1412.2,2026.1,1409.2,2031,1409.2,2037.5z"
						/>
						<path
							fill="#353535"
							d="M1445.8,2020.7v19.2c0,5.8,2.2,8.7,6.1,8.7c4,0,6.2-2.8,6.2-8.7v-19.2h7.6v18.8c0,10.3-5.2,15.2-14,15.2
    c-8.4,0-13.4-4.7-13.4-15.3v-18.7H1445.8z"
						/>
						<path fill="#353535" d="M1472.2,2020.7h7.6v27.1h13.3v6.3h-20.9V2020.7z" />
						<path
							fill="#353535"
							d="M1515.1,2054.1l-3-6.1c-1.2-2.3-2-4.1-3-6h-0.1c-0.7,1.9-1.5,3.7-2.6,6l-2.8,6.1h-8.6l9.7-16.9
    l-9.3-16.5h8.7l2.9,6.1c1,2,1.7,3.7,2.5,5.6h0.1c0.8-2.1,1.4-3.6,2.3-5.5l2.8-6.1h8.6l-9.4,16.3l9.9,17.1H1515.1z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 1540 2047)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.lionelGroulx, dataKey)}
					</text>
				</g>
				<g id="Atwater">
					<ellipse
						id="Point_00000131354567620141107420000013138942988804761265_"
						transform="matrix(0.2298 -0.9732 0.9732 0.2298 -957.0428 2454.7378)"
						fill="#FFFFFF"
						cx="1072.3"
						cy="1832"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000167372620058607454560000007229659976224152219_">
						<path
							fill="#353535"
							d="M905.2,1796.4l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H905.2z M910.9,1792.7l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H910.9z"
						/>
						<path fill="#353535" d="M923.3,1784.1h-6v-4.2h17.1v4.2h-6.1v18h-5V1784.1z" />
						<path
							fill="#353535"
							d="M941.6,1802.1l-5.3-22.3h5.4l1.7,9.2c0.5,2.7,0.9,5.5,1.3,7.8h0.1c0.4-2.4,0.9-5.1,1.5-7.9l1.9-9.1h5.3
    l1.8,9.4c0.5,2.6,0.9,5,1.2,7.4h0.1c0.3-2.5,0.8-5.1,1.3-7.7l1.8-9.1h5.1l-5.7,22.3h-5.4l-1.9-9.6c-0.4-2.2-0.8-4.3-1.1-6.9
    h-0.1c-0.4,2.5-0.7,4.6-1.3,6.9l-2.1,9.6H941.6z"
						/>
						<path
							fill="#353535"
							d="M970.7,1796.4l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H970.7z M976.4,1792.7l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H976.4z"
						/>
						<path fill="#353535" d="M988.8,1784.1h-6v-4.2h17.1v4.2h-6.1v18h-5V1784.1z" />
						<path
							fill="#353535"
							d="M1015.8,1792.7h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1792.7z"
						/>
						<path
							fill="#353535"
							d="M1020.2,1780.2c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1780.2z M1025.2,1789.7h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1789.7z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 900 1770)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.atwater, dataKey)}
					</text>
				</g>
				<g id="Guy-concordia">
					<circle
						id="Point_00000060750313705147935320000011729340301330523532_"
						fill="#FFFFFF"
						cx="1173"
						cy="1753.8"
						r="18.3"
					/>
					<g id="Name_00000072972388736007175130000011753143458024792715_">
						<path
							fill="#353535"
							d="M899.5,1724.6c-1.6,0.5-4.5,1.3-7.4,1.3c-4.1,0-7-1-9.1-3c-2-1.9-3.2-4.8-3.1-8.1
    c0-7.4,5.4-11.6,12.7-11.6c2.9,0,5.1,0.5,6.2,1.1l-1.1,4c-1.2-0.5-2.7-0.9-5.2-0.9c-4.2,0-7.4,2.4-7.4,7.2c0,4.6,2.9,7.3,7,7.3
    c1.1,0,2.1-0.1,2.5-0.3v-4.7h-3.4v-3.9h8.3V1724.6z"
						/>
						<path
							fill="#353535"
							d="M908.5,1703.3v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H908.5z"
						/>
						<path
							fill="#353535"
							d="M931.1,1725.6v-9.1l-7-13.1h5.8l2.3,5.4c0.7,1.6,1.2,2.8,1.7,4.3h0.1c0.5-1.4,1-2.7,1.7-4.3l2.2-5.4h5.7
    l-7.3,13v9.3H931.1z"
						/>
						<path fill="#353535" d="M951.3,1715.3v3.3h-8.6v-3.3H951.3z" />
						<path
							fill="#353535"
							d="M971.2,1725c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L971.2,1725z"
						/>
						<path
							fill="#353535"
							d="M993.9,1714.3c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C990.2,1703,993.9,1708.1,993.9,1714.3z M977.8,1714.5c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C979.8,1707,977.8,1710.2,977.8,1714.5z"
						/>
						<path
							fill="#353535"
							d="M997.2,1725.6v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H997.2z"
						/>
						<path
							fill="#353535"
							d="M1036.4,1725c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L1036.4,1725z"
						/>
						<path
							fill="#353535"
							d="M1059.1,1714.3c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1055.3,1703,1059.1,1708.1,1059.1,1714.3z M1043,1714.5c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1045,1707,1043,1710.2,1043,1714.5z"
						/>
						<path
							fill="#353535"
							d="M1062.4,1703.7c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1703.7z M1067.4,1713.2h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1713.2z"
						/>
						<path
							fill="#353535"
							d="M1082,1703.7c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4c0,4.3-1.5,7.2-3.7,9
    c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V1703.7z M1087,1721.8c0.4,0.1,1.1,0.1,1.8,0.1c4.6,0,7.6-2.5,7.6-7.8
    c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V1721.8z"
						/>
						<path fill="#353535" d="M1110,1703.3v22.3h-5v-22.3H1110z" />
						<path
							fill="#353535"
							d="M1119.6,1719.9l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1119.6z M1125.2,1716.1l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1125.2z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 880 1695)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.guyConcordia, dataKey)}
					</text>
				</g>
				<g id="Peel">
					<ellipse
						id="Point_00000021104793465422463330000009403372290157693580_"
						transform="matrix(0.2298 -0.9732 0.9732 0.2298 -659.6959 2532.7161)"
						fill="#FFFFFF"
						cx="1270.3"
						cy="1683.1"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000044159633510608063200000016239145378260850110_">
						<path
							fill="#353535"
							d="M1163.7,1631.3c1.5-0.3,3.7-0.5,6.8-0.5c3.1,0,5.3,0.6,6.8,1.8c1.4,1.1,2.4,3,2.4,5.1c0,2.2-0.7,4-2,5.3
    c-1.7,1.6-4.3,2.4-7.2,2.4c-0.7,0-1.3,0-1.7-0.1v8h-5V1631.3z M1168.7,1641.4c0.4,0.1,0.9,0.1,1.7,0.1c2.7,0,4.3-1.4,4.3-3.6
    c0-2-1.4-3.3-3.9-3.3c-1,0-1.7,0.1-2.1,0.2V1641.4z"
						/>
						<path
							fill="#353535"
							d="M1196.1,1643.8h-8.2v5.3h9.2v4.1h-14.2V1631h13.7v4.1h-8.7v4.6h8.2V1643.8z"
						/>
						<path
							fill="#353535"
							d="M1213.8,1643.8h-8.2v5.3h9.2v4.1h-14.2V1631h13.7v4.1h-8.7v4.6h8.2V1643.8z"
						/>
						<path fill="#353535" d="M1218.2,1631h5v18h8.9v4.2h-13.9V1631z" />
					</g>
					<text
						transform="matrix(1 0 0 1 1150 1650.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="end"
					>
						{Utils.getFormattedValueFromStation(data.peel, dataKey)}
					</text>
				</g>
				<g id="McGill">
					<ellipse
						id="Point_00000003819646222729953520000005327311215579653283_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -732.8665 1440.6278)"
						fill="#FFFFFF"
						cx="1372.6"
						cy="1605"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000099650648507207177550000016779599571150009023_">
						<path
							fill="#353535"
							d="M1244,1567.4c-0.1-2.7-0.2-5.9-0.2-9.2h-0.1c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7h-3.9l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4h-4.7l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L1244,1567.4z"
						/>
						<path
							fill="#353535"
							d="M1265.2,1575.5c-0.9,0.4-2.6,0.8-4.5,0.8c-5.2,0-8.6-3.2-8.6-8.3c0-4.7,3.2-8.6,9.2-8.6
    c1.3,0,2.8,0.2,3.8,0.6l-0.8,3.7c-0.6-0.3-1.5-0.5-2.8-0.5c-2.7,0-4.4,1.9-4.3,4.5c0,3,2,4.5,4.4,4.5c1.2,0,2.1-0.2,2.9-0.5
    L1265.2,1575.5z"
						/>
						<path
							fill="#353535"
							d="M1286.8,1574.9c-1.6,0.5-4.5,1.3-7.4,1.3c-4.1,0-7-1-9.1-3c-2-1.9-3.2-4.8-3.1-8.1
    c0-7.4,5.4-11.6,12.7-11.6c2.9,0,5.1,0.5,6.2,1.1l-1.1,4c-1.2-0.5-2.7-0.9-5.2-0.9c-4.2,0-7.4,2.4-7.4,7.2c0,4.6,2.9,7.3,7,7.3
    c1.1,0,2.1-0.1,2.5-0.3v-4.7h-3.4v-3.9h8.3V1574.9z"
						/>
						<path fill="#353535" d="M1295.7,1553.6v22.3h-5v-22.3H1295.7z" />
						<path fill="#353535" d="M1300.1,1553.6h5v18h8.9v4.2h-13.9V1553.6z" />
						<path fill="#353535" d="M1317,1553.6h5v18h8.9v4.2H1317V1553.6z" />
					</g>
					<text
						transform="matrix(1 0 0 1 1215 1576.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="end"
					>
						{Utils.getFormattedValueFromStation(data.mcGill, dataKey)}
					</text>
				</g>
				<g id="Place-des-arts">
					<circle
						id="Point_00000178194553026167705800000015195090490255739533_"
						fill="#FFFFFF"
						cx="1476.5"
						cy="1524.3"
						r="18.3"
					/>
					<g id="Name_00000111169197655271494940000006619739032652123522_">
						<path
							fill="#353535"
							d="M1191.1,1476.6c1.5-0.3,3.7-0.5,6.8-0.5c3.1,0,5.3,0.6,6.8,1.8c1.4,1.1,2.4,3,2.4,5.1c0,2.2-0.7,4-2,5.3
    c-1.7,1.6-4.3,2.4-7.2,2.4c-0.7,0-1.3,0-1.7-0.1v8h-5V1476.6z M1196.1,1486.7c0.4,0.1,0.9,0.1,1.7,0.1c2.7,0,4.3-1.4,4.3-3.6
    c0-2-1.4-3.3-3.9-3.3c-1,0-1.7,0.1-2.1,0.2V1486.7z"
						/>
						<path fill="#353535" d="M1210.3,1476.3h5v18h8.9v4.2h-13.9V1476.3z" />
						<path
							fill="#353535"
							d="M1232.6,1492.8l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1232.6z M1238.2,1489.1l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1238.2z"
						/>
						<path
							fill="#353535"
							d="M1264.6,1498c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L1264.6,1498z"
						/>
						<path
							fill="#353535"
							d="M1281.1,1489.1h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1489.1z"
						/>
						<path fill="#353535" d="M1293,1488.2v3.3h-8.6v-3.3H1293z" />
						<path
							fill="#353535"
							d="M1296.2,1476.6c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V1476.6z M1301.2,1494.8c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V1494.8z"
						/>
						<path
							fill="#353535"
							d="M1332.4,1489.1h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1489.1z"
						/>
						<path
							fill="#353535"
							d="M1337,1493.4c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L1337,1493.4z"
						/>
						<path fill="#353535" d="M1362.5,1488.2v3.3h-8.6v-3.3H1362.5z" />
						<path
							fill="#353535"
							d="M1370.7,1492.8l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1370.7z M1376.4,1489.1l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1376.4z"
						/>
						<path
							fill="#353535"
							d="M1387.2,1476.6c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1476.6z M1392.2,1486.2h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1486.2z"
						/>
						<path fill="#353535" d="M1411.1,1480.5h-6v-4.2h17.1v4.2h-6.1v18h-5V1480.5z" />
						<path
							fill="#353535"
							d="M1424.8,1493.4c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L1424.8,1493.4z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 1175 1498.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="end"
					>
						{Utils.getFormattedValueFromStation(data.placeDesArts, dataKey)}
					</text>
				</g>
				<g id="Saint-Laurent">
					<ellipse
						id="Point_00000142165207035143807480000013477008483011169207_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -567.6185 1536.845)"
						fill="#FFFFFF"
						cx="1571.3"
						cy="1453.6"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000176030476446252875370000009547147106302102916_">
						<path
							fill="#353535"
							d="M1304.4,1421.8c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L1304.4,1421.8z"
						/>
						<path
							fill="#353535"
							d="M1327.2,1421.3l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1327.2z M1332.9,1417.6l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1332.9z"
						/>
						<path fill="#353535" d="M1348.8,1404.8v22.3h-5v-22.3H1348.8z" />
						<path
							fill="#353535"
							d="M1353.2,1427v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1353.2z"
						/>
						<path fill="#353535" d="M1380.3,1409h-6v-4.2h17.1v4.2h-6.1v18h-5V1409z" />
						<path fill="#353535" d="M1399.5,1416.7v3.3h-8.6v-3.3H1399.5z" />
						<path fill="#353535" d="M1402.7,1404.8h5v18h8.9v4.2h-13.9V1404.8z" />
						<path
							fill="#353535"
							d="M1425,1421.3l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1425z M1430.6,1417.6l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1430.6z"
						/>
						<path
							fill="#353535"
							d="M1445.3,1404.8v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H1445.3z"
						/>
						<path
							fill="#353535"
							d="M1462.9,1405.1c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1405.1z M1467.8,1414.7h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1414.7z"
						/>
						<path
							fill="#353535"
							d="M1495.7,1417.6h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1417.6z"
						/>
						<path
							fill="#353535"
							d="M1500.1,1427v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1500.1z"
						/>
						<path fill="#353535" d="M1527.2,1409h-6v-4.2h17.1v4.2h-6.1v18h-5V1409z" />
					</g>
					<text
						transform="matrix(1 0 0 1 1290 1427.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="end"
					>
						{Utils.getFormattedValueFromStation(data.saintLaurent, dataKey)}
					</text>
				</g>
				<g id="Berri-Uqam_00000181778808040346364200000017813567215533034685_">
					<g id="Name_00000181072472768855115140000006691915391046183871_">
						<rect x="1249.3" y="1314.5" fill="#2EA64A" width="318.5" height="70.7" />
						<g>
							<path
								fill="#353535"
								d="M1267.1,1331c2-0.4,6-0.7,9.8-0.7c4.6,0,7.4,0.5,9.9,1.9c2.3,1.2,4,3.5,4,6.6c0,2.9-1.7,5.7-5.5,7.1
    v0.1c3.8,1,6.7,3.9,6.7,8.2c0,3.1-1.4,5.5-3.5,7.1c-2.5,2-6.5,3-13.2,3c-3.7,0-6.5-0.2-8.2-0.5V1331z M1274.6,1343.8h2.5
    c4,0,6.1-1.7,6.1-4.2c0-2.5-1.9-3.9-5.3-3.9c-1.7,0-2.6,0.1-3.2,0.2V1343.8z M1274.6,1358.6c0.7,0.1,1.6,0.1,2.9,0.1
    c3.4,0,6.5-1.3,6.5-4.9c0-3.3-3-4.7-6.8-4.7h-2.6V1358.6z"
							/>
							<path
								fill="#353535"
								d="M1317,1349.8h-12.3v8h13.8v6.2h-21.3v-33.5h20.6v6.2h-13v7h12.3V1349.8z"
							/>
							<path
								fill="#353535"
								d="M1323.6,1331c2.4-0.4,6-0.7,10.1-0.7c5,0,8.4,0.8,10.8,2.7c2,1.6,3.1,3.9,3.1,7c0,4.2-3,7.2-5.9,8.2
    v0.2c2.3,0.9,3.6,3.2,4.5,6.2c1,3.8,2,8.1,2.7,9.4h-7.7c-0.5-1-1.3-3.7-2.3-7.8c-0.9-4.2-2.3-5.3-5.5-5.3h-2.2v13.1h-7.5V1331z
    M1331.1,1345.4h3c3.8,0,6-1.9,6-4.8c0-3.1-2.1-4.6-5.6-4.6c-1.8,0-2.9,0.1-3.4,0.3V1345.4z"
							/>
							<path
								fill="#353535"
								d="M1353.1,1331c2.4-0.4,6-0.7,10.1-0.7c5,0,8.4,0.8,10.8,2.7c2,1.6,3.1,3.9,3.1,7c0,4.2-3,7.2-5.9,8.2
    v0.2c2.3,0.9,3.6,3.2,4.5,6.2c1,3.8,2,8.1,2.7,9.4h-7.7c-0.5-1-1.3-3.7-2.3-7.8c-0.9-4.2-2.3-5.3-5.5-5.3h-2.2v13.1h-7.5V1331z
    M1360.6,1345.4h3c3.8,0,6-1.9,6-4.8c0-3.1-2.1-4.6-5.6-4.6c-1.8,0-2.9,0.1-3.4,0.3V1345.4z"
							/>
							<path fill="#353535" d="M1390.1,1330.5v33.5h-7.6v-33.5H1390.1z" />
							<path fill="#353535" d="M1407.8,1348.4v5h-13v-5H1407.8z" />
							<path
								fill="#353535"
								d="M1420.2,1330.5v19.2c0,5.8,2.2,8.7,6.1,8.7c4,0,6.2-2.8,6.2-8.7v-19.2h7.6v18.8
    c0,10.3-5.2,15.2-14,15.2c-8.4,0-13.4-4.7-13.4-15.3v-18.7H1420.2z"
							/>
							<path
								fill="#353535"
								d="M1475.1,1369.7c-4.8-1.4-8.7-2.8-13.2-4.7c-0.7-0.3-1.5-0.5-2.3-0.5c-7.6-0.5-14.6-6.1-14.6-17
    c0-10,6.3-17.6,16.4-17.6c10.2,0,15.8,7.7,15.8,16.9c0,7.6-3.5,13-7.9,14.9v0.2c2.6,0.8,5.4,1.4,8.1,1.9L1475.1,1369.7z
    M1469.1,1347.2c0-6.1-2.9-11.2-8-11.2c-5.2,0-8.2,5-8.1,11.3c0,6.4,3,11.2,8.1,11.2
    C1466.2,1358.5,1469.1,1353.7,1469.1,1347.2z"
							/>
							<path
								fill="#353535"
								d="M1489.1,1355.4l-2.4,8.6h-7.8l10.2-33.5h9.9l10.4,33.5h-8.2l-2.6-8.6H1489.1z M1497.6,1349.8l-2.1-7.1
    c-0.6-2-1.2-4.5-1.7-6.4h-0.1c-0.5,2-1,4.5-1.5,6.5l-2,7.1H1497.6z"
							/>
							<path
								fill="#353535"
								d="M1542.1,1351.2c-0.2-4.1-0.3-8.9-0.3-13.8h-0.1c-1.1,4.3-2.5,9-3.7,13l-4.1,13h-5.9l-3.6-12.9
    c-1.1-3.9-2.2-8.7-3-13.1h-0.1c-0.2,4.6-0.4,9.7-0.6,13.9l-0.6,12.7h-7l2.1-33.5h10.1l3.3,11.2c1.1,3.8,2.1,8,2.8,12h0.2
    c0.9-3.9,2-8.3,3.2-12l3.6-11.1h9.9l1.8,33.5h-7.4L1542.1,1351.2z"
							/>
						</g>
					</g>

					<ellipse
						id="Point_00000074413810710948082370000013878415486417630614_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -500.6449 1576.2786)"
						fill="#FFFFFF"
						stroke="#231F20"
						strokeWidth="5"
						strokeMiterlimit="10"
						cx="1652.4"
						cy="1392.5"
						rx="34.1"
						ry="34.1"
					/>
					<text
						transform="matrix(1 0 0 1 1520 1305)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="end"
					>
						{Utils.getFormattedValueFromStation(data.berriUqam, dataKey)}
					</text>
				</g>
				<g id="Beaudry">
					<g id="Name_00000141433524227178228720000016064100511330259642_">
						<path
							fill="#353535"
							d="M1801.6,1333.6c1.3-0.3,4-0.5,6.5-0.5c3.1,0,5,0.3,6.6,1.2c1.6,0.8,2.7,2.3,2.7,4.4c0,2-1.1,3.8-3.7,4.7
    v0.1c2.5,0.7,4.4,2.6,4.4,5.5c0,2-0.9,3.6-2.3,4.7c-1.6,1.3-4.3,2-8.8,2c-2.5,0-4.3-0.2-5.4-0.3V1333.6z M1806.6,1342.1h1.6
    c2.7,0,4.1-1.1,4.1-2.8c0-1.7-1.3-2.6-3.6-2.6c-1.1,0-1.7,0.1-2.1,0.2V1342.1z M1806.6,1351.9c0.5,0.1,1.1,0.1,1.9,0.1
    c2.3,0,4.3-0.9,4.3-3.2c0-2.2-2-3.1-4.5-3.1h-1.7V1351.9z"
						/>
						<path
							fill="#353535"
							d="M1834.8,1346.1h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1346.1z"
						/>
						<path
							fill="#353535"
							d="M1844.3,1349.8l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1844.3z M1850,1346.1l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1850z"
						/>
						<path
							fill="#353535"
							d="M1864.7,1333.3v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H1864.7z"
						/>
						<path
							fill="#353535"
							d="M1882.2,1333.6c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V1333.6z M1887.2,1351.7c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V1351.7z"
						/>
						<path
							fill="#353535"
							d="M1905.2,1333.6c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1333.6z M1910.2,1343.1h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1343.1z"
						/>
						<path
							fill="#353535"
							d="M1929.3,1355.5v-9.1l-7-13.1h5.8l2.3,5.4c0.7,1.6,1.2,2.8,1.7,4.3h0.1c0.5-1.4,1-2.7,1.7-4.3l2.2-5.4
    h5.7l-7.3,13v9.3H1929.3z"
						/>
					</g>
					<circle
						id="Point_00000113327735077405924040000006888330176205716902_"
						fill="#FFFFFF"
						cx="1769.3"
						cy="1307.2"
						r="18.3"
					/>
					<text
						transform="matrix(1 0 0 1 1800 1385)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.beaudry, dataKey)}
					</text>
				</g>
				<g id="Papineau">
					<circle
						id="Point_00000025444813250842232940000018049883290655691183_"
						fill="#FFFFFF"
						cx="1842.4"
						cy="1247.3"
						r="18.3"
					/>
					<g id="Name_00000080928614776752030690000017326306128910114452_">
						<path
							fill="#353535"
							d="M1883.1,1261.2c1.5-0.3,3.7-0.5,6.8-0.5c3.1,0,5.3,0.6,6.8,1.8c1.4,1.1,2.4,3,2.4,5.1c0,2.2-0.7,4-2,5.3
    c-1.7,1.6-4.3,2.4-7.2,2.4c-0.7,0-1.3,0-1.7-0.1v8h-5V1261.2z M1888.1,1271.3c0.4,0.1,0.9,0.1,1.7,0.1c2.7,0,4.3-1.4,4.3-3.6
    c0-2-1.4-3.3-3.9-3.3c-1,0-1.7,0.1-2.1,0.2V1271.3z"
						/>
						<path
							fill="#353535"
							d="M1905.5,1277.4l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1905.5z M1911.1,1273.7l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H1911.1z"
						/>
						<path
							fill="#353535"
							d="M1922,1261.2c1.5-0.3,3.7-0.5,6.8-0.5c3.1,0,5.3,0.6,6.8,1.8c1.4,1.1,2.4,3,2.4,5.1c0,2.2-0.7,4-2,5.3
    c-1.7,1.6-4.3,2.4-7.2,2.4c-0.7,0-1.3,0-1.7-0.1v8h-5V1261.2z M1927,1271.3c0.4,0.1,0.9,0.1,1.7,0.1c2.7,0,4.3-1.4,4.3-3.6
    c0-2-1.4-3.3-3.9-3.3c-1,0-1.7,0.1-2.1,0.2V1271.3z"
						/>
						<path fill="#353535" d="M1946.2,1260.9v22.3h-5v-22.3H1946.2z" />
						<path
							fill="#353535"
							d="M1950.6,1283.2v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6
    v22.3h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1950.6z"
						/>
						<path
							fill="#353535"
							d="M1986.6,1273.7h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1273.7z"
						/>
						<path
							fill="#353535"
							d="M1996.2,1277.4l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H1996.2z M2001.8,1273.7l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H2001.8z"
						/>
						<path
							fill="#353535"
							d="M2016.5,1260.9v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5v12.5c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H2016.5z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 2045 1280)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.papineau, dataKey)}
					</text>
				</g>
				<g id="Frontenac">
					<circle
						id="Point_00000008124671616520088940000017166959310763701674_"
						fill="#FFFFFF"
						cx="1819.2"
						cy="1162.5"
						r="18.3"
					/>
					<g id="Name_00000123432284280462133540000009937666315867831480_">
						<path fill="#353535" d="M1893.9,1161.3h13.6v4.1h-8.6v5.1h8v4.1h-8v9h-5V1161.3z" />
						<path
							fill="#353535"
							d="M1911.3,1161.6c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1161.6z M1916.3,1171.1h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V1171.1z"
						/>
						<path
							fill="#353535"
							d="M1951.1,1172.2c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1947.4,1160.9,1951.1,1166,1951.1,1172.2z M1935,1172.5c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1937,1164.9,1935,1168.1,1935,1172.5z"
						/>
						<path
							fill="#353535"
							d="M1954.4,1183.5v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6
    v22.3h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1954.4z"
						/>
						<path fill="#353535" d="M1981.5,1165.5h-6v-4.2h17.1v4.2h-6.1v18h-5V1165.5z" />
						<path
							fill="#353535"
							d="M2008.6,1174.1h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1174.1z"
						/>
						<path
							fill="#353535"
							d="M2013,1183.5v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H2013z"
						/>
						<path
							fill="#353535"
							d="M2040.9,1177.8l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H2040.9z M2046.6,1174.1l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H2046.6z"
						/>
						<path
							fill="#353535"
							d="M2073,1182.9c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L2073,1182.9z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 2085 1183.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.frontenac, dataKey)}
					</text>
				</g>
				<g id="Préfontaine">
					<circle
						id="Point_00000135658038561720962880000001560355077704483503_"
						fill="#FFFFFF"
						cx="1860.7"
						cy="1080.2"
						r="18.3"
					/>
					<g id="Name_00000080929377368438513410000011889264613645022904_">
						<path
							fill="#353535"
							d="M1889.7,1109c1.5-0.3,3.7-0.5,6.8-0.5c3.1,0,5.3,0.6,6.8,1.8c1.4,1.1,2.4,3,2.4,5.1c0,2.2-0.7,4-2,5.3
    c-1.7,1.6-4.3,2.4-7.2,2.4c-0.7,0-1.3,0-1.7-0.1v8h-5V1109z M1894.7,1119.1c0.4,0.1,0.9,0.1,1.7,0.1c2.7,0,4.3-1.4,4.3-3.6
    c0-2-1.4-3.3-3.9-3.3c-1,0-1.7,0.1-2.1,0.2V1119.1z"
						/>
						<path
							fill="#353535"
							d="M1908.9,1109c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7c0,2.8-2,4.8-3.9,5.5
    v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V1109z
    M1913.9,1118.6h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2V1118.6z"
						/>
						<path
							fill="#353535"
							d="M1941.7,1121.6h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1121.6z"
						/>
						<path fill="#353535" d="M1946.2,1108.7h13.6v4.1h-8.6v5.1h8v4.1h-8v9h-5V1108.7z" />
						<path
							fill="#353535"
							d="M1983.9,1119.6c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1980.2,1108.3,1983.9,1113.5,1983.9,1119.6z M1967.8,1119.9c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1969.8,1112.3,1967.8,1115.6,1967.8,1119.9z"
						/>
						<path
							fill="#353535"
							d="M1987.3,1131v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H1987.3z"
						/>
						<path fill="#353535" d="M2014.4,1112.9h-6v-4.2h17.1v4.2h-6.1v18h-5V1112.9z" />
						<path
							fill="#353535"
							d="M2030.9,1125.3l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H2030.9z M2036.6,1121.5l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H2036.6z"
						/>
						<path fill="#353535" d="M2052.5,1108.7v22.3h-5v-22.3H2052.5z" />
						<path
							fill="#353535"
							d="M2056.9,1131v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H2056.9z"
						/>
						<path
							fill="#353535"
							d="M2092.9,1121.6h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1121.6z"
						/>
						<g>
							<path fill="#353535" d="M1938.6,1099.8l-4,4.7h-2.1l2.9-4.7H1938.6z" />
						</g>
					</g>
					<text
						transform="matrix(1 0 0 1 2110 1131.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.prefontaine, dataKey)}
					</text>
				</g>
				<g id="Joliette">
					<ellipse
						id="Point_00000111905231297628932180000012167498985193707183_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -158.2093 1666.9663)"
						fill="#FFFFFF"
						cx="1933.1"
						cy="1024.5"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000174593699585593368570000015196679251034894270_">
						<path
							fill="#353535"
							d="M1969.8,1050.5h5v14.1c0,6.3-3,8.5-7.9,8.5c-1.2,0-2.7-0.2-3.7-0.5l0.6-4.1c0.7,0.2,1.6,0.4,2.6,0.4
    c2.1,0,3.4-0.9,3.4-4.4V1050.5z"
						/>
						<path
							fill="#353535"
							d="M1999.4,1061.4c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C1995.6,1050.1,1999.4,1055.2,1999.4,1061.4z M1983.3,1061.7c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C1985.3,1054.1,1983.3,1057.3,1983.3,1061.7z"
						/>
						<path fill="#353535" d="M2002.7,1050.5h5v18h8.9v4.2h-13.9V1050.5z" />
						<path fill="#353535" d="M2024.6,1050.5v22.3h-5v-22.3H2024.6z" />
						<path
							fill="#353535"
							d="M2042.2,1063.3h-8.2v5.3h9.2v4.1H2029v-22.3h13.7v4.1h-8.7v4.6h8.2V1063.3z"
						/>
						<path fill="#353535" d="M2051.1,1054.7h-6v-4.2h17.1v4.2h-6.1v18h-5V1054.7z" />
						<path fill="#353535" d="M2069.9,1054.7h-6v-4.2h17.1v4.2h-6.1v18h-5V1054.7z" />
						<path
							fill="#353535"
							d="M2096.9,1063.3h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1063.3z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 2115 1073.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.joliette, dataKey)}
					</text>
				</g>
				<g id="Pie-Ix">
					<ellipse
						id="Point_00000000935437893917259340000016301523102943481000_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -98.2039 1702.0521)"
						fill="#FFFFFF"
						cx="2005.5"
						cy="969.6"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000129905745978847109180000018387545240705425324_">
						<path
							fill="#353535"
							d="M2032.8,1000.9c1.5-0.3,3.7-0.5,6.8-0.5c3.1,0,5.3,0.6,6.8,1.8c1.4,1.1,2.4,3,2.4,5.1c0,2.2-0.7,4-2,5.3
    c-1.7,1.6-4.3,2.4-7.2,2.4c-0.7,0-1.3,0-1.7-0.1v8h-5V1000.9z M2037.8,1011c0.4,0.1,0.9,0.1,1.7,0.1c2.7,0,4.3-1.4,4.3-3.6
    c0-2-1.4-3.3-3.9-3.3c-1,0-1.7,0.1-2.1,0.2V1011z"
						/>
						<path fill="#353535" d="M2057,1000.6v22.3h-5v-22.3H2057z" />
						<path
							fill="#353535"
							d="M2074.6,1013.4h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V1013.4z"
						/>
						<path fill="#353535" d="M2086.5,1012.5v3.3h-8.6v-3.3H2086.5z" />
						<path fill="#353535" d="M2094.7,1000.6v22.3h-5v-22.3H2094.7z" />
						<path
							fill="#353535"
							d="M2110.8,1022.9l-2-4c-0.8-1.6-1.4-2.7-2-4h-0.1c-0.5,1.3-1,2.5-1.7,4l-1.8,4h-5.7l6.4-11.3l-6.2-11h5.8
    l1.9,4.1c0.7,1.4,1.1,2.5,1.7,3.7h0.1c0.5-1.4,0.9-2.4,1.5-3.7l1.9-4.1h5.7l-6.3,10.9l6.6,11.4H2110.8z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 2130 1023.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.pieIx, dataKey)}
					</text>
				</g>
				<g id="Viau">
					<ellipse
						id="Point_00000057145411355420623830000009942678661176761523_"
						transform="matrix(0.2298 -0.9732 0.9732 0.2298 708.9381 2725.1384)"
						fill="#FFFFFF"
						cx="2076.1"
						cy="914.7"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000052078176822181688510000017499000879714121350_">
						<path
							fill="#353535"
							d="M2108.7,968.8l-7.1-22.3h5.5l2.7,9.4c0.8,2.6,1.5,5.2,2,7.9h0.1c0.6-2.7,1.3-5.3,2-7.9l2.8-9.5h5.4
    l-7.5,22.3H2108.7z"
						/>
						<path fill="#353535" d="M2129.5,946.5v22.3h-5v-22.3H2129.5z" />
						<path
							fill="#353535"
							d="M2139.1,963.1l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H2139.1z M2144.7,959.3l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H2144.7z"
						/>
						<path
							fill="#353535"
							d="M2159.4,946.5v12.8c0,3.8,1.5,5.8,4,5.8c2.6,0,4.1-1.9,4.1-5.8v-12.8h5V959c0,6.9-3.5,10.1-9.3,10.1
    c-5.6,0-8.9-3.1-8.9-10.2v-12.4H2159.4z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 2190 969.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.viau, dataKey)}
					</text>
				</g>
				<g id="Assomption">
					<circle
						id="Point_00000173139860391023021880000017854101101690092687_"
						fill="#FFFFFF"
						cx="2066.2"
						cy="823.2"
						r="18.3"
					/>
					<g id="Name_00000134221007725308733470000005973217372978351283_">
						<path
							fill="#353535"
							d="M2139.4,838.3l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H2139.4z M2145.1,834.6l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H2145.1z"
						/>
						<path
							fill="#353535"
							d="M2156.1,838.8c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L2156.1,838.8z"
						/>
						<path
							fill="#353535"
							d="M2173.9,838.8c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L2173.9,838.8z"
						/>
						<path
							fill="#353535"
							d="M2212,832.7c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C2208.2,821.4,2212,826.5,2212,832.7z M2195.9,833c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C2197.9,825.4,2195.9,828.6,2195.9,833z"
						/>
						<path
							fill="#353535"
							d="M2234.1,835.5c-0.1-2.7-0.2-5.9-0.2-9.2h-0.1c-0.7,2.8-1.6,6-2.5,8.6l-2.7,8.7h-3.9l-2.4-8.6
    c-0.7-2.6-1.5-5.8-2-8.7h-0.1c-0.1,3-0.2,6.5-0.4,9.2l-0.4,8.4h-4.7l1.4-22.3h6.7l2.2,7.4c0.7,2.6,1.4,5.4,1.9,8h0.1
    c0.6-2.6,1.4-5.5,2.1-8l2.4-7.4h6.6l1.2,22.3h-4.9L2234.1,835.5z"
						/>
						<path
							fill="#353535"
							d="M2243.2,822.1c1.5-0.3,3.7-0.5,6.8-0.5c3.1,0,5.3,0.6,6.8,1.8c1.4,1.1,2.4,3,2.4,5.1c0,2.2-0.7,4-2,5.3
    c-1.7,1.6-4.3,2.4-7.2,2.4c-0.7,0-1.3,0-1.7-0.1v8h-5V822.1z M2248.2,832.2c0.4,0.1,0.9,0.1,1.7,0.1c2.7,0,4.3-1.4,4.3-3.6
    c0-2-1.4-3.3-3.9-3.3c-1,0-1.7,0.1-2.1,0.2V832.2z"
						/>
						<path fill="#353535" d="M2266.7,826h-6v-4.2h17.1v4.2h-6.1v18h-5V826z" />
						<path fill="#353535" d="M2285.6,821.8V844h-5v-22.3H2285.6z" />
						<path
							fill="#353535"
							d="M2310.3,832.7c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C2306.6,821.4,2310.3,826.5,2310.3,832.7z M2294.2,833c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C2296.2,825.4,2294.2,828.6,2294.2,833z"
						/>
						<path
							fill="#353535"
							d="M2313.6,844v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6V844
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H2313.6z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 2350 844.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.assomption, dataKey)}
					</text>
				</g>
				<g id="Cadillac">
					<ellipse
						id="Point_00000023240717048853983890000001037483514846854545_"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 94.975 1694.3772)"
						fill="#FFFFFF"
						cx="2092.8"
						cy="732.5"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000163036564196056281760000014655916114275294889_">
						<path
							fill="#353535"
							d="M2143.9,776.9c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L2143.9,776.9z"
						/>
						<path
							fill="#353535"
							d="M2152.7,771.8l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H2152.7z M2158.4,768l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H2158.4z"
						/>
						<path
							fill="#353535"
							d="M2169.2,755.6c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V755.6z M2174.3,773.7c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V773.7z"
						/>
						<path fill="#353535" d="M2197.3,755.2v22.3h-5v-22.3H2197.3z" />
						<path fill="#353535" d="M2201.7,755.2h5v18h8.9v4.2h-13.9V755.2z" />
						<path fill="#353535" d="M2218.5,755.2h5v18h8.9v4.2h-13.9V755.2z" />
						<path
							fill="#353535"
							d="M2240.8,771.8l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H2240.8z M2246.4,768l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H2246.4z"
						/>
						<path
							fill="#353535"
							d="M2272.8,776.9c-0.9,0.4-3,1-5.7,1c-7.7,0-11.7-4.8-11.7-11.2c0-7.6,5.4-11.8,12.2-11.8
    c2.6,0,4.6,0.5,5.5,1l-1,4c-1-0.4-2.4-0.8-4.2-0.8c-4,0-7.1,2.4-7.1,7.4c0,4.5,2.6,7.3,7.1,7.3c1.5,0,3.2-0.3,4.2-0.7
    L2272.8,776.9z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 2290 778.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.cadillac, dataKey)}
					</text>
				</g>
				<g id="Langelier">
					<ellipse
						id="Point_00000178892275730768439670000014786670130869746063_"
						transform="matrix(0.2298 -0.9732 0.9732 0.2298 1015.9349 2627.5535)"
						fill="#FFFFFF"
						cx="2168"
						cy="671.9"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000036934625504286937620000014963281539504435361_">
						<path fill="#353535" d="M2200.8,700.4h5v18h8.9v4.2h-13.9V700.4z" />
						<path
							fill="#353535"
							d="M2223,716.9l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H2223z M2228.7,713.2l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H2228.7z"
						/>
						<path
							fill="#353535"
							d="M2239.5,722.6v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H2239.5z"
						/>
						<path
							fill="#353535"
							d="M2280.9,721.6c-1.6,0.5-4.5,1.3-7.4,1.3c-4.1,0-7-1-9.1-3c-2-1.9-3.2-4.8-3.1-8.1
    c0-7.4,5.4-11.6,12.7-11.6c2.9,0,5.1,0.5,6.2,1.1l-1.1,4c-1.2-0.5-2.7-0.9-5.2-0.9c-4.2,0-7.4,2.4-7.4,7.2c0,4.6,2.9,7.3,7,7.3
    c1.1,0,2.1-0.1,2.5-0.3v-4.7h-3.4v-3.9h8.3V721.6z"
						/>
						<path
							fill="#353535"
							d="M2298.1,713.2h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V713.2z"
						/>
						<path fill="#353535" d="M2302.5,700.4h5v18h8.9v4.2h-13.9V700.4z" />
						<path fill="#353535" d="M2324.4,700.4v22.3h-5v-22.3H2324.4z" />
						<path
							fill="#353535"
							d="M2342,713.2h-8.2v5.3h9.2v4.1h-14.2v-22.3h13.7v4.1h-8.7v4.6h8.2V713.2z"
						/>
						<path
							fill="#353535"
							d="M2346.4,700.7c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V700.7z M2351.4,710.2h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V710.2z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 2380 723.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.langelier, dataKey)}
					</text>
				</g>
				<g id="Radisson">
					<ellipse
						id="Point_00000160169130805105004400000006528418480841178497_"
						transform="matrix(0.2298 -0.9732 0.9732 0.2298 1116.2039 2654.249)"
						fill="#FFFFFF"
						cx="2235"
						cy="621.9"
						rx="18.3"
						ry="18.3"
					/>
					<g id="Name_00000134947022529758194680000008020940020181158046_">
						<path
							fill="#353535"
							d="M2273.1,646.6c1.6-0.3,4-0.5,6.7-0.5c3.3,0,5.6,0.5,7.2,1.8c1.3,1.1,2.1,2.6,2.1,4.7
    c0,2.8-2,4.8-3.9,5.5v0.1c1.5,0.6,2.4,2.1,3,4.1c0.7,2.5,1.4,5.4,1.8,6.3h-5.2c-0.3-0.6-0.9-2.4-1.5-5.2
    c-0.6-2.8-1.6-3.5-3.6-3.6h-1.5v8.7h-5V646.6z M2278.1,656.2h2c2.5,0,4-1.3,4-3.2c0-2-1.4-3.1-3.7-3.1c-1.2,0-1.9,0.1-2.3,0.2
    V656.2z"
						/>
						<path
							fill="#353535"
							d="M2298.2,662.8l-1.6,5.7h-5.2l6.8-22.3h6.6l6.9,22.3h-5.4l-1.7-5.7H2298.2z M2303.8,659.1l-1.4-4.7
    c-0.4-1.3-0.8-3-1.1-4.3h-0.1c-0.3,1.3-0.7,3-1,4.3l-1.3,4.7H2303.8z"
						/>
						<path
							fill="#353535"
							d="M2314.7,646.6c1.9-0.3,4.3-0.5,6.8-0.5c4.2,0,7,0.8,9.1,2.4c2.3,1.7,3.8,4.4,3.8,8.4
    c0,4.3-1.5,7.2-3.7,9c-2.3,2-5.9,2.9-10.3,2.9c-2.6,0-4.5-0.2-5.7-0.3V646.6z M2319.7,664.8c0.4,0.1,1.1,0.1,1.8,0.1
    c4.6,0,7.6-2.5,7.6-7.8c0-4.6-2.7-7.1-7-7.1c-1.1,0-1.8,0.1-2.3,0.2V664.8z"
						/>
						<path fill="#353535" d="M2342.7,646.3v22.3h-5v-22.3H2342.7z" />
						<path
							fill="#353535"
							d="M2347.3,663.4c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L2347.3,663.4z"
						/>
						<path
							fill="#353535"
							d="M2365.1,663.4c1.3,0.7,3.4,1.4,5.6,1.4c2.3,0,3.5-1,3.5-2.4c0-1.4-1.1-2.2-3.7-3.1
    c-3.7-1.3-6.1-3.3-6.1-6.6c0-3.8,3.2-6.7,8.4-6.7c2.5,0,4.4,0.5,5.7,1.1l-1.1,4c-0.9-0.4-2.5-1.1-4.6-1.1c-2.2,0-3.2,1-3.2,2.2
    c0,1.4,1.2,2,4.1,3.1c3.9,1.5,5.8,3.5,5.8,6.7c0,3.7-2.8,6.9-9,6.9c-2.5,0-5-0.7-6.3-1.4L2365.1,663.4z"
						/>
						<path
							fill="#353535"
							d="M2403.1,657.2c0,7.3-4.4,11.7-10.9,11.7c-6.6,0-10.5-5-10.5-11.3c0-6.7,4.3-11.6,10.8-11.6
    C2399.4,645.9,2403.1,651,2403.1,657.2z M2387.1,657.5c0,4.4,2.1,7.4,5.4,7.4c3.4,0,5.3-3.2,5.3-7.5c0-4-1.9-7.4-5.4-7.4
    C2389.1,649.9,2387.1,653.2,2387.1,657.5z"
						/>
						<path
							fill="#353535"
							d="M2406.5,668.6v-22.3h5.9l4.6,8.2c1.3,2.3,2.6,5.1,3.6,7.6l0.1,0c-0.3-2.9-0.4-5.9-0.4-9.3v-6.5h4.6v22.3
    h-5.3l-4.8-8.6c-1.3-2.4-2.8-5.3-3.9-7.9l-0.1,0c0.2,3,0.2,6.1,0.2,9.7v6.7H2406.5z"
						/>
					</g>
					<text
						transform="matrix(1 0 0 1 2440 668.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="start"
					>
						{Utils.getFormattedValueFromStation(data.radisson, dataKey)}
					</text>
				</g>
				<g id="Honoré-Beaugrand">
					<g id="Name_00000114033242785562611330000014800105571991313072_">
						<rect x="1952.2" y="448.9" fill="#2EA64A" width="529.8" height="70.7" />
						<g>
							<path
								fill="#FFFFFF"
								d="M1978.6,472.1V485h12.5v-12.9h7.6v33.5h-7.6v-14h-12.5v14h-7.6v-33.5H1978.6z"
							/>
							<path
								fill="#FFFFFF"
								d="M2035.9,488.5c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C2030.2,471.6,2035.9,479.2,2035.9,488.5z M2011.7,488.9c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C2014.7,477.6,2011.7,482.4,2011.7,488.9z"
							/>
							<path
								fill="#FFFFFF"
								d="M2040.9,505.6v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9v33.5
    h-7.9l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6v10.1H2040.9z"
							/>
							<path
								fill="#FFFFFF"
								d="M2105.7,488.5c0,11-6.6,17.6-16.4,17.6c-9.9,0-15.8-7.5-15.8-17c0-10,6.4-17.5,16.3-17.5
    C2100.1,471.6,2105.7,479.2,2105.7,488.5z M2081.5,488.9c0,6.6,3.1,11.2,8.1,11.2c5.1,0,8-4.8,8-11.3c0-6-2.9-11.2-8.1-11.2
    C2084.5,477.6,2081.5,482.4,2081.5,488.9z"
							/>
							<path
								fill="#FFFFFF"
								d="M2110.7,472.6c2.4-0.4,6-0.7,10.1-0.7c5,0,8.4,0.8,10.8,2.7c2,1.6,3.1,3.9,3.1,7c0,4.2-3,7.2-5.9,8.2
    v0.2c2.3,0.9,3.6,3.2,4.5,6.2c1,3.8,2,8.1,2.7,9.4h-7.7c-0.5-1-1.3-3.7-2.3-7.8c-0.9-4.2-2.3-5.3-5.5-5.3h-2.2v13.1h-7.5V472.6
    z M2118.2,486.9h3c3.8,0,6-1.9,6-4.8c0-3.1-2.1-4.6-5.6-4.6c-1.8,0-2.9,0.1-3.4,0.3V486.9z"
							/>
							<path
								fill="#FFFFFF"
								d="M2160,491.4h-12.3v8h13.8v6.2h-21.3v-33.5h20.6v6.2h-13v7h12.3V491.4z"
							/>
							<path fill="#FFFFFF" d="M2177.9,490v5h-13v-5H2177.9z" />
							<path
								fill="#FFFFFF"
								d="M2182.7,472.6c2-0.4,6-0.7,9.8-0.7c4.6,0,7.4,0.5,9.9,1.9c2.3,1.2,4,3.5,4,6.6c0,2.9-1.7,5.7-5.5,7.1
    v0.1c3.8,1,6.7,3.9,6.7,8.2c0,3.1-1.4,5.5-3.5,7.1c-2.5,2-6.5,3-13.2,3c-3.7,0-6.5-0.2-8.2-0.5V472.6z M2190.2,485.4h2.5
    c4,0,6.1-1.7,6.1-4.2c0-2.5-1.9-3.9-5.3-3.9c-1.7,0-2.6,0.1-3.2,0.2V485.4z M2190.2,500.2c0.7,0.1,1.6,0.1,2.9,0.1
    c3.4,0,6.5-1.3,6.5-4.9c0-3.3-3-4.7-6.8-4.7h-2.6V500.2z"
							/>
							<path
								fill="#FFFFFF"
								d="M2232.5,491.4h-12.3v8h13.8v6.2h-21.3v-33.5h20.6v6.2h-13v7h12.3V491.4z"
							/>
							<path
								fill="#FFFFFF"
								d="M2246.9,497l-2.4,8.6h-7.8l10.2-33.5h9.9l10.4,33.5h-8.2l-2.6-8.6H2246.9z M2255.4,491.3l-2.1-7.1
    c-0.6-2-1.2-4.5-1.7-6.4h-0.1c-0.5,2-1,4.5-1.5,6.5l-2,7.1H2255.4z"
							/>
							<path
								fill="#FFFFFF"
								d="M2277.5,472.1v19.2c0,5.8,2.2,8.7,6.1,8.7c4,0,6.2-2.8,6.2-8.7v-19.2h7.6v18.8c0,10.3-5.2,15.2-14,15.2
    c-8.4,0-13.4-4.7-13.4-15.3v-18.7H2277.5z"
							/>
							<path
								fill="#FFFFFF"
								d="M2331.8,504c-2.3,0.8-6.8,1.9-11.2,1.9c-6.1,0-10.5-1.5-13.6-4.5c-3.1-2.9-4.8-7.3-4.7-12.2
    c0-11.1,8.1-17.5,19.1-17.5c4.3,0,7.6,0.8,9.3,1.6l-1.6,6.1c-1.9-0.8-4.1-1.4-7.8-1.4c-6.3,0-11.1,3.5-11.1,10.8
    c0,6.9,4.4,11,10.6,11c1.7,0,3.1-0.2,3.7-0.5v-7h-5.2v-5.9h12.5V504z"
							/>
							<path
								fill="#FFFFFF"
								d="M2337.7,472.6c2.4-0.4,6-0.7,10.1-0.7c5,0,8.4,0.8,10.8,2.7c2,1.6,3.1,3.9,3.1,7c0,4.2-3,7.2-5.9,8.2
    v0.2c2.3,0.9,3.6,3.2,4.5,6.2c1,3.8,2,8.1,2.7,9.4h-7.7c-0.5-1-1.3-3.7-2.3-7.8c-0.9-4.2-2.3-5.3-5.5-5.3h-2.2v13.1h-7.5V472.6
    z M2345.2,486.9h3c3.8,0,6-1.9,6-4.8c0-3.1-2.1-4.6-5.6-4.6c-1.8,0-2.9,0.1-3.4,0.3V486.9z"
							/>
							<path
								fill="#FFFFFF"
								d="M2375.4,497l-2.4,8.6h-7.8l10.2-33.5h9.9l10.4,33.5h-8.2l-2.6-8.6H2375.4z M2383.9,491.3l-2.1-7.1
    c-0.6-2-1.2-4.5-1.7-6.4h-0.1c-0.5,2-1,4.5-1.5,6.5l-2,7.1H2383.9z"
							/>
							<path
								fill="#FFFFFF"
								d="M2400.2,505.6v-33.5h8.8l6.9,12.3c2,3.5,4,7.7,5.5,11.5l0.1,0c-0.5-4.4-0.6-8.9-0.6-14v-9.8h6.9v33.5
    h-7.9l-7.2-12.9c-2-3.6-4.2-7.9-5.8-11.8l-0.2,0c0.2,4.4,0.3,9.2,0.3,14.6v10.1H2400.2z"
							/>
							<path
								fill="#FFFFFF"
								d="M2434.4,472.6c2.8-0.5,6.4-0.7,10.2-0.7c6.3,0,10.5,1.2,13.7,3.6c3.5,2.6,5.7,6.7,5.7,12.6
    c0,6.4-2.3,10.8-5.5,13.6c-3.5,2.9-8.9,4.3-15.4,4.3c-3.9,0-6.7-0.2-8.6-0.5V472.6z M2442,499.9c0.7,0.1,1.7,0.1,2.6,0.1
    c6.9,0.1,11.4-3.7,11.4-11.7c0-7-4.1-10.6-10.6-10.6c-1.7,0-2.8,0.1-3.4,0.3V499.9z"
							/>
						</g>
						<g>
							<path fill="#FFFFFF" d="M2155.8,457.9l-6.1,7.1h-3.1l4.4-7.1H2155.8z" />
						</g>
					</g>

					<ellipse
						id="Point_00000000184196282428606980000006027510887897526180_"
						transform="matrix(0.9239 -0.3827 0.3827 0.9239 -40.4719 928.1229)"
						fill="#FFFFFF"
						stroke="#231F20"
						strokeWidth="5"
						strokeMiterlimit="10"
						cx="2312.8"
						cy="565.8"
						rx="34.1"
						ry="34.1"
					/>
					<text
						transform="matrix(1 0 0 1 2270 546.1449)"
						fontFamily="'Arial-BoldMT'"
						fill={'#2EA64A'}
						fontSize="26px"
						textAnchor="end"
					>
						{Utils.getFormattedValueFromStation(data.honoreBeaugrand, dataKey)}
					</text>
				</g>
			</g>
		</g>
	)
}
