import React from 'react'
import './App.css'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { MetroFiltered, MetroContracts } from './contracts/api/Metro.contracts'
import { Metro } from './components/Metro/Metro'
import { DownloadButton } from './components/DownloadButton'
import { MapSelect, MapSelectProps } from './components/MapSelect'
import { AmtFiltered } from './contracts/api/Amt.contracts'
import { fetchYears } from './services/api/years.service'
import { fetchAmt } from './services/api/amt.service'
import { fetchMetro } from './services/api/metro.service'
import { MapFilters } from './components/MapFilters'
import { Loader } from './components/Loader'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
interface Maps {
	amt: AmtFiltered | null
	metro: MetroFiltered | null
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
function App() {
	const { t, i18n } = useTranslation()
	const [period, setPeriod] = React.useState('')
	const [year, setYear] = React.useState('')
	const [yearsAndPeriods, setYearsAndPeriods] = React.useState<Record<string, Array<string>>>({})
	const [dataKey, setDataKey] = React.useState<keyof MetroContracts>('medianPrice')
	const svgRef = React.useRef<SVGSVGElement | null>(null)
	const [map, setMap] = React.useState<MapSelectProps['map']>('metro')
	const [maps, setMaps] = React.useState<Maps>({ metro: null, amt: null })
	const [loadingFilters, setLoadingFilters] = React.useState(false)

	// Effects
	//--------------------------------------------------------------------------
	React.useEffect(() => {
		const fetchYearsAndPeriods = async () => {
			const response = await fetchYears(map)
			const _year = Object.keys(response.data.data.attributes)[0]
			const _period = response.data.data.attributes[_year][0]

			setYearsAndPeriods(response.data.data.attributes)
			setYear(_year)
			setPeriod(_period)
		}
		void fetchYearsAndPeriods()
	}, [map])

	React.useEffect(() => {
		if (!year || !period || !map) return
		const fetchData = async () => {
			setLoadingFilters(true)
			// Here we need to make sure that if the year changed, the period exist in the given year

			const _availablePeriods = yearsAndPeriods[year]
			const _period = _availablePeriods.includes(period) ? period : yearsAndPeriods[year][0]

			const _amt = map === 'amt' ? await fetchAmt({ year, period: _period }) : { data: null }
			const _metro = map === 'metro' ? await fetchMetro({ year, period: _period }) : { data: null }

			setMaps({
				amt: _amt.data,
				metro: _metro.data,
			})
			setLoadingFilters(false)
		}
		void fetchData()
	}, [year, period, map, yearsAndPeriods])

	// Methods
	//--------------------------------------------------------------------------
	const handleOnYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setYear(event.target.value)
	}

	const handleOnPeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setPeriod(event.target.value)
	}

	const handleOneDataKeyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setDataKey(event.target.value as keyof MetroContracts)
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<React.Suspense fallback={<Loader />}>
			<motion.div className="App" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
				<div className="container">
					<div className="filters_container">
						<MapSelect map={map} setMap={setMap} />
						<MapFilters
							period={period}
							year={year}
							yearsAndPeriods={yearsAndPeriods}
							handleOnYearChange={handleOnYearChange}
							handleOneDataKeyChange={handleOneDataKeyChange}
							handleOnPeriodChange={handleOnPeriodChange}
							dataKey={dataKey}
						/>
					</div>
					{map === 'metro' ? (
						<Metro loadingFilters={loadingFilters} data={maps?.metro} dataKey={dataKey} ref={svgRef} />
					) : null}
					{/*{map === 'amt' ? (*/}
					{/*	<Amt loadingFilters={loadingFilters} data={maps?.amt} dataKey={dataKey} ref={svgRef} />*/}
					{/*) : null}*/}
					{!loadingFilters ? (
						<DownloadButton map={map} svgRef={svgRef} data={{ year: year, period, key: dataKey }} />
					) : null}
					<hr />
					<div className="language-switcher">
						<button
							className={'secondary'}
							onClick={() => {
								if (i18n.language === 'en') {
									void i18n.changeLanguage('fr')
								} else {
									void i18n.changeLanguage('en')
								}
							}}
						>
							{t('common.switch')}
						</button>
					</div>
				</div>
			</motion.div>
		</React.Suspense>
	)
}

export default App
