import { axiosInstance } from '../../config/axiosInstance'
import { MapSelectProps } from '../../components/MapSelect'

/*
|--------------------------------------------------------------------------
| Service
|--------------------------------------------------------------------------
*/

export const fetchYears = (map: MapSelectProps['map']) => axiosInstance.get(`/${map}s/years-and-periods`)
